import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from 'i18n';

import {ValidateEmail, colorizeAdamText} from 'Utils';
import {GetError} from 'Errors';
import {addAppMessage} from 'api/appMessage';
import {passResetRequest} from 'api/login';
import {APP_MESSAGE_TYPE_ERROR, APP_MESSAGE_TYPE_CLEAR} from 'actions/app';
import {getPassResetRequestDone, getPassResetRequestError, getPassResetRequestPending} from 'reducers/login';

import TextInput from './../TextInput';
import Button from './../Button';

import "./../Card.css";
import "./CardLogin.css";

class CardLoginPassForgot extends Component
{
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.state = {user:{val:""}};	
	}

	handleClick() {
		const {passResetRequest} = this.props;
		
		this.props.addAppMessage("", "", APP_MESSAGE_TYPE_CLEAR);
			
		if (!ValidateEmail(this.state.user.val)) { 
			this.setState({error:true})
			setTimeout(function() { 
				var err = GetError({id:"app001", "msg":"Ente a valid emial address."});
				this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);				
			}.bind(this), 50);			
		}
		else
		{
			this.setState({error:false})
			passResetRequest({"email":this.state.user.val});
		}

		
  }

	onSubmit(event){
		event.preventDefault();
		this.handleClick();
	}


	render()
	{
		if(this.state == null)
		{
	//		this.setState({user:{val:""}});	
	//		return("");		
		}
		const {result, error, pending} = this.props;
		if(result==null && error == null && pending == null)
		{
		
		}
		else if(!pending)
		{
			/*if(error != undefined)
				console.log("pass reset Error", error)
			if(result)
				console.log("pass reset");
				*/
		}

		return(
			<div className="Login Card round">
				<div className="Card-title Login-title">
					<span dangerouslySetInnerHTML={{__html:colorizeAdamText(i18n.t("login_pass_forgot_title"))}}></span>
				</div>
				<div className="Card-content Login-content">
					<form onSubmit={this.onSubmit}>
					<TextInput label={i18n.t("login_name_label")} placeholder={i18n.t("login_name_placeholder")} type="text" model={this.state.user} error={this.state.error} editable={true}></TextInput>					
					<Button onClick={this.handleClick} color="slate-green" className="login_btn">{i18n.t("login_pass_forgot_btn_label")}</Button>
					<div className="login_bottom">
						<div className="login_icon">
							<div className={"login_status " + (result!=null?"ok":(error!=null?"error":"pass_reset"))}></div>
						</div>
						<span className="login_right">{result!=null?i18n.t("login_pass_forgot_info_done"):i18n.t("login_pass_forgot_info")}</span>
						<input type="submit" className="hidden"></input>
					</div>
					</form>
				</div>
			</div>
		);
		}
	
}

const mapStateToProps = state => ({
	result: getPassResetRequestDone(state),
	error: getPassResetRequestError(state),
	pending: getPassResetRequestPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({passResetRequest: passResetRequest, addAppMessage: addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CardLoginPassForgot);

