import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CustomScroll from 'react-custom-scroll';

import {update} from './../../api/company';
import {getLastUpdate} from './../../reducers/company';

import {addAppMessage} from './../../api/appMessage';

import {findById} from './../../_models/CompanyModel';

import "./CompanyLevelDownContacts.css";
import Icon from './../Icon.js';
import i18n from '../../i18n';

class CompanyLevelDownContacts extends Component
{
	constructor(props) {
		super(props);
		
		this.activateClicked = this.activateClicked.bind(this);
	//	this.deleteClicked = this.deleteClicked.bind(this);
	}

	deleteClicked_depr(e)
	{	
		let id = e.target.parentElement.dataset.id;
		let company = findById(id, this.props.companies);
		
		//console.log(id, company, this.props.companies);
		company.value.parent_company_id = "0";
		this.props.update(company.value);
		//this.props.addAppMessage("000000", "delete " +  id, "");				
	}

	activateClicked(e)
	{
		let id = e.target.parentElement.dataset.id;
		let company = findById(id, this.props.companies).value;
		if(company.is_deactivated)
		{
			company.is_deactivated = false;
		}
		else
		{
			company.is_deactivated = true;			
		}

		this.props.update(company);
		
	}
	componentDidMount()
	{
	
	}

	componentDidUpdate()
	{
	
	}


	render()
	{	
	//	console.log("render");
		let companies = this.props.companies.sort(function(a, b) { 
			return a.is_deactivated>b.is_deactivated;
		});

		let list=[];
		for(var i=0;i<companies.length;i++)
			list.push(
			<div key={companies[i].id} data-id={companies[i].id}>
				<span>{companies[i].name}</span>
				{this.props.editable?<Icon type="dot" status={companies[i].is_deactivated?"":"ok"} onClick={this.activateClicked} title={i18n.t("company_details_leveldown_"+(companies[i].is_deactivated?"":"de")+"activate_btn")}></Icon>:""}
				{this.props.editable && this.props.onDelete!=null?<Icon type="minus" status="error" onClick={this.props.onDelete} title={i18n.t("company_details_leveldown_delete_btn")} id={companies[i].id}></Icon>:""}
			</div>
			);		
		


		return(
			<div className="CompanyLevelDownContacts">
			<CustomScroll heightRelativeToParent="100%">
			<div className="CompanyLevelDownContactsList">				
					{list}				
			</div>
			</CustomScroll>
			</div>
		);
	}

}

const mapStateToProps = state => ({
	getLastUpdate:getLastUpdate(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({update:update, addAppMessage: addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CompanyLevelDownContacts);
