import React, {Component} from 'react';
import ThreeContainer from 'ui/ThreeContainer';
import "./CommentPreviewCard.css";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {findVersionById} from '_models/VersionsModel';
import {getSelectedVersionId} from 'reducers/versions';


class CommentPreviewCard extends Component
{
	render()
	{
		
		if(this.props.selectedVersionId===-1 || this.props.selectedVersionId == null)
			return(<div className={"CommentPreviewCard Card round tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}></div>);
		let version = findVersionById(this.props.selectedVersionId);
		//console.log("render", this.props.selectedVersionId, version);
		return(
			<div className={"CommentPreviewCard Card round tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<ThreeContainer model={version}></ThreeContainer>
			</div>
		);
	}
}

const mapStateToProps = state => ({selectedVersionId:getSelectedVersionId(state)})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CommentPreviewCard);

