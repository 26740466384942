import React, {Component} from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {addAppMessage} from './../../api/appMessage';
import {getMembers, setMember, selectMember, deleteMember} from './../../api/companyMembers';
import {getCompanyMembers, getCompanySelectedMember} from './../../reducers/companyMembers';

import {APP_MESSAGE_TYPE_CLEAR, APP_MESSAGE_TYPE_ERROR} from './../../actions/app';
import {GetError} from 'Errors';
import {ValidateEmail} from 'Utils';
import CustomScroll from 'react-custom-scroll';

import "./CompanyMembersBlock.css";
import Label from './../Label';
import Icon from './../Icon';
import i18n from 'i18n';

class CompanyMembersBlock extends Component
{
	constructor(props)
	{
		super(props);
		this.onAddClick = this.onAddClick.bind(this);
		this.accessItemClicked = this.accessItemClicked.bind(this);
		this.rowClicked = this.rowClicked.bind(this);

	}

	accessItemClicked(e)
	{
		e.preventDefault();
		
		if(!this.props.editable)
			return;


		let changed = false;
		let selected = this.props.gotCompanySelectedMember;
		let permissions = this.props.gotCompanySelectedMember.permissions;
	
		if(e.currentTarget === this.refs.members_clients_access)
		{
			changed = true;
			if(permissions.includes(1))
				permissions.splice(permissions.indexOf(1),1);
			else
				permissions.push(1);
		}
		else if(e.currentTarget === this.refs.members_agency_access)
		{
			changed = true;
			if(permissions.includes(2))
				permissions.splice(permissions.indexOf(2),1);
			else
				permissions.push(2);
		}
		else if(e.currentTarget === this.refs.members_sales_access)
		{
			changed = true;
			if(permissions.includes(3))
				permissions.splice(permissions.indexOf(3),1);
			else
				permissions.push(3);
		}
		if(changed)
		{
			selected.permissions = permissions;
		
			let realPermissions = [];
			permissions.forEach(function(item){
				realPermissions.push(item);
			});
			
			if(permissions.length === 0)
				realPermissions.push(0);			
			this.props.addAppMessage("","", APP_MESSAGE_TYPE_CLEAR);
			this.pendingSaveMessage  = {"title":i18n.t("company_details_members_msg_saved_title"), "text":i18n.t("company_details_members_msg_saved_text").split("[#email]").join(selected.email)};
			
			this.props.setMember({"email":selected.email, "company_id":this.props.companyId, permissions:realPermissions})


			this.setState({selected: selected});
		}
	}

	rowClicked(e)
	{
		if(e.target.className.includes("minus"))
		{
		//	console.log("minus")
			this.props.deleteMember({email:this.props.gotCompanyMembers[e.currentTarget.dataset.id].email, company_id:this.props.companyId});
		}
		else
			this.props.selectMember(this.props.gotCompanyMembers[e.currentTarget.dataset.id]);
	}

	onAddClick(e)
	{		
		let email = this.refs.member_email_input.value;
		if(!ValidateEmail(email))
		{
			let err = GetError({id:"app001", "msg":"invalid email"});
			this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);	
			return;
		}
		this.props.addAppMessage("", "", APP_MESSAGE_TYPE_CLEAR);	
		
		for(let i=0; i<this.props.gotCompanyMembers.length;i++)
		{
			if(this.props.gotCompanyMembers[i].email === email)
			{
				let err = GetError({id:"app007", "msg":"member already exists"});
				this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);
				this.setState({members:this.props.gotCompanyMembers, selected:this.props.gotCompanyMembers[i]});	
				return;
			}
		}		
		this.props.gotCompanyMembers.push({id:"",email:email, permissions:[], active:false});
		this.props.selectMember(this.props.gotCompanyMembers[this.props.gotCompanyMembers.length-1]);
		this.refs.member_email_input.value = "";
	}
	render()
	{
	
		if(this.actualCompanyId !== this.props.companyId)
		{
			this.actualCompanyId = this.props.companyId;
			
			this.props.getMembers({"company_id":this.actualCompanyId});			
			return(
				<div className="block members noselect">
					{"Loading " + i18n.t("company_details_members_label")}				
				</div>
			);
		}
		if(this.pendingSaveMessage != null)
		{
			this.props.addAppMessage(this.pendingSaveMessage.title, this.pendingSaveMessage.text);
			this.pendingSaveMessage = null;
		}
	
		let list=[];
		for(var i=0;i<this.props.gotCompanyMembers.length;i++)
			list.push(
			<div key={i} data-id={i} onClick={this.rowClicked} className={"row" + (this.props.gotCompanySelectedMember===this.props.gotCompanyMembers[i]?" selected":"")}>
				<span>{this.props.gotCompanyMembers[i].email}</span>
				<Icon type="activity" status={this.props.gotCompanyMembers[i].active?"ok":"error"} onClick={this.activateClicked}></Icon>
				{this.props.editable?<Icon type="minus" status="error" onClick={this.deleteClicked} ref="members_list_row_delete"></Icon>:null}
			</div>
			);		

		return(
			<div className="block members noselect">
				<Label value={i18n.t("company_details_members_label")}></Label>
				<div className="CompanyMembersBlock-content ">
				{this.props.editable?<div className="mailInput">
						<form onSubmit={this.onAddClick}>
						<input type="text" id="memberEmail" placeholder={i18n.t("company_details_members_input_placeholder")} ref="member_email_input" />
						<Icon type="plus" status="green" onClick={this.onAddClick}></Icon>
						</form>
					</div>:null}
					<div className={"availableGroups"+(this.props.gotCompanySelectedMember==null?" disabled":"")} >
						<div onClick={this.accessItemClicked} className={"MainNavigationItem round clients" + (this.props.clientsAvailable?"":" hidden")} ref="members_clients_access"><a href="#0" className={ (this.props.gotCompanySelectedMember!=null && this.props.gotCompanySelectedMember.permissions.includes(1)?" selected":"")}><div className="MainNavigationItem-content"><div className="MainNavigationItem-bg"></div><span>{i18n.t("clients_title")}</span></div></a></div>
						<div onClick={this.accessItemClicked} className={"MainNavigationItem round agency" + (this.props.agencyAvailable?"":" hidden")} ref="members_agency_access"><a href="#1" className={ (this.props.gotCompanySelectedMember!=null && this.props.gotCompanySelectedMember.permissions.includes(2)?" selected":"")}><div className="MainNavigationItem-content"><div className="MainNavigationItem-bg"></div><span>{i18n.t("agency_title")}</span></div></a></div>
						<div onClick={this.accessItemClicked} className={"MainNavigationItem round sales" + (this.props.salesAvailable?"":" hidden")} ref="members_sales_access"><a href="#2" className={ (this.props.gotCompanySelectedMember!=null && this.props.gotCompanySelectedMember.permissions.includes(3)?" selected":"")}><div className="MainNavigationItem-content"><div className="MainNavigationItem-bg"></div><span>{i18n.t("sales_title")}</span></div></a></div>
					</div>
					
					<div className="CompanyMembersList">
					<CustomScroll heightRelativeToParent="100%">
						{list}
					</CustomScroll>
					</div>
					
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({gotCompanyMembers:getCompanyMembers(state), gotCompanySelectedMember:getCompanySelectedMember(state)})
const mapDispatchToProps = dispatch => bindActionCreators({addAppMessage, getMembers, setMember, selectMember, deleteMember}, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(CompanyMembersBlock);