import React, {Component} from 'react';
import CustomScroll from 'react-custom-scroll';
import "./SearchDetails.css";
//import {SearchCompany} from './../../_models/CompanyModel';^
import {Search} from './../../_models/SearchModel';
import i18n from "i18n";
import { Link } from 'react-router-dom';
import { CONST } from '../../Constants';

class SearchDetails extends Component
{
	constructor(props) {
		super(props);
		this.search="";
		this.results=[];
	}


	renderResults(results)
	{

		let list = [];
		document.onclick = this.documentClicked;

		//console.log(results)
		for(let i in results)
		{
			if(results[i].scope == CONST.settings || results[i].scope != this.props.scope)
				continue;

			
			
			for(let j in results[i].results)
			{
				list.push(
					<Link key={i+"_"+j} className="row" to={"/"+(results[i].scope==="project"?"agency":results[i].scope)+"/"+results[i].results[j].cid+(results[i].results[j].pid!=null?"/"+results[i].results[j].pid:"")}>
						<div className="_01">{results[i].results[j].pid==null?i18n.t(CONST.clients+"_search_category"):i18n.t(CONST.project+"_search_category")}</div>
						<div className="_02">{results[i].phrase}</div>
						<div className="_03">{results[i].results[j].text}</div>
					</Link>
				);
			}
		}

		return list;
	}

	render()
	{

	//	console.log(this.props.search);
		if(this.search!=this.props.search)
		{
			this.search = this.props.search
			this.results = Search(this.props.search);
		}
			
		return(
			<div className="SearchDetails Card round tile _r3-4 _c2-4">
				<div className="Card-title">
					<span><span>{i18n.t("search_title")}</span> {this.search + " "+this.props.scope}</span>
					
				</div>
				<div className="Card-content">
				
					<div className="resultstable">
						<div className="head">
							<div className="_01">{i18n.t("search_scope")}</div>
							<div className="_02">{i18n.t("search_client")}</div>
							<div className="_03">{i18n.t("search_result")}</div>
						</div>
						<div className="body">
						<CustomScroll heightRelativeToParent="100%">
							{this.renderResults(this.results)}
							</CustomScroll>
						</div>
					</div>
				</div>
			</div>
		);
	
	}
}

export default SearchDetails;