import i18n from "./i18n";

export function GetError(errorObj)
{
	var err = {id:"000000", msg:"Unknown message"};
	if(errorObj == null || errorObj.id == null)
		return err;
	
	if(errorObj.id === "000103")
		return {id:errorObj.id, msg:i18n.t("error_000103_user_pass_not_valid")};
	if(errorObj.id === "app001")
		return {id:errorObj.id, msg:i18n.t("error_app001_input_empty_user")};
	if(errorObj.id === "app002")
		return {id:errorObj.id, msg:i18n.t("error_app002_input_differ_pass")};
	if(errorObj.id === "app003")
		return {id:errorObj.id, msg:i18n.t("error_app003_input_short_pass")};
	if(errorObj.id === "app004")
		return {id:errorObj.id, msg:i18n.t("error_app004_logo_file_size_to_big")};
	if(errorObj.id === "app005")
		return {id:errorObj.id, msg:i18n.t("error_app005_logo_file_type_not_png")};	
	if(errorObj.id === "app006")
		return {id:errorObj.id, msg:i18n.t("error_app006_logo_file_error")};	
	if(errorObj.id === "app007")
		return {id:errorObj.id, msg:i18n.t("error_app007_email_already_in_watchers")};	
		

	return errorObj;
}
