let _myPermissions = [];
let _myPermissionsMap = {};

let _myCompanyPermissions = [];
let _myCompanyPermissionsMap = {};


export function HasPermission_depr(id) {
	if(_myPermissionsMap[id] != null)
		return _myPermissionsMap[id];
	
	for(let i=0; i<_myPermissions.length;i++)
	{
		if(_myPermissions[i] == id)
		{
			_myPermissionsMap[id] = true;
			return _myPermissionsMap[id];
		}
	}
	_myPermissionsMap[id] = false;
	return false;
}

export function HasPermissions_depr(...args) {
	for(let i=0; i<args.length;i++)
	{
		if(!HasCompanyPermission(args[i]))
			return false;			
	}
	return true;
}
export function HasOnOfPermissions(...args) {
//	console.log(args);
//	console.log(_myCompanyPermissionsMap)
	for(let i=0; i<args.length;i++)
	{
		if(HasCompanyPermission(args[i]))
			return true;			
	}
	//console.log(false);
	return false;
}

export function GetPermissionClassName(id) {

	//console.log(id)
	if(HasCompanyPermission(id))
		return "";
	else
		return " hidden";
}

export function SetPermissions(permissions) {

	
	_myPermissions = permissions;
	_myPermissionsMap = {};
	for(let i=0; i<_myPermissions.length;i++)
		_myPermissionsMap[_myPermissions[i]] = true;
}

export function HasPermission(id) {
	return HasCompanyPermission(id);
}

export function HasCompanyPermission(id) {
	if((id+"").length == 3)
		id = "000"+id;	
//	console.log(id);
	//console.log(_myCompanyPermissionsMap)
	if(_myCompanyPermissionsMap[id] != null)
		return true;	
	//	console.log(false);
	return false;
}

export function SetCompanyPermissions(permissions)
{

	if(permissions == null)
		permissions = [];
	_myCompanyPermissions = permissions;
	_myCompanyPermissionsMap = {};
	for(let i=0; i<_myCompanyPermissions.length;i++)
		_myCompanyPermissionsMap[_myCompanyPermissions[i]] = true;
}


export const Ids = {
	_206: '206', //companytopLevel
	_207: '207', //companyAddChild
	_208: '208' //companyChangeRoleGroup 
};