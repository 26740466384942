import {CONST} from './../Constants';

let _allCompanies = [];
export class Company
{
	constructor(scope, companies, locked)
	{
		if(scope == null)
			scope = CONST.clients;
		_allCompanies.push(this);
		this.scope = scope;
		this.entries = [];
		this.locked = locked;
	//	this.entries_map = {};
		this.entries = companies;//findChildren("0", companies);

		this.HasRootAccess = false;
		for(let i in this.entries)
		{
			if(this.entries[i].parent_company_id === "0")
				this.HasRootAccess = true;
		}
	}

	

	Search(searchPhrase, children)
	{
		searchPhrase = searchPhrase.toLowerCase();
		let results = [];
		if(children == null)
			children = this.entries;
		for(let i=0; i<children.length;i++)
		{
			if(children[i].name.toLowerCase().indexOf(searchPhrase)!==-1)
				results.push(children[i]);
			if(children[i].children!=null)
				results = results.concat(this.Search(searchPhrase, children[i].children));
		}

		
		return results;
	}


	findChildren(id, companies)
	{
		let _companies = [];
	/* eslint-disable */
		for(let c in companies)
		{
			if(companies[c].parent_company_id === id)
				_companies.push(companies[c]);
		} 
		for(let c in _companies)
			_companies[c].children = findChildren(_companies[c].id, companies);
		/* eslint-enable */
		return _companies;
	}

	FindById(id, entries, depth=0)
	{	
		if(entries == null) 
			entries =this.entries;
		if(id == "0")
			return  {depth:depth, value:this.entries};
		
		
		/* eslint-disable */
		for(let c in entries)
		{
			if(entries[c].id === id)
			{
				return {depth:depth, value:entries[c]};		
			}
			let child = this.FindById(id, entries[c].children, depth+1);
			if(child != null)
			{
			return child;
			}
		} 
		/* eslint-enable */
		return null;
	}

	GetCompaniesGroupedByDepth(id)
	{
		
		if(this.entries.length===0)
			return {stack:[], groups:[]};
		
		if(id == null || id.length<10)
			return {stack:[], groups:[this.entries]};
		
		let grouped = [];
		let activeCompany = this.FindById(id);
		
		if(activeCompany==null)
			return this.entries;
		let stack = this.getParentStack([activeCompany]);
	
		for(let i=stack.length-1;i>=0;i--)
			grouped.push(this.getElementsInSameLevel(stack[i], this.entries));
		if(activeCompany.value.children.length>0)
			grouped.push(activeCompany.value.children);
		return {stack:stack, groups:grouped};
	}

	getParentStack(stack)
	{
		let element = stack[stack.length-1];
		if(element.value.parent_company_id==null || element.value.parent_company_id.length<10)
			return stack;
		let parentElement = this.FindById(element.value.parent_company_id);
		stack.push(parentElement);
		if(parentElement.depth===0)
			return stack;

		return this.getParentStack(stack);
	}

	getElementsInSameLevel(element)
	{
		if(element.value.parent_company_id==null || element.value.parent_company_id.length<10)
			return this.entries;
		
		return this.FindById(element.value.parent_company_id).value.children;
	}

	changeParent(id, oldParent, newParent)
	{
		let debug = false;
		if(this.scope == "clients")
			debug = true;
		let _oldParent = this.FindById(oldParent);
		let foundindex = -1;
		let oldchildren = _oldParent.value.children;
		if(oldchildren == null)
			oldchildren =  _oldParent.value;
		for(let i=0; i<oldchildren.length;i++)
		{
			if(oldchildren[i].id == id)
			{
				foundindex = i;
				break;
			}
		}
		if(foundindex>=0)
		{
			let el = oldchildren.splice(foundindex, 1);
			if(el.length==1)
			{
				let _newParent = this.FindById(newParent);
				if(_newParent!=null)
				{
					if(debug)	console.log(newParent)
					if(_newParent.value.children!=null && newParent!="0")
					{
						_newParent.value.children.push(el[0]);
						el[0].depth =_newParent.depth+1;
						
					}
					else
					{
					
						_newParent.value.push(el[0]); //root
						el[0].depth = 0;
						if(debug)console.log("-0", el[0])
					}
				}
			}		
		}
	}
}

export function Reset()
{
	_allCompanies = [];
	actualOrderReceivedCompainesScope="";
}

export function SearchCompany(searchPhrase)
{
	let foundCompanies = [];
	for(let i=0; i<_allCompanies.length;i++)
	{
		foundCompanies.push({scope:_allCompanies[i].scope, results:_allCompanies[i].Search(searchPhrase)});
	}
	return foundCompanies;
}

export function GetCompaniesByScope(scope)
	{
		for(let i=0; i<_allCompanies.length;i++)
		{
		//	console.log(_allCompanies[i].scope);
			if(scope === "agency" && _allCompanies[i].scope === "project")
				return _allCompanies[i];
			else if(scope === "clients" && (_allCompanies[i].scope === "clients" || _allCompanies[i].scope === ""))
				return _allCompanies[i];
			else if(scope === "sales" && _allCompanies[i].scope === "sales")
				return _allCompanies[i];
			else if(scope === "report" && _allCompanies[i].scope === "report")
				return _allCompanies[i];
			else if(scope === "config" && _allCompanies[i].scope === "settings")
				return _allCompanies[i];
		}
		
		return {NOTFOUND:true, scope:scope, entries:[], entries_map:{}};
}

let actualOrderReceivedCompainesScope="";

export function orderReceivedCompaines(companies, scope)
{
//	console.time("orderReceivedCompaines");

	actualOrderReceivedCompainesScope = scope;
	//let rootChildren = findRootChildren(companies);

	let _companies = findRootChildren(companies);
	for(let i in _companies)
	{
		_companies[i].children = findChildren(_companies[i].id, companies);
	}
	
	let c = new Company(scope, _companies, companies.locked===true);


	for(let i=0; i<companies.length;i++)
	{
		let _c = findById(companies[i].id, _companies);
		if(_c == null)
		{
			if(companies[i].children == null)
				companies[i].children =[];
			_companies.push(companies[i]);
			c.entries.push(companies[i]);
		}
	}
//	console.log(_companies);
	return _companies;
}


export function removeDeletedCompanyAndReturnAll(company)
{

	actualOrderReceivedCompainesScope = "clients";
	//console.log("deleting:", company, _allCompanies)
	for(let i=0; i<_allCompanies.length; i++)
	{
		//console.log(_allCompanies.length, i)
	}
	for(let i=0; i<_allCompanies.length; i++)
	{
		let deleted = false;
		let parent1 = _allCompanies[i].FindById(company.parent_company_id);
	//	console.log(parent1, _allCompanies.length, i)
		company.children = [];
		let removed = false;
		if(parent1!=null && parent1.value!=null && parent1.value.children!=null)
		{
			for(let c=0; c<parent1.value.children.length;c++)
			{
				if(parent1.value.children[c].id == company.id)
				{
					parent1.value.children.splice(c, 1);
					//delete _allCompanies[i].entries_map[company.id];
					removed = true;
					deleted = true;
				//	break;
				}
			}
			
		}
		else
		{
			for(let c=0; c<	_allCompanies[i].entries.length;c++)
			{
				if(_allCompanies[i].entries[c].id == company.id)
				{
					_allCompanies[i].entries.splice(c, 1);
				//	delete _allCompanies[i].entries_map[company.id];
					removed = true;
					deleted = true;
				//	break;				
				}
			}			
		}
		//console.log(deleted);
	}

	return [];
}



export function updateCompanyAndReturnAll(company)
{

	actualOrderReceivedCompainesScope = "clients";
	for(let i=0; i<_allCompanies.length; i++) 
	{
		//console.log(i)
		

		let res = updateCompany(company, _allCompanies[i].entries);
		if(res != null && res.oldParent!= res.newParent)
		{		
			_allCompanies[i].changeParent(company.id, res.oldParent, res.newParent);
		}
	}	
	return _allCompanies;
}


export function addCreatedCompanyAndReturnAll(company)
{
	
	actualOrderReceivedCompainesScope = "clients";
	for(let i=0; i<_allCompanies.length; i++)
	{
		let checkIfExists = _allCompanies[i].FindById(company.id);
		if(checkIfExists)
			return [];
		let parent1 = _allCompanies[i].FindById(company.parent_company_id);
		
		company.children = [];
		
		if(parent1!=null && parent1.value.children!=null)
			parent1.value.children.push(company);
		else if(parent1!=null && parent1.value.children==null)
		{
			parent1.value.push(company);
		//	_allCompanies[i].entries.push(company);		
		}
		else
			_allCompanies[i].entries.push(company);		
	}
	return _allCompanies;
}

export function unorderCompaines(orderedCompanies, listCompanies)
{
	if(listCompanies==null)
		listCompanies = [];
	
	for(let i=0; i<orderedCompanies.length; i++)
	{
		listCompanies.push(orderedCompanies[i]);
		if(orderedCompanies[i].children.length>0)
		{
			unorderCompaines(orderedCompanies[i].children, listCompanies);
		}
	}

	return listCompanies;
}

export function setCompanyParent_depr(company, parent_company_id)
{
	console.log(company, parent_company_id);
	if(company.parent_company_id !== parent_company_id)
	{
		
	//	company.parent_company_id = parent_company_id;
		console.log(company, parent_company_id)
	}
}

export function updateCompany(company, all)
{

	for(let i=0; i<all.length;i++)
	{
		if(company.id === all[i].id)
		{
			all[i].name = company.name;
			if(all[i].is_deactivated!=company.is_deactivated)
			{
				console.log(i, company.is_deactivated)
				all[i].is_deactivated=company.is_deactivated;
				adjustActivateStatusInChildren(all[i]);
			}
			let parentChanged = all[i].parent_company_id !== company.parent_company_id;
			
		
			let oldParent = all[i].parent_company_id;
			all[i].parent_company_id = company.parent_company_id;
			return {oldParent:oldParent, newParent:company.parent_company_id};
		}
		if(all[i].children.length>0)
		{
			let res = updateCompany(company, all[i].children);
			if(res != null)
				return res;
		}
	}
	return null;
}

function adjustActivateStatusInChildren(company)
{
	for(let i in company.children)
	{
	//	console.log(company.children[i])
		company.children[i].is_deactivated=company.is_deactivated;
		adjustActivateStatusInChildren(	company.children[i]);
	}
}

function findRootChildren(companies)
{
	let _companies = [];
	for(let c in companies)
	{
		let foundParent = false;
		for(let c2 in companies)
		{
			if(companies[c].parent_company_id === companies[c2].id)
			{
				foundParent = true;
				break;
			}
		}
		if(!foundParent)
			_companies.push(companies[c]);
	}
	
	return _companies; 
}
export function findChildren(id, companies)
{
	let _companies = [];
/* eslint-disable */
	for(let c in companies)
	{
		
		if(companies[c].parent_company_id === id)
		{
		
			_companies.push(companies[c]);
		}
	} 
	for(let c in _companies)
	{
		_companies[c].children = findChildren(_companies[c].id, companies);
	}
	/* eslint-enable */
	return _companies;
}


export function findById(id, companies, depth=0)
{	
	//console.log(companies)
//	console.log(companies_map);
//	if(companies_map[id]!=null)
	//	return companies_map[id];
	/* eslint-disable */
	for(let c in companies)
	{
		if(companies[c].id === id)
			return {depth:depth, value:companies[c]};

		let child = findById(id, companies[c].children, depth+1);
		if(child != null)
		{
		//	companies_map[id] = child;
			return child;
		}
	} 
	/* eslint-enable */
	return null;
}

export function getCompaniesGroupedByDepth(id, companies)
{
	if(companies.length===0)
		return {stack:[], groups:[]};
	if(id == null || id.length<10)
		return {stack:[], groups:[companies]};
	let grouped = [];
	let activeCompany = findById(id, companies);
	if(activeCompany==null)
		return companies;
	let stack = getParentStack(companies,[activeCompany]);
	
	for(let i=stack.length-1;i>=0;i--)
		grouped.push(getElementsInSameLevel(stack[i], companies));
	if(activeCompany.value.children.length>0)
		grouped.push(activeCompany.value.children);
	return {stack:stack, groups:grouped};
}

function getParentStack(companies, stack)
{
	let element = stack[stack.length-1];
	if(element.value.parent_company_id==null || element.value.parent_company_id.length<10)
		return stack;

	let parentElement = findById(element.value.parent_company_id, companies);
	if(parentElement==null)//if elemn has parent_id but the parent id isnt available
		return stack;
	stack.push(parentElement);
	if(parentElement.depth===0)
		return stack;

	return getParentStack(companies, stack);
}

function getElementsInSameLevel(element, companies)
{
	if(element.value.parent_company_id==null || element.value.parent_company_id.length<10)
		return companies;
	
	let c = findById(element.value.parent_company_id, companies);
	
	if(c == null)
		return companies;
	return findById(element.value.parent_company_id, companies).value.children;
}


