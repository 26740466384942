import React, {Component} from 'react';

import Icon from './Icon';

import "./CheckBox.css";


class CheckBox extends Component
{
	render()
	{
		return(
			<div className="CheckBox" onClick={this.props.onClick} data-id={this.props.id} title={this.props.title}>
				<Icon type={this.props.checked?"checkbox":""}></Icon>			
			</div>			
		);
	}
}

export default CheckBox;