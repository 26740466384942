import {fetchAuth, fetchLogin, fetchLogout, fetchPassResetRequest,fetchPassReset, fetchSearchIndex} from './../actions/login';
import {_doRequest} from './_api.js';


export function auth(data) {
	
	return _doRequest("auth", fetchAuth, "000100", data);	
}

export function logout() {
	
	return _doRequest("logout", fetchLogout, "000102");	
}

export function login(data) {
	return _doRequest("login", fetchLogin, "000101",data);	
}


export function getSearchIndex(data) {
	return _doRequest("searchindex", fetchSearchIndex, "000400",data);	
}

export function passResetRequest( data) {
	return _doRequest(
		"pass_reset_request", 
		fetchPassResetRequest, 
		"000103",
		data
	);
}

export function setNewPass( data) {
	return _doRequest(
		"pass_reset", 
		fetchPassReset, 
		"000106",
		data
	);
}

