import {REPORTS_GET_PENDING, REPORTS_GET_SUCCESS, REPORTS_GET_ERROR} from './../actions/reports';
import {initialState}  from './../initialState.js';


export function reportsReducer(state = initialState, action) {

	switch(action.type) {	
		case REPORTS_GET_PENDING: 
	  	return {
			...state,
			error: null,
			pending: true,
			list: [],
    }
		case REPORTS_GET_SUCCESS:	
		  return {
			...state,
			pending: false,
			info: action.info,
			list: action.payload,
		
    }
    case REPORTS_GET_ERROR:			
		  return {
      ...state,
      pending: false,
			error: action.info,
			list: [],
		}
    default: 
      return state;
  }
}

export const getReports = state => state.reports.list;