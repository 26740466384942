import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {IsProjectViewRoute, IsMobile} from './../Utils';
import {getSelectedCompany, getSelectedCompanyId, getCompanyPending} from './../reducers/company';
import {get} from './../api/company';
import "./HeaderSearch.css";
import i18n from '../i18n';
import QuickSearch from './QuickSearch';

class HeaderSearch extends Component
{
	render()
	{
		let p = (document.location+"").split("/#/");
		let scope = "";
		if(p.length>=2)
		{
			p = p[1].split("/");
			scope = p[0];		
		}


		let logo = "";
		//	
		if(this.props.selectedCompany!=null && this.props.selectedCompany["logo"]!=null)
			logo = this.props.selectedCompany["logo"];
		if(this.props.selectedCompany == null || this.props.selectedCompanyId != this.props.selectedCompany.id)
		{
			logo = "";
		//	console.log(this.props.selectedCompanyId)
			if(!this.props.pending && this.lastloadingId != this.props.selectedCompanyId && this.props.selectedCompanyId!="")
			{
				//TODO LOGO 

				this.props.get({"id":this.props.selectedCompanyId});
				this.lastloadingId = this.props.selectedCompanyId;
			}
		}
		else
		{

		}

		let logolink = "/";
		if(IsMobile() && localStorage.getItem("lastProjectViewHash")!="")
		{
			logolink = "/projectview";
		}
		


		return(
			<div className="HeaderSearch tile _r1-2 _c1">
				<div className="HeaderSearch-content">
					<div className="AdamLogo"><Link to={logolink}><img src="images/logo_adam.png" alt="adam." /></Link></div>
					
					{this.props.selectedCompany!=null?<div className="CustomerLogo">
					<div className="logoimg" style={{backgroundImage: 'url("'+(logo.includes("data:image/png")?logo:"data:image/png;base64, "+logo)+'")'}}></div>
						
						</div>:""}
					<div className="Search">
						<QuickSearch scope={scope}></QuickSearch>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	pending: getCompanyPending(state),
	selectedCompany:getSelectedCompany(state),
	selectedCompanyId:getSelectedCompanyId(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({get}, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(HeaderSearch);