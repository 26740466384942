import {fetchProjects_get, fetchSelectedProjectId_set, fetchProject_set, 
	fetchSharingMembers_get, fetchSharingMember_add, fetchSharingMember_del, versionStatsUpdated as _versionStatsUpdated} from './../actions/projects';

import {_doRequest} from './_api.js';
import {_doAppRequest} from './_app.js';

export function getAllByCompanyID( data) {	
	return _doRequest(
		"project_get", 
		fetchProjects_get, 
		"000251",
		data
	);
}

export function getSalesMembers( data) {	
	return _doRequest(
		"sharing_getMembers", 
		fetchSharingMembers_get, 
		"000307",
		data
	);
}

export function versionStatsUpdated(data)
{
	//console.log(data)
	return dispatch => {	 		
		dispatch(_versionStatsUpdated(data));
	};

}
export function addSalesMember( data) {	
	return _doRequest(
		"sharing_addMember", 
		fetchSharingMember_add, 
		"000305",
		data
	);
}

export function deleteSalesMember( data) {	
	return _doRequest(
		"sharing_delMember", 
		fetchSharingMember_del, 
		"000306",
		data
	);
}

export function setSelectedProjectId(data) {	
	return _doAppRequest(
		fetchSelectedProjectId_set, 
		data
	);
}

export function updateProject( data) {	
	
	return _doRequest(
		"project_update", 
		fetchProject_set, 
		"000253",
		data
	);
}

export function createProject( data) {	
	
	return _doRequest(
		"project_create", 
		fetchProject_set, 
		"000252",
		data
	);
}

export function deleteProject( data) {	
	
	return _doRequest(
		"project_delete", 
		fetchProject_set, 
		"000254",
		data
	);
}


