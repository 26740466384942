import adam3dviewer from './examples/adam3dviewer';

let __adam3dView;
export function Init3DView(container, model){

	try
	{
		if(__adam3dView == null)
		{
			__adam3dView = new adam3dviewer(container, model);
		}
		else
		{
			__adam3dView.SetModel(model);
		}
	}
	catch(Ex)
	{
		console.log(Ex, container, model)
	}
}

export function CreatePreview(callback){
	if(__adam3dView!=null)
		__adam3dView.CreatePreview(callback);
}
