import React, {Component} from 'react';
import CustomScroll from 'react-custom-scroll';
import {Link, Redirect} from 'react-router-dom';
import i18n from "i18n";

import {GetPermissionClassName as Permission, HasPermission, HasOnOfPermissions} from '_models/Permissions'; 

import Button from 'ui/Button.js';
import ActionBar from 'ui/ActionBar';

import "./VersionsCard.css";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {findProjectById} from '_models/ProjectsModel'
import {findVersionById} from '_models/VersionsModel'

import {getProject, getSelectedProjectId} from 'reducers/projects';
import {getVersionsReducer, getSelectedVersionId, getCreatedVersionId} from 'reducers/versions';
import {showAlertMessage} from 'api/appMessage';
import {getAllByProjectId, setSelectedVersionId, createVersion,deleteVersion,updateVersion} from 'api/versions';
import {setSelectedCommentId} from 'api/comments';
import { CONST } from '../../../../Constants';
import {getSelectedCommentId, getCommentsReducer} from 'reducers/comments';

class VersionsCard extends Component
{
	constructor(props) {
		super(props);
		//this.versionSelected = ((e)=>{this.props.setSelectedCommentId(-1);this.props.setSelectedVersionId(e.currentTarget.dataset.id)});
		
		this.deleteClicked = this.deleteClicked.bind(this);
		this.newClicked = this.newClicked.bind(this);
	}
	
	
	deleteClicked(e)
	{
		let version = findVersionById(this.props.selectedVersionId);
		if(version!=null)
			this.props.showAlertMessage({text:i18n.t("deletealert_message").split("[#1]").join(this.project.name+"_"+version.version), data:version, scope:CONST.version});
	}

	newClicked(e)
	{
		this.props.createVersion({project_id:this.props.selectedProjectId});
	}

	countComments(version, actualCommentsVersionId, actualComments)
	{
		if(actualCommentsVersionId==version.id && actualComments>=0)
			return actualComments;
		return version.comments;		
	}

	render()
	{	
	
		//console.log(this.props.projectsReducer , this.props.selectedProjectId);
		if(this.props.createdVersionId != null && this.actualProjectId !== this.props.createdVersionId && this.props.selectedProjectId!== this.props.createdVersionId)
		{
			let version = findVersionById(this.props.createdVersionId);
		//	console.log(this.props.createdVersionId, version, this.props.versionsReducer)
			let tmp = this.props.createdVersionId;
			this.props.versionsReducer.createdVersionId  = null;
			return(<Redirect to={"/agency/"+this.props.companyid+"/"+this.props.projectid+"/"+tmp}></Redirect>)
		}
		
	//	console.log(this.props.selectedProjectId, this.props.projectsReducer)
		//if(this.props.selectedProjectId!==0 && this.actualProjectId !== this.props.selectedProjectId && (this.props.selectedProjectId.length>5 || this.props.selectedProjectId===0))
		if(!this.props.versionsReducer.pending && this.actualProjectId !== this.props.selectedProjectId && (this.props.selectedProjectId.length>5 || this.props.selectedProjectId===0))
		{
		//	console.log(this.actualProjectId, this.props.selectedProjectId);
			this.actualProjectId = this.props.selectedProjectId;
			this.props.getVersions({project_id:this.actualProjectId});
			this.project = findProjectById(this.actualProjectId);	
			this.props.setSelectedVersionId(-1);	
			this.props.setSelectedCommentId(-1);	
			return("");		
		}
		
		let actualId = "0";
		if(this.props.versionsReducer.list.length>0 && this.props.selectedVersionId<0)//Buggy
			;//this.props.setSelectedVersionId(this.props.versionsReducer.list[0].id);
		if(this.props.selectedVersionId!=null && this.props.selectedVersionId.length>=5)
			actualId = this.props.selectedVersionId;

		if(this.project==null || this.props.versionsReducer.pending || this.props.projectsReducer.deletedId === this.props.selectedProjectId)
		{
			return(
			<div className={"VersionsCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>	
				<div className="title">
					<span>{i18n.t("versions_tile")}</span>
				</div>
			</div>);
		}
		if(this.props.versionid !== this.props.selectedVersionId)
		{
			this.props.setSelectedVersionId(this.props.versionid );
		}
		
		let actualComments = -1;
		let actualCommentsVersionId = -1;
		if(this.props.comments.list!=null)
		{
				for(let i in this.props.comments.list)
				{
					if(this.props.selectedCommentId === this.props.comments.list[i].id)
					{
						actualComments = this.props.comments.list.length;
						actualCommentsVersionId = this.props.comments.list[i].version_id
					}
				}
		}

		return(
		
			<div className={"VersionsCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<div className="title">
					<span>{i18n.t("versions_tile")}</span>					
					<Button left="plus" color="black" className={"right"+Permission(272)} onClick={this.newClicked}>{i18n.t("versions_add_btn")}</Button>
				</div>
				<div className="content">

				<ActionBar deleteClicked={actualId!=="0" && HasPermission(274)?this.deleteClicked:null} newClicked={HasPermission(272)?this.newClicked:null}></ActionBar>
				<CustomScroll heightRelativeToParent="calc(100% - 30px)">
				<div className="list">
					{
					this.props.selectedProjectId===CONST.new
					?
						""
					:
						this.props.versionsReducer.list.map((version, index) =>(								
						<div className={(this.props.versionid === version.id?"active":"")+" noselect"} key={index}>
							<Link to={"/agency/"+this.project.company_id+"/"+this.project.id+"/"+version.id}><span>
								{this.project.name + " " +	this.project.article_nr + "_" + version.version}
								<span className={(version.published_at!=null?"":"no")+" published"}></span>
								<span className={(this.countComments(version, actualCommentsVersionId, actualComments)>0?"":"hidden")+" comments"}>{this.countComments(version, actualCommentsVersionId, actualComments)}</span>
							</span>
							</Link>
						</div>
					))}
				</div>
					</CustomScroll>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	selectedProjectId:getSelectedProjectId(state),
	selectedVersionId:getSelectedVersionId(state),
	projectsReducer: getProject(state),
	versionsReducer: getVersionsReducer(state),
	createdVersionId: getCreatedVersionId(state),	
	selectedCommentId:getSelectedCommentId(state),
	comments:getCommentsReducer(state)
})

const mapDispatchToProps = dispatch => bindActionCreators(
	{
		showAlertMessage,
		getVersions:getAllByProjectId, 
		setSelectedVersionId, 
		setSelectedCommentId,
		createVersion, 
		deleteVersion,
		updateVersion
	}, 
	dispatch
);

export default connect(mapStateToProps,mapDispatchToProps)(VersionsCard);