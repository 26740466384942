import React, {Component} from 'react';
import { connect } from 'react-redux';
import {getLogin} from './../reducers/login';

import i18n from "../i18n";

import CardLogin from '../ui/_login/CardLogin';

import "./LoginView.css";
import BoardUi from '../ui/BoardUi';


class LoginView extends Component
{
		
	render()
	{
	
		const {result} = this.props;
		let loggedIn = result!=null && result.length>10;
		if(loggedIn)
		{
			return(		
			<div className={"LoginView Card round tile "+this.props.row+ " "+this.props.column+" "+this.props.className}>
				<div className="LoginView-title Card-title">
					<span>
						{i18n.t("navigation_title")}
					</span>				
				</div>
				<div className="LoginView-content Card-content display-linebreak">					
					<BoardUi></BoardUi>					
				</div>
			</div>
			);
		}
		
		return(
			<div className={"LoginView Card round tile "+this.props.row+ " "+this.props.column+" "+this.props.className}>
				<div className="LoginView-title Card-title">
					<span>
						{i18n.t("login_title")}
					</span>				
				</div>
				<div className="LoginView-content Card-content display-linebreak">					
					<CardLogin></CardLogin>					
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	result: getLogin(state)
})
export default connect(mapStateToProps)(LoginView);
