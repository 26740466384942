import React, {Component} from 'react';
import CustomScroll from 'react-custom-scroll';

import {GetApiURL} from './../api/_api';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {getReports} from 'reducers/reports';
import {getAllReportsByCompanyId} from 'api/reports';

import "./ReportCard.css";
import Icon from './Icon.js';
import i18n from "../i18n";
import {findById} from './../_models/CompanyModel';

class ReportCard extends Component
{
	constructor(props) {
		super(props);
		
		this.state = {
			actualReportId:0,
			projects:[]}
		this.projectSelected = this.projectSelected.bind(this);
		
	}

	getProjectsByCompany(companyId)
	{
		let amount = Math.floor(Math.random() * Math.floor(this.state.projects.length-1));
		let ar = [];
		for(let i=0; i<amount; i++)
		{
			ar.push(this.state.projects[i]);
		}

		ar.sort(() => Math.random() - 0.5);
		return ar;
	}
	projectSelected(e)
	{
		this.setState(
		{
			actualReportId : e.currentTarget.dataset.id
		}
		)
	}

	render()
	{
		let actualReportId = this.state.actualReportId;
		if(this.actualComnpanyId !== this.props.companyid)
		{
			this.props.getAllReportsByCompanyId({company_id:this.props.companyid});
			this.actualComnpanyId = this.props.companyid;
			this.actualCompany = findById(this.actualComnpanyId, this.props.companies);
			this.lastProjects = this.getProjectsByCompany(this.actualComnpanyId);
			actualReportId = "0";
		}
		if(this.lastProjects  == null)
			this.lastProjects = [];
		
		let actualCustomerName = "";
		if(this.actualCompany!=null)
			actualCustomerName = this.actualCompany.value.name;

		return(
			<div className={"ReportCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<div className="ReportCard-title Card-title">
					<span className="col_main">{i18n.t("report_card_title")}</span><span>{actualCustomerName}</span>
				</div>
				<div className="ReportCard-content Card-content">
				<CustomScroll heightRelativeToParent="100%">
				<div className="list">
					{this.props.reports.map((project, index) =>(								
						<div className={(actualReportId === project.id?"active":"")+" noselect"} onClick={this.projectSelected} data-id={project.id} key={project.id}>
							<div className="previewImage">
								<div style={{backgroundImage:project.versions.length>0?"url("+GetApiURL()+"files/"+project.id+"/"+project.versions[0].id+"/preview.png)":""}}>
								</div>
							</div>
							
							<span className="name" >{project.name}</span>
							<span className="art_id">{i18n.t("article_short")} {project.article_nr} </span>
							<hr/>							
							
							<span className="views_lab">{i18n.t("report_row_view")}</span>
							<span className="views_val">
								<Icon type="views" className={(actualReportId === project.id?"main":"")}></Icon> 
								<span>{project.all_views.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
							</span>
							<span className="customer_name">{actualCustomerName} </span>
							<span className="contacts_lab">{i18n.t("report_row_contacts")}</span>
							 <span className="contacts_val">
								<Icon type="contacts" className={(actualReportId === project.id?"main":"")}></Icon> 
								<span>{project.private_views.length.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
							</span>
						</div>
					))}
				</div>
				</CustomScroll>

				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({reports:getReports(state)})

const mapDispatchToProps = dispatch => bindActionCreators({getAllReportsByCompanyId}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(ReportCard);



