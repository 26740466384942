import React, {Component} from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from "i18n";
import {CONST} from 'Constants';
import {updateProject, createProject} from 'api/projects';
import {updateVersion} from 'api/versions';

import {HasPermission, HasOnOfPermissions} from '_models/Permissions'; 

import {findProjectById} from '_models/ProjectsModel';
import {findVersionById} from '_models/VersionsModel';


import {getSelectedProjectId, getProject, getUpdatedProjectId} from 'reducers/projects';
import { getSelectedCompanyId } from 'reducers/company';
import { getSelectedVersionId, getUpdatedVersionId, getVersionsReducer} from 'reducers/versions';

import {addAppMessage, showAlertMessage} from 'api/appMessage';
import {APP_MESSAGE_TYPE_ERROR, APP_MESSAGE_TYPE_INFO} from 'actions/app';

import "./ProjectDetailCard.css";
import TextInputModel from 'ui/TextInputModel';
import Button from 'ui/Button.js';
import LabeledButton from 'ui/LabeledButton.js';

import {CreatePreview} from '3d/threeEntryPoint2';
import { Redirect } from 'react-router-dom';
class ProjectDetailCard extends Component
{
	constructor(props) {
		super(props);
		this.saveOnClick = this.saveOnClick.bind(this);
		this.fileDropped = this.fileDropped.bind(this);
		this.fileOver = this.fileOver.bind(this);
		this.fileOut = this.fileOut.bind(this);
		this.fileChanged = this.fileChanged.bind(this);		
		this.publishClicked = this.publishClicked.bind(this);
		this.threeJsThumbCreated = this.threeJsThumbCreated.bind(this);
		if(this.props.view_type!==CONST.short)
			this.drop_obj_ref = React.createRef();
	}

	//#region file drop

	fileChanged(e)
	{		
		if(e.target.files!=null && e.target.files.length>0)
			this.fileSelected(e.target.files[0]);					
	}

	fileOver(e)
	{
		this.activateDropZone();
	}

	fileOut(e)
	{
		this.deactivateDropZone();
	}

	activateDropZone()
	{
		if(this.drop_obj_ref.current.className.indexOf("active") === -1)
			this.drop_obj_ref.current.className+=" active";
	}

	deactivateDropZone()
	{
		if(this.drop_obj_ref.current.className.indexOf("active") !== -1)
			this.drop_obj_ref.current.className = this.drop_obj_ref.current.className.split(" active").join("");
	}

	

	fileDropped(e)
	{
		e.stopPropagation();
		e.preventDefault();

		this.files = [];
		let file = null;
		if (e.dataTransfer.items) {
			for (var i = 0; i < e.dataTransfer.items.length; i++) {
				if (e.dataTransfer.items[i].kind === 'file') {
					var _file = e.dataTransfer.items[i].getAsFile();
					file = _file;
				}
			}
		} else {
			if( e.dataTransfer.files.length>0)
				file = e.dataTransfer.files[0];	
		}
		this.fileSelected(file);
	}


	fileSelected(file)
	{
		if(!this.isObjEditable)
		{
			
			this.props.addAppMessage("", i18n.t("project_edit_disabled_because_version_published"), APP_MESSAGE_TYPE_ERROR);
			return;
		}

		this.deactivateDropZone();
		if(file == null)
		{
			this.props.addAppMessage(i18n.t("fileuploader_errorInselectedFile_title"),  "", APP_MESSAGE_TYPE_ERROR);
			return;
		}
		if(file.name.indexOf(".obj")===-1)
		{
			this.props.addAppMessage(i18n.t("fileuploader_projectDetailsFilenameUnknown_title"), i18n.t("fileuploader_projectDetailsFilenameUnknown_text").split("[#filename]").join(file.name), APP_MESSAGE_TYPE_ERROR);
			return;
		}
		
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			this.setState(
			{
				...this.state,
				file: file,
				base64: reader.result,
				model: {name:	this.model.name,
					article_nr:	this.model.article_nr,
					job_nr:	this.model.job_nr,
					material:	this.model.material,
					width:	this.model.width,
					height:	this.model.height,
					depth:	this.model.depth,
					id:	this.model.id}
				}
			)

		
			this.state.model.id = "";
		}.bind(this);

		reader.onerror = function (error) {
			this.props.addAppMessage(i18n.t("fileuploader_errorInselectedFile_title"),  "", APP_MESSAGE_TYPE_ERROR);
			return "0";
		};
	}

	//#endregion file drop
	
	saveOnClick(e)
	{
		if(this.state!=null && this.state.file != null)
		{
			this.model.file = this.state.base64;
		}
	
		if(this.model.id === CONST.new || this.model.id==null)
		{
			if(this.model.company_id == null)
				this.model.company_id = this.props.selectedCompanyId;
			this.pending={action:"create"};
			
			this.props.createProject(this.model);
		}
		else
		{
			this.pendingUpdateId = this.model.id;
			this.props.updateProject(this.model);		
		}
	}

	threeJsThumbCreated(imgData)
	{
		if(imgData == null)
		{
			this.props.addAppMessage("Preview not created",  "", APP_MESSAGE_TYPE_ERROR);
		}
		else if(imgData!=="")
			this.version.previewImage = imgData;
		
		this.props.version.getUpdatedVersionId = "";
		this.lastupdatedVersionId = "";
		
		if(this.version.published_at == null)
			this.version.published_at = new Date().toISOString().slice(0, 16).replace('T',' ');
		else
			this.version.published_at = null;
		this.props.updateVersion(this.version);
	}

	publishClicked(e)
	{
		if(this.version.published_at == null)
		{
			let d = CreatePreview(this.threeJsThumbCreated);
			return;
		}
		else
			this.threeJsThumbCreated("");	
	}
	
	render()
	{		

	
		
		if(this.pending!=null && this.pending.action ==="create")
		{
			this.pending = null;
		//	let project = findProjectById(this.props.selectedProjectId);
		}
		let editable = true;
		if(this.props.view_type===CONST.short)
			editable = false;

		if(!HasOnOfPermissions(252,253))
			editable = false;

		this.model = {
			name: CONST.new,
			article_nr:"",			
			job_nr:"",
			material:"",
			width: CONST.mm,
			height: CONST.mm,
			depth: CONST.mm,
			published_versions:[]	
		}
		if(this.props.selectedProjectId!==CONST.new)
		{
			let project = findProjectById(this.props.selectedProjectId);
			if(project!=null)
				this.model = project;
		}
		if(this.state!=null && this.state.model.id===this.model.id)
		{
			this.model.name = this.state.model.name;
			this.model.article_nr = this.state.model.article_nr;
			this.model.job_nr = this.state.model.job_nr;
			this.model.material = this.state.model.material;
			this.model.width = this.state.model.width;
			this.model.height = this.state.model.height;
			this.model.depth = this.state.model.depth;
			this.state.model.id = "";
		}
		else if(this.state!=null &&  this.state.model.id!==this.model.id)
		{
			this.state.file = null;
			this.state.model.id = "";
		}
		

		if(this.pendingUpdateId!=null && this.pendingUpdateId == this.props.updatedProjectId)
		{
			this.pendingUpdateId = null;
			this.props.addAppMessage("OBJ Uploaded",  i18n.t("fileuploader_obj_uploaded"), APP_MESSAGE_TYPE_INFO);
		}
		let uploadText =  i18n.t("projects_details_uploadtext_btn");
		if(this.state!=null && this.state.file!=null && this.state.file.name.length>0)
		{
			uploadText = 	i18n.t("projects_details_upload_fileselected_text_btn").replace("[#name]", this.state.file.name).replace("[#size]", Math.round(this.state.file.size/1024)+" "+i18n.t("kB"));
		}

		this.version = null;
	
		if(this.props.selectedVersionId!=null && this.props.selectedVersionId.length>5 )
		{
			this.version = findVersionById(this.props.selectedVersionId);			
			this.lastupdatedVersionId = "";
		}
		else if(this.props.updatedVersionId!=null && this.props.updatedVersionId.length>5 && this.lastupdatedVersionId  != this.props.updatedVersionId)
		{
		
			this.version = findVersionById(this.props.updatedVersionId);
			this.lastupdatedVersionId = this.props.updatedVersionId;
		}
		else
			this.lastupdatedVersionId = "";

		if(this.props.selectedProjectId===0 || (this.props.view_type===CONST.short && this.version==null))
		{
			return(
				<div className={"ProjectDetailCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
					<div className="title">
						<span>{i18n.t("project_details_title")}</span>
					</div>
				</div>
			);
		}
		if(this.props.view_type===CONST.short)
		{
			
			return(
				<div className={"ProjectDetailCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
					<div className="title">
						<span>{this.model.name}</span>					
					</div>
					<div className="content">
					<TextInputModel _key="name" placeholder={i18n.t("projects_details_name_placeholder")} label={i18n.t("projects_details_name_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="article_nr" placeholder={i18n.t("projects_details_articlenr_placeholder")} label={i18n.t("projects_details_articlenr_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="job_nr" placeholder={i18n.t("projects_details_jobnr_placeholder")} label={i18n.t("projects_details_jobnr_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="material" placeholder={i18n.t("projects_details_material_placeholder")} label={i18n.t("projects_details_material_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="height" placeholder={i18n.t("projects_details_height_placeholder")} label={i18n.t("projects_details_height_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="width" placeholder={i18n.t("projects_details_width_placeholder")} label={i18n.t("projects_details_width_label")}  model={this.model} editable={editable}></TextInputModel>	
					<TextInputModel _key="depth" placeholder={i18n.t("projects_details_depth_placeholder")} label={i18n.t("projects_details_depth_label")}  model={this.model} editable={editable}></TextInputModel>	
					
					<LabeledButton label="" color={this.version.published_at==null?"green":"red"} className={"publish" + ((this.version==null || !HasPermission(273))?" hidden":"")} onClick={this.publishClicked}>
						{this.version.published_at==null?i18n.t("projects_details_publish_btn"):i18n.t("projects_details_unpublish_btn")}
					</LabeledButton>
					
					</div>
				</div>
			);
		}
		//this.hasChanges = false;
		
		this.isObjEditable = this.model.published_versions==null || this.model.published_versions.length==0;
		//console.log(this.model.published_versions.length, isObjEditable);
		let hasChanges = true;
		return(
			<div className={"ProjectDetailCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<div className="title">
					<span>{this.model.name}</span>
					<span className="right">{this.model.article_nr}</span>					
				</div>
				<div className="content">
				<TextInputModel _key="name" placeholder={i18n.t("projects_details_name_placeholder")} label={i18n.t("projects_details_name_label")}  model={this.model} editable={editable}></TextInputModel>
				<TextInputModel _key="article_nr" placeholder={i18n.t("projects_details_articlenr_placeholder")} label={i18n.t("projects_details_articlenr_label")}  model={this.model} editable={editable}></TextInputModel>
				<TextInputModel _key="job_nr" placeholder={i18n.t("projects_details_jobnr_placeholder")} label={i18n.t("projects_details_jobnr_label")}  model={this.model} editable={editable}></TextInputModel>
				<TextInputModel _key="material" placeholder={i18n.t("projects_details_material_placeholder")} label={i18n.t("projects_details_material_label")}  model={this.model} editable={editable}></TextInputModel>
				<TextInputModel _key="height" placeholder={i18n.t("projects_details_height_placeholder")} label={i18n.t("projects_details_height_label")}  model={this.model} editable={editable}></TextInputModel>
				<TextInputModel _key="width" placeholder={i18n.t("projects_details_width_placeholder")} label={i18n.t("projects_details_width_label")}  model={this.model} editable={editable}></TextInputModel>	
				<TextInputModel _key="depth" placeholder={i18n.t("projects_details_depth_placeholder")} label={i18n.t("projects_details_depth_label")}  model={this.model} editable={editable}></TextInputModel>	
				<div id="obj_file_dropzone" ref={this.drop_obj_ref} className={"DropTarget noselect"+(!editable?" hidden":"")+(this.isObjEditable?"":" disabled")}>
				<form>
					<div>
						<div>
							<div>
								<span dangerouslySetInnerHTML={{__html: uploadText}}></span>
							</div>
						</div>
					</div>
					<input className="_file" type="file" name="obj_file" id="obj_123_file" onDrop={this.fileDropped} onDragOver={this.fileOver} onDragLeave={this.fileOut} onChange={this.fileChanged} />
				</form>
				</div>
				<div className={"buttons" + (!editable?" hidden":"")}>
					<Button color={hasChanges?"green":"gray"} disabled={!hasChanges} onClick={this.saveOnClick}>{i18n.t("projects_details_save_btn")}</Button>				
				</div>
			
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	updatedVersionId: getUpdatedVersionId(state),
	selectedProjectId:getSelectedProjectId(state),
	updatedProjectId: getUpdatedProjectId(state),
	projectsReducer: getProject(state),
	selectedCompanyId: getSelectedCompanyId(state),
	selectedVersionId: getSelectedVersionId(state),
	version: getVersionsReducer(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({addAppMessage, updateProject, createProject, showAlertMessage, updateVersion}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(ProjectDetailCard);