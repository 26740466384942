import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {getCompanies} from './../reducers/login';
import CompanyCard from '../ui/Company/CompanyCard';
import ReportCard from '../ui/ReportCard';
import SearchDetails from '../ui/SearchDetails';
import { CONST } from '../Constants';

class ReportView extends Component
{
	constructor(props) {
		super(props);
		this.state={view_type:"visualization"}
		this.commentBtnClicked = this.commentBtnClicked.bind(this);
		this.visualizationBtnClicked = this.visualizationBtnClicked.bind(this);
	}
	commentBtnClicked(e)
	{
		this.setState({view_type:"comment"});
	}
	visualizationBtnClicked(e)
	{
		this.setState({view_type:"visualization"});
	}

	render()
	{
		return(
			<>
			<CompanyCard companyid={this.props.match.params.companyid} companies={this.props.companies} readonly={true} scope="report" gridpositions="_r3-4 _c1"></CompanyCard>
			{
			this.props.match.params.search?
				<SearchDetails search={this.props.match.params.search} scope={CONST.report}></SearchDetails>
			:
				<ReportCard gridpositions="_r3-4 _c2-4" companyid={this.props.match.params.companyid} companies={this.props.companies}></ReportCard>
			}
			</>
		);
		
	}
}

const mapStateToProps = state => ({
	companies: getCompanies(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(ReportView);

