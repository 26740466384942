import {GetApiURL} from 'api/_api';
import { getVersionsReducer } from '../reducers/versions';

let versions_map = {};
let versions = [];

export function getVersions()
{
	return versions;
}
export function parseVersions(rawVersionsList)
{
	versions = [];
	versions_map = {};
	/* eslint-disable */
	for(let rawP in rawVersionsList)
	{
		 let p = parseVersion(rawVersionsList[rawP]);
		 if(p!=null)
		 {
			 versions_map[p.id] = p;
			 versions.push(versions_map[p.id]);
		 }
	}
	versions = versions.sort((a,b) => (a.version > b.version) ? 1 : ((b.version > a.version) ? -1 : 0));
	/* eslint-enable */
	return versions;
}


export function findVersionById(id)
{

	if(versions_map[id]!=null)
		return versions_map[id];
	for(let i=0; i<versions.length;i++)
	{
		if(versions[i].id === id)
		{
			versions_map[id] = versions[i];
			return versions[i];
		}
	}
	return null;
}

export function parseVersion(rawVersion)
{
	if(rawVersion== null)
		return null;
		
	let version = {
		"id": "",
		"project_id": "",
		"version": 0,
		"published_at": null,
		"color_map": "default/cm.png",
		"metallic_map": "default/mm.png",
		"roughness_map": "default/rm.png",
		"normal_map": "default/nm.png",
		"emmisive_map": "default/em.png",
		"ambient_map": "default/ao.png",
		"opacity_map": "default/om.png",
		"omActive" : false,
		"usdz_file": null,
		"gltf_file": null,
		"convert_hash": null,
		"convert_started_at": null,
		"comments":0
	};

	version.id  = rawVersion.id;
	version.project_id  = rawVersion.project_id;
	version.version  = rawVersion.version;
	version.published_at  = rawVersion.published_at;
	version.color_map  = rawVersion.color_map;
	version.metallic_map  = rawVersion.metallic_map;
	version.roughness_map  = rawVersion.roughness_map;
	version.normal_map  = rawVersion.normal_map;
	version.emmisive_map  = rawVersion.emmisive_map;
	version.ambient_map  = rawVersion.ambient_map;
	version.opacity_map  = rawVersion.opacity_map;
	version.omActive = rawVersion.omActive == true;
	version.usdz_file  = rawVersion.usdz_file;
	version.gltf_file  = rawVersion.gltf_file;
	version.convert_hash  = rawVersion.convert_hash;
	version.convert_started_at  = rawVersion.convert_started_at;
	version.comments = rawVersion.comments;

	return version;
}

export function addVersion(version)
{
	if(versions_map[version.id] !=null)
		return;
	versions_map[version.id] = version;
	versions.push(versions_map[version.id]);
	versions = versions.sort((a,b) => (a.version > b.version) ? 1 : ((b.version > a.version) ? -1 : 0));
}


export function udpateVersion(rawVersion)
{

	let v = parseVersion(rawVersion);

	if(v!=null && v.id !=null)
	{
		versions_map[v.id] = v;
		for(let i=0; i<versions.length;i++)
		{
			if(versions[i].id === v.id)
			{
				versions[i] = v;
				versions = versions.sort((a,b) => (a.version > b.version) ? 1 : ((b.version > a.version) ? -1 : 0));
				break;
			}
		}
	}
}

export function Get3dObjectFromVersionModel(model)
{

	let serverUrl = GetApiURL()+"files/";
	let three={};
	three.obj = serverUrl+model.project_id+"/file.obj";	
	if(model.obj_file)
		three.obj = serverUrl+model.project_id+"/"+model.obj_file;	
	three.color = serverUrl+model.project_id+"/"+model.id+"/"+model.color_map;	
	three.metallic = serverUrl+model.project_id+"/"+model.id+"/mm.png";	
	three.roughness = serverUrl+model.project_id+"/"+model.id+"/rm.png";	
	three.normal = serverUrl+model.project_id+"/"+model.id+"/nm.png";	
	three.emissive = serverUrl+model.project_id+"/"+model.id+"/em.png";	
	three.ao = serverUrl+model.project_id+"/"+model.id+"/ao.png";	
	three.om = serverUrl+model.project_id+"/"+model.id+"/om.png";	
	three.omActive = model.omActive;	
	
	return three;
}

export function deleteVersion(rawVersion)
{
	if(versions_map[rawVersion.id]!=null)
	{
		let foundIndex = -1;
		for(let i=0;i<versions.length;i++)
		{
			if(versions[i].id === rawVersion.id)
				foundIndex = i;
		}
		if(foundIndex>=0)
			versions.splice(foundIndex, 1);
		delete versions_map[rawVersion.id];
	}
	versions = versions.sort((a,b) => (a.version > b.version) ? 1 : ((b.version > a.version) ? -1 : 0));
	return versions;
}