import {fetchConfig_get, fetchConfig_set} from './../actions/config';
import {_doRequest} from './_api.js';

export function getConfig( data) {	
	return _doRequest(
		"config_get", 
		fetchConfig_get, 
		"000001",
		data
	);
}

export function setConfig( data) {	
	return _doRequest(
		"config_set", 
		fetchConfig_set, 
		"000001",
		data
	);
}
