import React, {Component} from 'react';
import Label from './Label';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {addAppMessage} from './../api/appMessage';
import {APP_MESSAGE_TYPE_ERROR} from './../actions/app';
import {GetError} from './../Errors';
import "./FileBase64InputModel.css";
import Icon from './Icon';
import i18n from '../i18n';

class FileBase64InputModel extends Component
{
	constructor(props)
	{
		super(props);
		FileBase64InputModel.MAX_LOGO_SIZE = 50*1024;
		this.state = {value:""}
		this.onFileChange = this.onFileChange.bind(this);
	}

	getBase64(file) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			this.props.model[this.props._key]=reader.result;
			this.setState({value:reader.result});		
		}.bind(this);

		reader.onerror = function (error) {
			var err = GetError({id:"app006", "msg":"fiel erorr"});
			this.props.addAppMessage(err.id, err.msg+" "+error, APP_MESSAGE_TYPE_ERROR);	
		};
 }

	
 	onFileChange(event){
		var files = event.target.files;
		if(files.length<=0)
			return;
		if(files[0].size>FileBase64InputModel.MAX_LOGO_SIZE)
		{
			let err = GetError({id:"app004", "msg":"filesize to big"});
			this.props.addAppMessage(err.id, err.msg.split("[#1]").join((FileBase64InputModel.MAX_LOGO_SIZE/1024)+"KB"), APP_MESSAGE_TYPE_ERROR);
		}
		else if(!files[0].type.includes("png"))
		{
			let err = GetError({id:"app005", "msg":"image not png"});
			this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);		
		}
		else
			this.getBase64(files[0]);
	};

	getImageDimensions(file) {
		return new Promise (function (resolved, rejected) {
			var i = new Image()
			i.onload = function(){
				console.log(i.width, i.height)
				resolved({w: i.width, h: i.height})
			};
			i.src = file
		})
	}

	render()
	{
		if(this.props.model==null)
		{
			return("Loading");
		}
	
		return(
			<div title={this.props.model!=null?this.props.model[this.props._key]:""} className={"FileBase64Input TextInput"+ (this.props.className!=null?" "+this.props.className:"") + (this.props.editable?" editable":"")+ (this.props.error?" error":"")}> 
				<Label value={this.props.label}></Label>
				<div className="TextInput-content">
		{/*<img onLoad={this.imgloaded} src={this.props.model[this.props._key].includes("data:image/png")?this.props.model[this.props._key]:"data:image/png;base64, "+this.props.model[this.props._key]} alt={i18n.t("company_details_logo_label")} title={i18n.t("company_details_logo_label")} />*/}
				<div className="logoimg" style={{backgroundImage: 'url("'+(this.props.model[this.props._key]!=null?(this.props.model[this.props._key].includes("data:image/png")?this.props.model[this.props._key]:"data:image/png;base64, "+this.props.model[this.props._key]):"")+'")'}}></div>
				<div className="logo">
					<div className={"Button round black"+(!this.props.editable?" hidden":"")}>
						<span className="Button-content">	
							{i18n.t("company_details_logo_placeholder")}
							<Icon type="check" color="black"></Icon>
						</span>				
						<input type="file" className="Button" title={i18n.t("company_details_logo_label")} onChange={this.onFileChange}></input>
					</div>
				</div>
				</div>
			</div>
		);
	}
}


const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(FileBase64InputModel);
