import React, {Component} from 'react';

import Button from 'ui/Button.js';
import Icon from 'ui/Icon.js';
import i18n from "i18n";
import {CONST} from 'Constants';

import {HasPermission, HasOnOfPermissions } from '../../../../_models/Permissions';

import VersionMapImageRow from './../VersionMapImageRow';
import "./VersionDetailCard.css";
import ThreeContainer from 'ui/ThreeContainer';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import {findVersionById, Get3dObjectFromVersionModel} from '_models/VersionsModel';
import {findProjectById} from '_models/ProjectsModel';

import {getVersionsReducer, getUpdatedVersionId} from 'reducers/versions';
import {getProject} from 'reducers/projects';

import {showAlertMessage} from 'api/appMessage';
import {addAppMessage} from 'api/appMessage';
import { APP_MESSAGE_TYPE_WARNING, APP_MESSAGE_TYPE_ERROR, APP_MESSAGE_TYPE_INFO} from 'actions/app';

import {getAllByProjectId, setSelectedVersionId, updateVersion} from 'api/versions'

import {getVersionExtendedActivated, setVersionExtendedActivated} from '_models/UserSettings';
import {getLastUpdate} from 'reducers/company';
import {CreatePreview} from '3d/threeEntryPoint2';

import {versionStatsUpdated} from 'api/projects';
import CustomScroll from 'react-custom-scroll';

class VersionDetailCard extends Component
{	
	constructor(props) {


		super(props);

		this.filenames = ["cm.png", "mm.png", "rm.png", "nm.png", "em.png", "ao.png", "om.png"];

		this.state = {extended:getVersionExtendedActivated()}
		this.extendedClicked = this.extendedClicked.bind(this);
		this.fileDropped = this.fileDropped.bind(this);
		
		this.fileChanged = this.fileChanged.bind(this);
		this.fileOver = this.fileOver.bind(this);
		this.fileOut = this.fileOut.bind(this);
		this.publishClicked = this.publishClicked.bind(this);
		this.threeJsThumbCreated = this.threeJsThumbCreated.bind(this);
		
		this.mapupload_ref = React.createRef();
		this.btnSelected = this.btnSelected.bind(this);
		this.checked = this.checked.bind(this);
	}


	
	publishClicked(e)
	{
		if(this.model.published_at == null)
			CreatePreview(this.threeJsThumbCreated);
		else
			this.threeJsThumbCreated("");	
	}

	threeJsThumbCreated(imgData)
	{
		if(imgData == null)
		{
			this.props.addAppMessage("Preview not created",  "", APP_MESSAGE_TYPE_ERROR);
		}
		else if(imgData!=="")
			this.model.previewImage = imgData;
		
		this.model.getUpdatedVersionId = "";
		this.lastupdatedVersionId = "";
		
		if(this.model.published_at == null)
			this.model.published_at = new Date().toISOString().slice(0, 16).replace('T',' ');
		else
			this.model.published_at = null;

		
		this.props.updateVersion(this.model);
	}



	checkIfEditAvailable()
	{
		if(this.isPublished)
		{
			this.props.addAppMessage("", i18n.t("version_edit_disabled_because_published"), APP_MESSAGE_TYPE_ERROR);
			return false;
		}

		return true;
	}
	btnSelected(type, action)
	{
	
		//action: "download, upload"
		//type: "cm, em, mm, nm, rm, ao"
		if(action === CONST.upload)
		{
			if(!this.checkIfEditAvailable())
				return;
			this.pending_file_type = type;
			document.getElementById('map_file').click();
		}
		else
		{
			let _3dModel = Get3dObjectFromVersionModel(this.model);
			let path = "";
			if(type === CONST.cm)
				path = _3dModel.color;
			else if(type === CONST.mm)
				path = _3dModel.metallic;
			else if(type === CONST.rm)
				path = _3dModel.roughness;
			else if(type === CONST.nm)
				path = _3dModel.normal;
			else if(type === CONST.em)
				path = _3dModel.emissive;
			else if(type === CONST.ao)
				path = _3dModel.ao;
			else if(type === CONST.om)
				path = _3dModel.om;
			
			if(path.length>5)
				window.open(path, "_blank");
			else
				this.props.addAppMessage(i18n.t("versionMapImage_donwloadUrl_error_title"), i18n.t("versionMapImage_donwloadUrl_error_text"), APP_MESSAGE_TYPE_ERROR);
		}
	}

	
	checked(type, isActive)
	{
		if(type === CONST.om)
		{
			this.model.omActive = isActive;
			//this.setState({model:this.model})
			
			this.props.updateVersion(this.model);
		//	_3dModel.omActive = this.model.omActive;
		}
	}

	//#region filedrop

	fileChanged(e)
	{		
		if(e.target.files!=null && e.target.files.length>0)
			this.filesSelected(e.target.files);					
	}


	fileOver(e)
	{
		if(this.mapupload_ref.current.className.indexOf("active") === -1)
			this.mapupload_ref.current.className+=" active";
	}

	fileOut(e)
	{
		if(this.mapupload_ref.current.className.indexOf("active") !== -1)
			this.mapupload_ref.current.className = this.mapupload_ref.current.className.split(" active").join("");
	}
	

	fileDropped(e)
	{
		this.pending_file_type = null;
		e.stopPropagation();
		e.preventDefault();
		let files = [];
		if (e.dataTransfer.items) {
			for (var i = 0; i < e.dataTransfer.items.length; i++) {
				if (e.dataTransfer.items[i].kind === 'file') {
					var _file = e.dataTransfer.items[i].getAsFile();
					files.push(_file);
				}
			}
		} 
		else {
			if(e.dataTransfer.files.length>0)
				files = e.dataTransfer.files;	
		}
		this.filesSelected(files);
	}

	filesSelected(files)
	{
		if(!this.checkIfEditAvailable())
				return;
		if(files == null || files.length===0)
		{
			this.props.addAppMessage(i18n.t("fileuploader_errorInselectedFile_title"),  "null", APP_MESSAGE_TYPE_ERROR);
			return;
		}

		this.model.files = []; 
		this.fileReadQueue = [];
		
		
		for(let i=0; i<files.length;i++)
		{
			if(files[i].size > 10 * 1024 * 1024)
			{
				this.props.addAppMessage(i18n.t("fileuploader_versionDetailsFileToBig_title"), i18n.t("fileuploader_versionDetailsFileToBig_text").split("[#filename]").join(files[i].name), APP_MESSAGE_TYPE_ERROR);			
				return;
			}
			if(this.pending_file_type==null)
			{
				if(!this.filenames.includes(files[i].name))
				{
					this.props.addAppMessage(i18n.t("fileuploader_versionDetailsFilenameUnknown_title"), i18n.t("fileuploader_versionDetailsFilenameUnknown_text").split("[#filename]").join(files[i].name), APP_MESSAGE_TYPE_WARNING);			
				}
				else
				{
					
					this.fileReadQueue.push(files[i].name);
					let reader = new FileReader();
					reader._name = files[i].name;
					reader.readAsDataURL(files[i]);
					reader.onload = function (e) {
						if(this.fileReadQueue.indexOf(reader._name)>=0)
							this.fileReadQueue.splice(this.fileReadQueue.indexOf(reader._name), 1);
						
						if(files[i].name === "om.png")
							this.model.omActive = true;
						this.model.files.push({file:reader.result,name:reader._name});
						if(this.fileReadQueue.length<=0)
						{
						
							this.props.addAppMessage("", i18n.t("fileuploader_version_maps_uploaded"), APP_MESSAGE_TYPE_INFO);			
							this.props.updateVersion(this.model);
						}
						else
							;//this.props.addAppMessage("Image loaded", reader._name, APP_MESSAGE_TYPE_INFO);			
					}.bind(this);
			
					reader.onerror = function (error) {
						this.props.addAppMessage(i18n.t("fileuploader_errorInselectedFile_title"),  "null", APP_MESSAGE_TYPE_ERROR);
					};
				}
			}
		
			else
			{
				this.fileReadQueue.push(files[0].name);
				let reader = new FileReader();
				reader._name = files[0].name;
				reader.readAsDataURL(files[0]);
				reader.onload = function (e) {
					if(this.fileReadQueue.indexOf(reader._name)>=0)
						this.fileReadQueue.splice(this.fileReadQueue.indexOf(reader._name), 1);
					this.model.files.push({file:reader.result, name:this.pending_file_type+".png"});
					if(this.fileReadQueue.length<=0)
					{			
						this.props.addAppMessage("", i18n.t("fileuploader_version_maps_uploaded"), APP_MESSAGE_TYPE_INFO);		
						this.props.updateVersion(this.model);
					}
					else
						this.props.addAppMessage("Image loaded", reader._name, APP_MESSAGE_TYPE_INFO);			
					this.pending_file_type=null;
				}.bind(this);
		
				reader.onerror = function (error) {
					this.props.addAppMessage(i18n.t("fileuploader_errorInselectedFile_title"),  "null", APP_MESSAGE_TYPE_ERROR);
				};
			}
		}
	}

	fileSelected(file)
	{		
		if(file == null)
		{
			this.props.addAppMessage(i18n.t("fileuploader_errorInselectedFile_title"),  "null", APP_MESSAGE_TYPE_ERROR);
			return;
		}
		this.filesSelected([file]);
	}

	//#endregion filedrop

	extendedClicked(e)
	{
		setVersionExtendedActivated(!this.state.extended);
		this.setState({extended: !this.state.extended});
	}


	render()
	{
		//render if all projects are loaded
		this.project = this.props.__projects;
		
		//console.log("2 redner")

		this.pending_file_type=null;
		this.model = findVersionById(this.actualVersionId);
		
		if(this.actualVersionId !== this.props.versionid)
		{
			this.model = null; 
			this.model = findVersionById(this.props.versionid);		
			if(this.model!=null)
				this.actualVersionId = this.props.versionid;			
		}

		if(this.model!=null && this.published_at != this.model.published_at)
		{
		//	console.log("changed",  this.model.published_at)

			this.published_at = this.model.published_at;

			this.props.versionStatsUpdated(this.model);
		}
	
		if(this.model == null || this.props.__projects.selectedProjectId === this.props.__projects.deletedId)
		{
			return(
				<div className={"VersionDetailCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<div className="title">
					<span>{i18n.t("version_details_title")}</span>
				</div>
				</div>
			);
		}
		let project = findProjectById(this.model.project_id);
		
		this.isPublished = this.model.published_at!=null;
		if(project!=null)
			this.model.obj_file = project.obj_file;
		return(
			<div className={"VersionDetailCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<div className="title">
					<span>{i18n.t("visualization_title") + " _" + this.model.version}</span>			
					<div className="buttons right">
				
					
						<Button color={this.model.published_at==null?"green":"red"} onClick={this.publishClicked} className={"publish" + ((this.model==null || !HasPermission(273))?" hidden":"")}>{this.model.published_at==null?i18n.t("projects_details_publish_btn"):i18n.t("projects_details_unpublish_btn")}</Button>	
						<a className={"Button round black"+(project==null || (project.hash==null || project.hash==="")?" hidden":"")} href={project!=null?"/#/projectview/"+project.hash:""} target="_blank" rel="noopener noreferrer"><span className="Button-content">{i18n.t("visualization_project_btn")}</span></a>					
						<Button color="black" onClick={this.props.showCommentsCallback}>{i18n.t("visualization_comment_btn")}</Button>	
					
					</div>		
				</div>
				<div className={"content"}>
				<div className={"left"+ (HasOnOfPermissions(272,273)?"":" hidden")+(this.isPublished?" disabled":"")}>
					<div className="DropTarget noselect" ref={this.mapupload_ref}>
						<form>
							<div>
								<div>
									<div>
										<span dangerouslySetInnerHTML={{__html: i18n.t("visualization_uploadtext_btn")}}></span>
									</div>
								</div>
							</div>
							<input className="_file" type="file" name="map_file" id="map_file" multiple  onDrop={this.fileDropped} onDragOver={this.fileOver} onDragLeave={this.fileOut} onChange={this.fileChanged}  />
						</form>
					</div>

					<Icon className="eye blue" type={this.state.extended?"minus":"plus"} onClick={this.extendedClicked}></Icon>
					<div className="extendedMode noselect" onClick={this.extendedClicked}>
						<Icon type="dot" status={this.state.extended?CONST.ok:CONST.error}></Icon>
						{i18n.t("visualization_extended_mode")}
					</div>
				</div>
				<div className="right">
					<div className={"rows"+(this.isPublished?" disabled":"")}>
					<CustomScroll heightRelativeToParent="calc(100% - 0px)">
						<VersionMapImageRow disabled={!HasOnOfPermissions(272,273)} status={this.model.color_map!=="default/cm.png"} name={i18n.t("version_map_color_label")} type={CONST.cm} selected={this.btnSelected} ></VersionMapImageRow>
						<hr/>
						<VersionMapImageRow disabled={!HasOnOfPermissions(272,273) || !this.state.extended} status={this.model.metallic_map!=="default/mm.png"} name={i18n.t("version_map_metallic_label")} type={CONST.mm} selected={this.btnSelected} ></VersionMapImageRow>
						<hr/>
						<VersionMapImageRow disabled={!HasOnOfPermissions(272,273) || !this.state.extended} status={this.model.roughness_map!=="default/rm.png"} name={i18n.t("version_map_roughness_label")} type={CONST.rm} selected={this.btnSelected} ></VersionMapImageRow>
						<hr/>
						<VersionMapImageRow disabled={!HasOnOfPermissions(272,273) || !this.state.extended} status={this.model.normal_map!=="default/nm.png"} name={i18n.t("version_map_normal_label")} type={CONST.nm} selected={this.btnSelected} ></VersionMapImageRow>
						<hr/>
						<VersionMapImageRow disabled={!HasOnOfPermissions(272,273) || !this.state.extended} status={this.model.emmisive_map!=="default/em.png"} name={i18n.t("version_map_emmisive_label")} type={CONST.em} selected={this.btnSelected} ></VersionMapImageRow>
						<hr/>
						<VersionMapImageRow disabled={!HasOnOfPermissions(272,273) || !this.state.extended} status={this.model.opacity_map!=="default/oa.png"} isCheckBox={true} isChecked={this.model.omActive === true} name={i18n.t("version_map_opacity_label")} type={CONST.om} checked={this.checked} selected={this.btnSelected} ></VersionMapImageRow>
						<hr/>
						<VersionMapImageRow disabled={!HasOnOfPermissions(272,273) || !this.state.extended} status={this.model.ambient_map!=="default/ao.png"} name={i18n.t("version_map_ambient_occ_label")} type={CONST.ao} selected={this.btnSelected} ></VersionMapImageRow>
					</CustomScroll>	
					</div>
					<div className="versionPreview">
					<ThreeContainer model={this.model}></ThreeContainer>
					</div>
				</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({	
	updatedVersionId: getUpdatedVersionId(state),	
	__versions: getVersionsReducer(state),
	__projects: getProject(state),
	getLastUpdate:getLastUpdate(state)

})

const mapDispatchToProps = dispatch => bindActionCreators(
	{
		showAlertMessage,
		getVersions:getAllByProjectId, 
		setSelectedVersionId, 
		addAppMessage,
		updateVersion,
		versionStatsUpdated
	}, 
	dispatch
);

export default connect(mapStateToProps,mapDispatchToProps)(VersionDetailCard);

