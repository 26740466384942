
import {RESPONSE_TYPE} from './../api/_api.js';


export const AR_PROJECT_GET_PENDING = 'AR_PROJECT_GET_PENDING';
export const AR_PROJECT_GET_SUCCESS = 'AR_PROJECT_GET_SUCCESS';
export const AR_PROJECT_GET_ERROR = 'AR_PROJECT_GET_ERROR';


export function fetchArProject_get(type, info, payload) {	
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: AR_PROJECT_GET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
	
		return {
			type:  AR_PROJECT_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type:  AR_PROJECT_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}
