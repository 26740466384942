import {COMMENTS_GET_PENDING, COMMENTS_GET_SUCCESS, COMMENTS_GET_ERROR, COMMENT_SET_SELECTED_ID, COMMENT_SET_PENDING, COMMENT_SET_ERROR, COMMENT_SET_SUCCESS} from './../actions/comments';
import {parseComment, parseComments, deleteComment, addComment} from './../_models/CommentsModel';
import {initialState}  from './../initialState.js';


export function commentReducer(state = initialState, action) {
	
	switch(action.type) {			
		case COMMENTS_GET_PENDING: 
	  	return {
			...state,
			error: null,
			pending: true
     	}
		case COMMENTS_GET_SUCCESS:	
		  return {
			...state,
			pending: false,
			info: action.info,
			list: parseComments(action.payload),
		
    }
    case COMMENT_SET_ERROR:			
		  return {
      ...state,
      pending: false,
      error: action.info
		}
		case COMMENT_SET_PENDING: 		
	  	return {
			...state,
			error: null,
			pending: true
     	}
		case COMMENT_SET_SUCCESS:
			
			if(action.info.id === "000292")
			{
				return {
				...state,
				pending: false,
				info: action.info,	
				list: addComment(parseComment(action.payload)),
				created:action.payload.id
				}
			}
			else if(action.info.id === "000293")
			{
				return {
				...state,
				pending: false,
				info: action.info,	
				updated:true		
				}
			}
			else if(action.info.id === "000294")
			{
				return {
				...state,
				pending: false,
				info: action.info,		
				list: deleteComment(action.payload),
				selectedCommentId: -1
				}
			}
			break;
    case COMMENTS_GET_ERROR:
		  return {
      ...state,
      pending: false,
      error: action.info
		}
		case COMMENT_SET_SELECTED_ID:	
				return {
				...state,
				selectedCommentId: action.payload				
			}
    default: 
      return state;
  }
}

export const getCommentsReducer = state => state.comment;
export const getSelectedCommentId = state => state.comment.selectedCommentId;

