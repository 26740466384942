import React, {Component} from 'react';
import Label from './Label';

import "./TextInput.css";

class TextInput extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {value:""}
		this.onTextChange = this.onTextChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
	}

	onFocus(event){
		console.log("focus");
		if(this.props.model.val==="new")
			this.props.model.val = "";
		
		this.setState({value:event.target.value})		
	};

	onTextChange(event){
		this.props.model.val=event.target.value;
		this.setState({value:event.target.value})		
	};

	render()
	{
		return(
			<div className={"TextInput"+ (this.props.className!=null?" "+this.props.className:"") + (this.props.editable?" editable":"")+ (this.props.error?" error":"")}> 
				<Label value={this.props.label}></Label>
				<input 
					type={this.props.type!=null?this.props.type:"text"} 
					className="TextInput-content" 
					placeholder={this.props.placeholder!=null?this.props.placeholder:""} 
					onChange={this.onTextChange} 
					onFocus={this.onFocus}
					value={this.props.model!=null?this.props.model.val:""}>
					</input>
			</div>
		);
	}
}
export default TextInput;