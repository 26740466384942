export const API_REQUEST_ADD = 'API_REQUEST_ADD';
export const API_REQUEST_DONE = 'API_REQUEST_DONE';

export function apiRequestStart() {
	return {
	 	type: API_REQUEST_ADD
	}
}

export function apiRequestDone() {
	return {
	 	type: API_REQUEST_DONE
	}
}

