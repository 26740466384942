import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';


import {createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { Provider } from 'react-redux';
import { Route} from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";

import App from 'App';
import './index.css';

import {loginReducer} from "reducers/login";
import {companyReducer} from "reducers/company";
import {configReducer} from "reducers/config";
import {apiReducer} from "reducers/api";
import {appReducer} from "reducers/app";
import {initialState} from 'initialState';
import {projectReducer} from 'reducers/projects';
import {versionReducer} from 'reducers/versions';
import {commentReducer} from 'reducers/comments';
import {arProjectReducer} from 'reducers/arproject';
import {reportsReducer} from 'reducers/reports';

import {companyMembersReducer} from 'reducers/companyMembers';
const middlewares = [thunk];

const rootReducer = combineReducers({login:loginReducer, company:companyReducer, config:configReducer, apiReducer, app:appReducer, project:projectReducer, version:versionReducer, comment:commentReducer, arproject:arProjectReducer, reports:reportsReducer, companyMembers:companyMembersReducer});
const store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));

ReactDOM.render(
	<Provider store={store}>
		<HashRouter> 
			<I18nextProvider i18n={i18n}>
				<Route path="/" component={App}  />
				<div id="threejs"></div>
			</I18nextProvider>
		</HashRouter> 	
	</Provider>
	,
  document.getElementById('root')
);
