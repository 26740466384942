import React, {Component} from 'react';
import CustomScroll from 'react-custom-scroll';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {addAppMessage} from './../api/appMessage';
import {APP_MESSAGE_TYPE_TODO} from './../actions/app';

import "./RightsMatrix.css";
import i18n from "../i18n";

import CheckBox from './CheckBox';
import Label from './Label';

class RightsMatrix extends Component
{
	constructor(props) {
		super(props);
		
		this.state = 
		{
			data: this.props.data
		}
		this.saveClicked = this.saveClicked.bind(this);
		this.checkboxClicked = this.checkboxClicked.bind(this);
		
	}

	checkboxClicked(e)
	{
		let role_id = e.currentTarget.dataset.id.split("_")[0];
		let permission_id = e.currentTarget.dataset.id.split("_")[1];
		let tmpstate = this.state.data;
		tmpstate.rolePermissions[role_id][permission_id] = tmpstate.rolePermissions[role_id][permission_id]!==1?1:0;
		this.setState(
			{data: tmpstate}
		)
	}
	saveClicked(e)
	{
		this.props.addAppMessage("0", "api not connected yet", APP_MESSAGE_TYPE_TODO);			
	}
	
	render()
	{
		
		if(this.props.data == null)
			return("Loading");
		else if(this.state.data==null)
		{
			//this.state.data = this.props.data;
			this.setState({data:this.props.data});
		}

		var data = this.state.data;
		
		let headerChildren = [<th key="th"></th>];
		for(let i=0;i<data.allRoles.length;i++)
		{
			if(data.allRoles[i].id===1)
				continue;
			headerChildren.push(<th  key={"th"+i}>{data.allRoles[i].name}</th>);
		}
		
		let bodyChildren = [];
		
		for(let i=0;i<data.allPermissions.length;i++)
		{
			let row = [<td key={i}>{data.allPermissions[i].description}</td>];
			for(let j=0;j<data.allRoles.length;j++)
			{
				if(data.allRoles[j].id===1)
					continue;
			
				let p = data.rolePermissions[data.allRoles[j].id][data.allPermissions[i].id];
				
				row.push(<td key={i+"_"+j}>	<CheckBox checked={(p===1?true:false)} id={data.allRoles[j].id+"_"+data.allPermissions[i].id} title={data.allRoles[j].name} onClick={this.checkboxClicked}></CheckBox></td>);
			}
			bodyChildren.push(<tr key={i}>{row}</tr>);
		}
			

		return(
			<div className="RightsMatrix">
				<Label>{i18n.t("config_permiossions_label")}</Label>
				<CustomScroll heightRelativeToParent="calc(100% - 20px)">
				<table>
					<thead>
						<tr>
							{headerChildren}
						</tr>
					</thead>
					<tbody>
					 {bodyChildren}
					</tbody>
				</table>
				</CustomScroll>

			
			</div>
		);
	}
}


const mapStateToProps = state => ({

})

//const mapDispatchToProps = dispatch => bindActionCreators({passResetRequest: passResetRequest, addAppMessage: addAppMessage}, dispatch);
const mapDispatchToProps = dispatch => bindActionCreators({addAppMessage: addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(RightsMatrix);
