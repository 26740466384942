import React, {Component} from 'react';
import {getCompanies} from './../reducers/login';
//import {updateCompany} from './../api/login';
import {create as createCompany} from './../api/company';
import {showAlertMessage} from 'api/appMessage';
import {getUser} from './../reducers/login';
import {getUpdatedCompany, getCreatedCompany, getCompany, getSelectedCompanyId} from './../reducers/company';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {findById, getCompaniesGroupedByDepth, GetCompaniesByScope} from './../_models/CompanyModel';
import CompanyDetailsCard from '../ui/Company/CompanyDetailsCard';
import CompanyCard from '../ui/Company/CompanyCard';
import i18n from '../i18n';
import { Redirect } from 'react-router-dom';
import { CONST } from '../Constants';
import SearchDetails from '../ui/SearchDetails';


class CompanyView extends Component
{
	constructor(props) {
		super(props);
	
		this.newClicked = this.newClicked.bind(this);
		this.newInLevelClicked = this.newInLevelClicked.bind(this);
		this.deleteClicked = this.deleteClicked.bind(this);
	}

	deleteClicked(e)
	{
	
		let company = this.props.company.selectedCompany;
		if(e.currentTarget.classList.contains("minus"))//delete in companyleveldownClients
		{
			console.log(findById(e.currentTarget.dataset.id, this.props.companies))
			company = findById(e.currentTarget.dataset.id, this.props.companies);
			if(company!=null)
				company = company.value;
		}
		if(company)
			this.props.showAlertMessage({text:i18n.t("deletealert_message").split("[#1]").join(company.name), data:company, scope:CONST.clients});
	}

	newInLevelClicked(level)
	{		
		let company = GetCompaniesByScope("clients");
		let res = getCompaniesGroupedByDepth(this.props.match.params.companyid, company.entries);//
		let parent_id ="";
		if(level>0)
		{
			for(let i=0; i<res.stack.length;i++)
			{
				if(level==res.stack.length-i)
				{
					parent_id=res.stack[i].value.id;
					break;
				}
			}
		}
		
		let ob = {name:i18n.t("company_new_name"), parent_company_id:parent_id, permissionsRoleId: 5, user:{email:this.props.user.email, user_id:this.props.user.user_id}};
		this.props.createCompany(ob);
	}

	newClicked(e)
	{
		let ob = {name:i18n.t("company_new_name"), parent_company_id:this.props.match.params.companyid, permissionsRoleId: 5, user:{email:this.props.user.email, user_id:this.props.user.user_id}};
		this.props.createCompany(ob);
	}

	render()
	{
		if(this.props.updatedCompany!=null && this.props.companies!=null)
		{		
			this.props.company.updatedCompany = null;	
		//	this.props.updateCompany(this.props.updatedCompany);
		}
		

		if(this.props.createdCompany!=null && this.props.createdCompany.id != this.props.match.params.companyid)
		{
			
			let id = this.props.createdCompany.id;
			this.props.company.createdCompany = null;
			return(<Redirect to={"/clients/"+id}></Redirect>);
		}
		if(this.props.company.deletedCompany!=null)
		{
		//
		
			//console.log(this.props.company.deletedCompany)
			let id = this.props.company.deletedCompany.parent_company_id;
			if(id == null)
				id = "";
			this.props.company.deletedCompany = null;
			
			return(<Redirect to={"/clients/"+id}></Redirect>);
		}
		

		let children = [];
		let _ch = findById(this.props.match.params.companyid, this.props.companies);
		let _parentName = "PARENT";
	//	console.log(this.props.match.params.search);
		if(_ch!=null && _ch.value!=null)
		{
			children = _ch.value.children;
			let p = findById(_ch.value.parent_company_id, this.props.companies);
			if(p != null)
				_parentName = p.value.name;
		}
		return(
			<>
			<CompanyCard companyid={this.props.match.params.companyid} deleteClicked={this.deleteClicked} newInLevelClicked={this.newInLevelClicked} newClicked={this.newClicked} scope="clients" gridpositions="_r3-4 _c1"></CompanyCard>
			{this.props.match.params.search?<SearchDetails search={this.props.match.params.search} scope={CONST.clients}></SearchDetails>:<CompanyDetailsCard companyid={this.props.match.params.companyid} companies={this.props.companies} children={children} parentName={_parentName} deleteClicked={this.deleteClicked}   newClicked={this.newClicked} ></CompanyDetailsCard> }
			
			</>
		);
	}
}

const mapStateToProps = state => ({
	companies: getCompanies(state),
	updatedCompany: getUpdatedCompany(state),
	createdCompany: getCreatedCompany(state),
	getSelectedCompanyId:getSelectedCompanyId(state),
	
	company: getCompany(state),
	user: getUser(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({/*updateCompany,*/ createCompany, showAlertMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CompanyView);

