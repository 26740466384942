import {AR_PROJECT_GET_ERROR, AR_PROJECT_GET_SUCCESS, AR_PROJECT_GET_PENDING} from './../actions/ar';
import {initialState}  from './../initialState.js';


export function arProjectReducer(state = initialState, action) {

	switch(action.type) {			
		case AR_PROJECT_GET_PENDING: 
	  	return {
			...state,
			error: null,
			pending: true
     	}
		case AR_PROJECT_GET_SUCCESS:	
		  return {
			...state,
			pending: false,
			info: action.info,
			project: action.payload,		
    }
    case AR_PROJECT_GET_ERROR:			
		  return {
      ...state,
      pending: false,
      error: action.info
		}
    default: 
      return state;
  }
}
export const getArProject = state => state.arproject;

