
import {RESPONSE_TYPE} from './../api/_api.js';

export const REPORTS_GET_PENDING = 'REPORTS_GET_PENDING';
export const REPORTS_GET_SUCCESS = 'REPORTS_GET_SUCCESS';
export const REPORTS_GET_ERROR = 'REPORTS_GET_ERROR';

export function fetchReports_get(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: REPORTS_GET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type:  REPORTS_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type:  REPORTS_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}