import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18n from 'i18n';
import {GetError} from 'Errors';
import {addAppMessage} from 'api/appMessage';
import { APP_MESSAGE_TYPE_ERROR} from 'actions/app';
import {setNewPass} from 'api/login';

import {getPassResetDone, getPassResetError, getPassResetPending} from 'reducers/login';
import {colorizeAdamText} from 'Utils';
import TextInput from './../TextInput';
import Button from './../Button';

import "./../Card.css";
import "./CardLogin.css";
import { Redirect } from 'react-router-dom';


class CardLoginPassReset extends Component
{
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.onSubmit = this.onSubmit.bind(this);		
		this.state = {email:{val:this.props.match.params.email}, emailid:{val:this.props.match.params.emailid}, pass1:{val:""}, pass2:{val:""}, passInputErorrs:false};
	}

	handleClick() {
	
		if(this.state.pass1.val !== this.state.pass2.val)
		{
			let err = GetError({id:"app002", "msg":"Passes differ"});
			this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);
			this.setState({...this.state, passInputErorrs:true });
		}
		else if(this.state.pass1.val.length<6)
		{
			let err = GetError({id:"app003", "msg":"Pass to short"});
			this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);				
			this.setState({...this.state, passInputErorrs:true });
		}
		else
		{			
			this.setState({...this.state, passInputErorrs:false});
			this.props.setNewPass({"email":this.state.email.val,"emailid":this.state.emailid.val,"pass":this.state.pass1.val});
		}
		
  }

	onSubmit(event){
		event.preventDefault();
		this.handleClick();
	}


	render()
	{
		if(this.state == null)
		{
		//	this.state = {email:{val:this.props.match.params.email}, emailid:{val:this.props.match.params.emailid}, pass1:{val:""}, pass2:{val:""}, passInputErorrs:false}
		}
		const {result, error, pending} = this.props;
		if(result==null && error == null && pending == null)
		{
		
		}
		else if(!pending)
		{
			if(error != null)
			{
				var err = GetError({id:error.info.id, "msg":error.info.msg});
				this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);
			}
			if(result)
			{
				return(<Redirect to="/"></Redirect>)
			}
		}

		return(
			<div className="Login Card round">
				<div className="Card-title Login-title">					
					<span dangerouslySetInnerHTML={{__html:colorizeAdamText(i18n.t("login_pass_reset_title"))}}></span>
				</div>
				<div className="Card-content Login-content">
					<form onSubmit={this.onSubmit}>
					<TextInput editable={true} label={i18n.t("login_name_label")} placeholder={i18n.t("login_name_placeholder")} type="text" model={this.state.email} className={this.props.match.params.email!=null?"hidden":""}></TextInput>
					<TextInput editable={true} label={i18n.t("login_pass_reset_emailid_label")} placeholder={i18n.t("login_pass_reset_emailid_placeholder")} type="text" model={this.state.emailid}  className={this.props.match.params.emailid!=null?"hidden":""}></TextInput>
					<TextInput editable={true} label={i18n.t("login_pass_reset_newpass1_label")} placeholder={i18n.t("login_pass_label")} type="password" model={this.state.pass1}></TextInput>
					<TextInput editable={true} label={i18n.t("login_pass_reset_newpass2_label")} placeholder={i18n.t("login_pass_label")} type="password" model={this.state.pass2}></TextInput>
					<Button className="login_btn" onClick={this.handleClick} color="green">{i18n.t("login_pass_reset_setpass_btn_label")}</Button>
					<div className="login_bottom">
						<div className="login_icon">
							<div className={"login_status " + (result!=null?"ok":((this.state.passInputErorrs || error!=null)?"error":"new_pass"))}></div>
						</div>
					
						<input type="submit" className="hidden"></input>
					</div>			
					</form>	
				</div>
			</div>
		);
		}
	
}

const mapStateToProps = state => ({
	result: getPassResetDone(state),
	error: getPassResetError(state),
	pending: getPassResetPending(state)
})

//const mapDispatchToProps = dispatch => bindActionCreators({passResetRequest: passResetRequest, addAppMessage: addAppMessage}, dispatch);
const mapDispatchToProps = dispatch => bindActionCreators({setNewPass:setNewPass, addAppMessage: addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CardLoginPassReset);

