export const CONST = {
  download: 'download',
	upload: 'upload',
	ao: 'ao',
	cm: 'cm',
	mm: 'mm',
	rm: 'rm',
	nm: 'nm',
	em:	'em',
	om: 'om',
	ok: 'ok',
	error: 'error',
	new: 'new',
	short: 'short',
	clients: 'clients',
	project: 'project',
	sales: 'sales',
	report: 'report',
	settings: 'settings',
	config:'config',
	agency:'agency',
	version:'version'
	
};