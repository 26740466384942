import React, {Component} from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from "i18n";

import {GetPermissionClassName as Permission} from './../../../_models/Permissions'; 
import {APP_MESSAGE_TYPE_INFO, APP_MESSAGE_TYPE_TODO} from './../../../actions/app';

import {getSelectedProjectId} from './../../../reducers/projects';
import {addAppMessage} from './../../../api/appMessage';

import ContactsList from '../ContactsList';
import "./SalesVersionDetailsCard.css";
import TextInputModel from './../../TextInputModel';
import Button from './../../Button.js';
import Label from './../../Label.js';
import {getLastUpdate} from './../../../reducers/company';

class SalesVersionDetailsCard extends Component
{


	constructor(props) {
		super(props);
		
		
		this.copyOnClick = this.copyOnClick.bind(this);
		this.doNothing = this.doNothing.bind(this);
		
		this.inputRref = React.createRef();
	}

	doNothing(e)
	{

	}
	copyOnClick(e) {
		this.inputRref.current.select();
		this.inputRref.current.setSelectionRange(0, 99999);
		document.execCommand("copy");
		
		this.props.addAppMessage("", i18n.t("msg_app_901_link_copied_to_clipboard"), APP_MESSAGE_TYPE_INFO);				
	}

	componentDidUpdate()
	{
		if(this.inputRref.current!=null)
			this.inputRref.current.scrollLeft = this.inputRref.current.scrollWidth;
	}
	
	render()
	{
		if(this.props.project == null)
			return("");
		
		//console.log(Permission(301))
		
		return(
			<div className={"SalesVersionDetailsCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<div className="SalesVersionDetailsCard-title Card-title">
					<span>{this.props.project.name}</span>
					<span className="right">{this.props.project.article_nr}</span>					
				</div>
				<div className="SalesVersionDetailsCard-content Card-content">
					<div className="left">
					<TextInputModel _key="name" placeholder={i18n.t("projects_details_name_placeholder")} label={i18n.t("projects_details_name_label")}  model={this.props.project} editable={false}></TextInputModel>
					<TextInputModel _key="article_nr" placeholder={i18n.t("projects_details_articlenr_placeholder")} label={i18n.t("projects_details_articlenr_label")}  model={this.props.project} editable={false}></TextInputModel>
					<TextInputModel _key="job_nr" placeholder={i18n.t("projects_details_jobnr_placeholder")} label={i18n.t("projects_details_jobnr_label")}  model={this.props.project} editable={false}></TextInputModel>
					<TextInputModel _key="material" placeholder={i18n.t("projects_details_material_placeholder")} label={i18n.t("projects_details_material_label")}  model={this.props.project} editable={false}></TextInputModel>
					<TextInputModel _key="height" placeholder={i18n.t("projects_details_height_placeholder")} label={i18n.t("projects_details_height_label")}  model={this.props.project} editable={false}></TextInputModel>
					<TextInputModel _key="width" placeholder={i18n.t("projects_details_width_placeholder")} label={i18n.t("projects_details_width_label")}  model={this.props.project} editable={false}></TextInputModel>	
					<TextInputModel _key="depth" placeholder={i18n.t("projects_details_depth_placeholder")} label={i18n.t("projects_details_depth_label")}  model={this.props.project} editable={false}></TextInputModel>	
					</div>
					<div className="right">
						<div className={"block public_url"+Permission(301)}>
							<Label value={i18n.t("sales_direct_link_to_ar_label")} className="black"></Label>
							<div className="url_row">
								<input type="text" className="public_url" value={this.props.project.url} ref={this.inputRref } onChange={this.doNothing}></input>
								<Button onClick={this.copyOnClick}>{i18n.t("sales_direct_link_to_ar_copy_btn")}</Button>
							</div>
						</div>
						<hr></hr>
						<ContactsList projectid={this.props.project.id}></ContactsList>
					</div>
				</div>
			</div>
		);
	}	
}

const mapStateToProps = state => ({	selectedProjectId:getSelectedProjectId(state),getLastUpdate:getLastUpdate(state)})
const mapDispatchToProps = dispatch => bindActionCreators({addAppMessage: addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(SalesVersionDetailsCard);
