
import {RESPONSE_TYPE} from './../api/_api.js';

export const VERSIONS_GET_PENDING = 'VERSIONS_GET_PENDING';
export const VERSIONS_GET_SUCCESS = 'VERSIONS_GET_SUCCESS';
export const VERSIONS_GET_ERROR = 'VERSIONS_GET_ERROR';

export const VERSION_SET_PENDING = 'VERSION_SET_PENDING';
export const VERSION_SET_SUCCESS = 'VERSION_SET_SUCCESS';
export const VERSION_SET_ERROR = 'VERSION_SET_ERROR';

export const VERSION_SET_SELECTED_ID = 'VERSION_SET_SELECTED_ID';

export function fetchVersions_get(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: VERSIONS_GET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type:  VERSIONS_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type:  VERSIONS_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function fetchSelectedVersionId_set(payload) {
	return {	
		type:  VERSION_SET_SELECTED_ID,
		payload: payload
	};
	
}

export function fetchVersion_set(type, info, payload) {

	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: VERSION_SET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type:  VERSION_SET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type:  VERSION_SET_ERROR,
			info: info,
			payload: payload
		};
	}
}
