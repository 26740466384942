import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {CONST} from './../../Constants';

import MainNavigationItem from './MainNavigationItem';
import MainNavigationUserItem from './MainNavigationUserItem';

import {getCompanies} from './../../reducers/login';
import {GetCompaniesByScope} from './../../_models/CompanyModel';
import "./MainNavigation.css";

class MainNavigation extends Component
{
	render()
	{
		let clientAvailable = true;
		let com = GetCompaniesByScope(CONST.clients);
		if(com.locked || com.entries == null || com.entries.length === 0 )
			clientAvailable = false;

		let agencyAvailable = true;
		com = GetCompaniesByScope(CONST.agency);
		if(com.locked || com.entries == null || com.entries.length === 0)
			agencyAvailable = false;

		let salesAvailable = true;
		com = GetCompaniesByScope(CONST.sales);
		if(com.locked || com.entries == null || com.entries.length === 0)
			salesAvailable = false;
		
		let reportsAvailable = true;
		com = GetCompaniesByScope(CONST.report);
		if(com.locked || com.entries == null || com.entries.length === 0)
			reportsAvailable = false;
		
		let configAvailable = true;
		com = GetCompaniesByScope(CONST.config);
		if(com.locked || com.entries == null || com.entries.length === 0)
			configAvailable = false;
	
		if(this.props.visible === false)
			return(<></>);
		else if(this.props.isboard === true)
		{
			return(
				<div className="MainNavigation board">
					<div className="MainNavigation-content">
						<MainNavigationItem className={"_r2 _c1"+(!clientAvailable?" hidden":"")} linkToRoot={true}>clients</MainNavigationItem>
						<MainNavigationItem className={"_r2 _c2"+(!agencyAvailable?" hidden":"")} linkToRoot={true}>agency</MainNavigationItem>
						<MainNavigationItem className={"_r2 _c3"+(!salesAvailable?" hidden":"")} linkToRoot={true}>sales</MainNavigationItem>
						<MainNavigationItem className={"_r2 _c4"+(!reportsAvailable?" hidden":"")} linkToRoot={true}>report</MainNavigationItem>						
						<MainNavigationItem className={"_r3 _c1"+(!configAvailable?" hidden":"")} linkToRoot={true}>config</MainNavigationItem>
						<MainNavigationUserItem className="_r3 _c2-4"></MainNavigationUserItem>						
					</div>
				</div>
			);
		}
		else
		{
			return(
				<div className="MainNavigation tile _r1-2 _c2-4">
					<div className="MainNavigation-content">
						<MainNavigationItem linkToRoot={true}>board</MainNavigationItem>
						<MainNavigationItem className={!clientAvailable?"hidden":""}>clients</MainNavigationItem>
						<MainNavigationItem className={!agencyAvailable?"hidden":""}>agency</MainNavigationItem>
						<MainNavigationItem className={!salesAvailable?"hidden":""}>sales</MainNavigationItem>
						<MainNavigationItem className={!reportsAvailable?"hidden":""}>report</MainNavigationItem>
						<MainNavigationItem className={!configAvailable?"hidden":""} linkToRoot={true}>config</MainNavigationItem>
						<MainNavigationUserItem></MainNavigationUserItem>						
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => ({
	companies: getCompanies(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(MainNavigation);