import {APP_MESSAGE_ADD, APP_MESSAGE_REMOVE, PICKER_MESSAGE_TYPE_HIDE, PICKER_MESSAGE_TYPE_PARENT, PICKER_MESSAGE_TYPE_PERMISSION_ROLE, PICKER_MESSAGE_TYPE_CHILD, ALERT_MESSAGE_TYPE_SHOW, ALERT_MESSAGE_TYPE_HIDE} from './../actions/app';
import {initialState}  from './../initialState.js';

export function appReducer(state = initialState, action) {
  switch(action.type) {
    case APP_MESSAGE_ADD: 
    	return {
				...state,
				/*messages: [...state.messages,action.message]*/
				messages: [action.message]
		}    
		case APP_MESSAGE_REMOVE: 
			action.key = parseInt(action.key);
			return {
				...state,
			messages: state.messages.filter(msg=>msg.key!==action.key)
		}   
		case PICKER_MESSAGE_TYPE_HIDE: 
			
			return {
				...state,
			picker: {type:PICKER_MESSAGE_TYPE_HIDE}
		}   
		case PICKER_MESSAGE_TYPE_PARENT: 		
			return {
				...state,
			picker: {type:PICKER_MESSAGE_TYPE_PARENT, selectedCompany:action.company_id}
			
		}   
		case PICKER_MESSAGE_TYPE_CHILD: 
			return {
				...state,
			picker:  {type:PICKER_MESSAGE_TYPE_CHILD, selectedCompany:action.company_id}
		}  
		case PICKER_MESSAGE_TYPE_PERMISSION_ROLE: 
			return {
				...state,
			picker:  {type:action.type, selectedId:action.selectedId, list:action.list}
		}  
		case ALERT_MESSAGE_TYPE_SHOW: 		
			return {
			...state,
			alertMessage:  {type:ALERT_MESSAGE_TYPE_SHOW, message:action.message}
		}
		case ALERT_MESSAGE_TYPE_HIDE: 
			return {
			...state,
			alertMessage:  {type:ALERT_MESSAGE_TYPE_HIDE, message:null}
		}
				
    default: 
			return state;
	}
}



export const getAlertMessage = state => state.app.alertMessage;
export const getMessages = state => state.app.messages;
export const getPicker = state => state.app.picker;

