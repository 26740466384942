import React, {Component} from 'react';
import {withRouter,  NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import i18n from "i18n";
import {getSelectedCompanyId} from './../../reducers/company';
import "./MainNavigationItem.css";

class MainNavigationItem extends Component
{
	render()
	{
		const isActive = (path, match, location) => !!(match || path === location.pathname);
	return(
			<div className={"MainNavigationItem round" + (this.props.className!=null?" "+this.props.className:"")+" "+this.props.children}>
			<NavLink activeClassName="selected" activeStyle={{color:"#ff0000"}} to={"/"+this.props.children+(!this.props.linkToRoot && this.props.selectedCompanyId!=null?"/"+this.props.selectedCompanyId:"")}  isActive={isActive.bind(this, "/"+this.props.children)}>
				<div className="MainNavigationItem-content">
					<div className="MainNavigationItem-bg"></div>
					<span>{i18n.t(this.props.children+"_title")}</span>
				</div>
			</NavLink >	
		</div>			
		);
	}
}
//export default withRouter(MainNavigationItem);

const mapStateToProps = state => ({
		selectedCompanyId:getSelectedCompanyId(state),
})


export default connect(mapStateToProps,null)(MainNavigationItem);
