import {CONFIG_GET_PENDING, CONFIG_GET_SUCCESS, CONFIG_GET_ERROR} from './../actions/config';
import {initialState}  from './../initialState.js';

export function configReducer(state = initialState, action) {

	switch(action.type) {			
		case CONFIG_GET_PENDING: 
	  	return {
			...state,
			error: null,
			pending: true
    }
		case CONFIG_GET_SUCCESS:			
      return {
			...state,
			pending: false,
			info: action.info,
			config: action.payload
    }
    case CONFIG_GET_ERROR:
		  return {
      ...state,
      pending: false,
      error: action.info
		}
    default: 
      return state;
  }
}

export const gotConfig = state => state.config;