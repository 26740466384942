import { CONST } from "../Constants";

let searchIndex = [];
let created_at = new Date();
let version = 0;
export function CreateSearchIndex(data)
{	
	searchIndex = data;
	version++;
	created_at = new Date();
	return created_at;
} 

export function GetVersion()
{
	return version;
}
export function ShouldRefreshIndex()
{
	return new Date() - created_at.getTime()>30*1000;
	
}

export function ResetSearchIndex()
{
	searchIndex = [];
} 


function getObjectString(id, searchPhrase)
{
	for(let id in searchIndex.data)
	{
		
		for(let o in searchIndex.data[id])
		{
			if(searchIndex.data[id][o].toLowerCase().indexOf(searchPhrase)!==-1)
			{
				return searchIndex.data[id][o];
			}
		}
	}
	return "";
}
export function Search(searchPhrase)
{
	searchPhrase = searchPhrase.toLowerCase();	
	let results = [];


	if(searchIndex == null || !searchIndex.data)
		return [];
	let foundIds = [];
	for(let id in searchIndex.data)
	{
		
		for(let o in searchIndex.data[id])
		{
			if(searchIndex.data[id][o].toLowerCase().indexOf(searchPhrase)!==-1)
			{
				foundIds.push(id);
				break;
			}
		}
	}
	

	for(let i in searchIndex.structure)
	{	
		let scope = null;
		if(i === "companies")
			scope = CONST.clients;
		else if(i === "companies_projects")
			scope = CONST.project;
		else if(i === "companies_sales")
			scope = CONST.sales;
		else if(i === "companies_reports")
			scope = CONST.report;
		
		
		if(scope != null)
		{
			let scoperesults = [];
			for(let j in searchIndex.structure[i])
			{
				let company = searchIndex.structure[i][j];
				
				if(foundIds.includes(company.id))
					scoperesults.push({cid:company.id, text:getObjectString(company.id, searchPhrase)});
				for(let pi in company.projects)
				{
					
					if(foundIds.includes(company.projects[pi].id))
					{
						scoperesults.push({cid:company.id, pid:company.projects[pi].id, text:getObjectString(company.projects[pi].id, searchPhrase)});
					}
				}				
			}
			results.push({scope:scope, phrase:searchPhrase, results:scoperesults});
		}
	}
	return results;
}