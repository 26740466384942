import React, {Component} from 'react';
import {Get3dObjectFromVersionModel} from './../../_models/VersionsModel';
import {Init3DView} from '3d/threeEntryPoint2';

import './ThreeContainer.css';



export default class ThreeContainer extends Component {
	constructor(props)
	{
		super(props);
		this.ref = React.createRef();
	}

	componentDidMount() {	
		this.root = document.getElementById("root");

		this.singletonDiv = document.getElementById("threejs");
		if(this.singletonDiv.parentNode == this.root)
		{
			this.singletonDiv.parentNode.removeChild(this.singletonDiv);
			this.ref.current.appendChild(this.singletonDiv);
		}

		Init3DView(this.singletonDiv, Get3dObjectFromVersionModel(this.props.model));	
	}

	componentWillUnmount()
	{
		if(this.singletonDiv.parentNode != null)
		{
			this.singletonDiv.parentNode.removeChild(this.singletonDiv);
			this.root.appendChild(this.singletonDiv);
		}
	}

	render() {
		if(this.ref.current!= null && this.singletonDiv !=null && this.props.model!=null)
			Init3DView(this.singletonDiv,Get3dObjectFromVersionModel(this.props.model));
		return (<div className="ThreeContainer" ref={this.ref}></div>);
	}
}