import {fetchVersions_get, fetchVersion_set, fetchSelectedVersionId_set} from './../actions/versions';
import {_doRequest} from './_api.js';

export function getAllByProjectId( data) {	
	
	return _doRequest(
		"version_get", 
		fetchVersions_get, 
		"000271",
		data
	);
}

export function createVersion( data) {	
	return _doRequest(
		"version_create", 
		fetchVersion_set, 
		"000272",
		data
	);
}

export function updateVersion( data) {	
	return _doRequest(
		"version_update", 
		fetchVersion_set, 
		"000273",
		data
	);
}

export function deleteVersion( data) {	
	return _doRequest(
		"version_delete", 
		fetchVersion_set, 
		"000274",
		data
	);
}


export function setSelectedVersionId(data) {	
	return dispatch => {
		dispatch(fetchSelectedVersionId_set(data));					
	};
}

