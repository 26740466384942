import {fetchReports_get} from './../actions/reports';
import {_doRequest} from './_api.js';
import {_doAppRequest} from './_app.js';

export function getAllReportsByCompanyId( data) {	
	return _doRequest(
		"reports_get", 
		fetchReports_get, 
		"000341",
		data
	);
}