import React, {Component} from 'react';
import i18n from "i18n";

import {findProjectById} from '_models/ProjectsModel';

import ThreeContainer from 'ui/ThreeContainer';
import "./PreviewCard.css";

class PreviewCard extends Component
{
	constructor(props) {
		super(props);
		
		this.state = {
			actualId:0,
			projects:[]
		}
		this.projectSelected = this.projectSelected.bind(this);
		
	}
	projectSelected(e)
	{
		this.setState(
		{
			actualId : e.currentTarget.dataset.id
		}
		)
	}

	render()
	{
		
		if(this.props.versionid==null || this.props.version == null || findProjectById(this.props.version.project_id)==null)
		{
			return(
				<div className={"PreviewCard Card round tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>		
					<div className="loading">{this.props.version!=null?i18n.t("loading"):""}</div>
				</div>
			);
		}
		
		let project = findProjectById(this.props.version.project_id);
		
		return(
			<div className={"PreviewCard Card round tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<ThreeContainer model={this.props.version}></ThreeContainer>				
				<a className={"Button round black"+(project==null || project.hash==null?" hidden":"")} href={project!=null?"/#/projectview/"+project.hash:""} target="_blank"><span className="Button-content">{i18n.t("sales_project_btn")}</span></a>					
			</div>
		);
	}
}
export default PreviewCard;


