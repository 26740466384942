import React, {Component} from 'react';
import i18n from "i18n";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {getAlertMessage} from 'reducers/app';
import {hideAlertMessage} from 'api/appMessage';
import {deleteProject} from 'api/projects';
import {deleteVersion} from 'api/versions';
import {del as deleteCompany} from 'api/company';

import "./DeleteAlert.css";
import Button from 'ui/Button';
import { CONST } from '../../Constants';

class DeleteAlert extends Component
{
	constructor(props) {
		super(props);
		this.cancelClicked = this.cancelClicked.bind(this);
		this.deleteClicked = this.deleteClicked.bind(this);
	}
	
	deleteClicked(e)
	{
		
		if(this.props.alert.message.scope === CONST.project)
			this.props.deleteProject(this.props.alert.message.data);
		else if(this.props.alert.message.scope === CONST.version)
			this.props.deleteVersion(this.props.alert.message.data);
		else if(this.props.alert.message.scope === CONST.clients)
			this.props.deleteCompany(this.props.alert.message.data);
		else	
			console.error("Unknown Delete Scope", this.props.alert.message.scope);
		this.props.hideAlertMessage();
	}
	cancelClicked(e) {
		this.props.hideAlertMessage();
  }

	render()
	{

		var msg = null;
		
		if(this.props.alert != null)
		{
			
			if(this.props.alert.message != null)
			{
				msg = {
					text: this.props.alert.message.text
				}
			}
		}
				
		if(msg == null)
			return(<div className="DeleteAlert"><div className="content Card"></div></div>);
		else
			return(
			<div className={"DeleteAlert visible "+msg.type}>
				<div className="content Card noselect">					
						<h2 className="">{i18n.t("deletealert_title")}</h2>

						<div className="right">
						<span className="txt">{msg.text}</span>
						<Button color="green" onClick={this.cancelClicked}>{i18n.t("deletealert_cancel_btn")}</Button>
						<Button color="red"  onClick={this.deleteClicked}>{i18n.t("deletealert_delete_btn")}</Button>
						<span className="hint">{i18n.t("deletealert_hint")}</span>					
						</div>
						<div className="Icon alert"></div>
				</div>
			</div>
		);		
	}
}

const mapStateToProps = state => ({
	alert: getAlertMessage(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({hideAlertMessage, deleteProject, deleteVersion, deleteCompany}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(DeleteAlert);