import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {getCompanies} from './../reducers/login';
import ConfigCard from '../ui/ConfigCard';


class ConfigView extends Component
{
	constructor(props) {
		super(props);
		this.state={view_type:"visualization"}
		this.commentBtnClicked = this.commentBtnClicked.bind(this);
		this.visualizationBtnClicked = this.visualizationBtnClicked.bind(this);
	}
	commentBtnClicked(e)
	{
		this.setState({view_type:"comment"});
	}
	visualizationBtnClicked(e)
	{
		this.setState({view_type:"visualization"});
	}

	render()
	{
		return(
			<>
				<ConfigCard readonly={true} gridpositions="_r3-4 _c1-4"></ConfigCard>			
			</>
		);
		
	}
}

const mapStateToProps = state => ({
	companies: getCompanies(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(ConfigView);

