import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CustomScroll from 'react-custom-scroll';

import {GetPermissionClassName as Permission, HasOnOfPermissions, HasCompanyPermission, SetCompanyPermissions, Ids as PermissionId} from './../../_models/Permissions'; 
import {getCompany, getCompanyError, getCompanyPending, getSelectedCompany, getSelectedCompanyId} from './../../reducers/company';
import {getPicker} from './../../reducers/app';

import {get, update} from './../../api/company';
import {APP_MESSAGE_TYPE_INFO, APP_MESSAGE_TYPE_TODO, APP_MESSAGE_TYPE_ERROR} from './../../actions/app';
import {PICKER_MESSAGE_TYPE_PARENT, PICKER_MESSAGE_TYPE_CHILD, PICKER_MESSAGE_TYPE_PERMISSION_ROLE} from './../../actions/app';
import {addAppMessage, showPicker, hidePicker} from './../../api/appMessage';

import TextInputModel from './../../ui/TextInputModel';
import FileBase64InputModel from './../../ui/FileBase64InputModel';

import LabeledButton from './../../ui/LabeledButton';
import CompanyLevelDownContacts from './CompanyLevelDownContacts';

import CompanyMembersBlock from './CompanyMembersBlock';

import "./CompanyDetailsCard.css";
import Button from './../Button.js';
import i18n from "i18n";
import { findById} from './../../_models/CompanyModel';
import { ReactCustomScroll } from 'react-custom-scroll/dist/reactCustomScroll';
import { Redirect } from 'react-router-dom';
import { CONST } from '../../Constants';

class CompanyDetailsCard extends Component
{
	constructor(props) {
		super(props);
		
		this.saveClicked = this.saveClicked.bind(this);
		this.rightConfigButtonClicked = this.rightConfigButtonClicked.bind(this);
		this.selectParentClicked = this.selectParentClicked.bind(this);
	//	this.selectChildClicked = this.selectChildClicked.bind(this);

		this.permissionsList = [{id:2, name:"Customer Service"}, {id:3, name:"Operator"}, {id:4, name:"Sales"}, {id:1, name: "Supervisor"}];
	}

	selectParentClicked(e)
	{
		this.props.showPicker(PICKER_MESSAGE_TYPE_PARENT);	
	}
	rightConfigButtonClicked(e)
	{
		this.props.showPicker(PICKER_MESSAGE_TYPE_PERMISSION_ROLE, -1, this.permissionsList);
	}
	saveClicked(e)
	{
		this.props.addAppMessage(""/*"000203"*/, "save company " +this.company.name, APP_MESSAGE_TYPE_INFO);				
		this.company.user = this.props.selectedCompany.mainUser;	
		
		if(this.company.parent_company_id === "")
			this.company.parent_company_id = "0";
		this.props.update(this.company);
	}

	componentDidUpdate()
	{
		if(this.props.companyid!=null && this.loaded<1 && this.areAvailable(CONST.clients))
			this.props.get({"id":this.props.companyid});
		this.loaded++;
	}

	areAvailable(scope)
	{
		if(this.props.selectedCompany==null)
			return false;
		if(this.props.selectedCompany.rolegroup_id == null || this.props.selectedCompany.rolegroup_id===0)
			return false;
		if(this.props.selectedCompany.rolegroup_id === 1)
			return true;
		if((scope === "clients" || scope === "agency") && this.props.selectedCompany.rolegroup_id === 2)
			return true;
		if(scope === "agency" && this.props.selectedCompany.rolegroup_id === 3)
			return true;
		if(scope === "sales" && this.props.selectedCompany.rolegroup_id === 4 )
			return true;

		return false;
	}


	render()
	{
		let level = 0;
		if(this.loaded==null)
			this.laoded=0;
	
		if(this.props.error && this.lastError != this.props.error)
		{
			this.lastError = this.props.error;
			if(this.props.error.info)
			{
				this.props.addAppMessage(this.props.error.info.id, this.props.error.info.msg+"\r\n"+this.props.error.data, APP_MESSAGE_TYPE_ERROR);	
				if(this.props.error.info.id === "000201")
					return(<Redirect to={"/"+CONST.clients}></Redirect>)	
			}
			else 
				return(<Redirect to={"/"+CONST.clients}></Redirect>)	
		}
		
		if(this.props.companyid != null)
		{
		//	console.log(this.props.pending, this.props.selectedCompany)
			if(!this.props.pending && this.props.result.companies.length>0 && this.props.result.companies[0].id===this.props.companyid)
			{			
				//getSelectedCompany
				this.company = this.props.selectedCompany;
				if(this.props.selectedCompany != null)
					this.props.selectedCompany.mainUser = {name:"", email:"", mobile:"", telefon:"", fax:""}; 

				var s = findById(this.company.id, this.props.companies);
				if(s!=null)
					level = s.depth;				
			}
			else
			{		
				//console.log("1", this.props.companyid);
				if(!this.props.pending )
				{				
				//	console.log("2", this.props.companyid);
					if(this.areAvailable(CONST.clients))
					{
					//	console.log("3", this.props.companyid);
					//	this.props.get({"id":this.props.companyid});
					}
				}
			}		
		}

		if(this.props.selectedCompany==null || this.props.companyid==null)
		{
			
			return(
			<div className="CompanyDetailsCard Card round tile _r3-4 _c2-4">
				<div className="CompanyDetailsCard-title Card-title"></div>
				<div className="CompanyDetailsCard-content Card-content"></div>
			</div>
			);
		}
		else
		{		
		//	SetCompanyPermissions(this.props.selectedCompany.userPermissions);
			
		
			if(this.props.pickerState!=null && (this.props.pickerState.selectedCompany!=null || this.props.pickerState.selectedId>=0))
			{	
				if(this.props.pickerState.type === PICKER_MESSAGE_TYPE_PARENT)
				{	
					
					let selectedCompanyId = this.props.pickerState.selectedCompany!=="0"?this.props.pickerState.selectedCompany:"";	
					if(this.props.selectedCompany.id !== selectedCompanyId)		
						this.props.selectedCompany.parent_company_id = this.props.pickerState.selectedCompany!=="0"?this.props.pickerState.selectedCompany:"";
					else
			
					this.props.hidePicker();
				}
				else if(this.props.pickerState.type === PICKER_MESSAGE_TYPE_CHILD)
				{
					let child = findById(this.props.pickerState.selectedCompany);
					this.props.children.push(child.value);
					this.props.hidePicker();				
				}
				else if(this.props.pickerState.type === PICKER_MESSAGE_TYPE_PERMISSION_ROLE)
				{
				//	console.log(this.props.pickerState)
					this.props.selectedCompany.rolegroup_id =  this.props.pickerState.selectedId;
					
					this.props.hidePicker();				
				}
			}
		var editable = true;
		editable = HasCompanyPermission(202) || HasCompanyPermission(203);
		

		let permissionName = "NOT SET";
		for(let i=0; i< this.permissionsList.length;i++)
		{
			if( this.permissionsList[i].id == this.props.selectedCompany.rolegroup_id)
				permissionName =  this.permissionsList[i].name;
		}
		let parentCompanyModel = {name:this.props.parentName};
		
		if(this.props.selectedCompany.contactDetails==null)
			this.props.selectedCompany.contactDetails = {name:"", email:"",mobile:"", phone:"", fax:""}

		if(this.props.selectedCompany.name == null)//apears while opene lient after deleting a client
			return("");
	
		return(
			<div className="CompanyDetailsCard Card round tile _r3-4 _c2-4">
				<div className="CompanyDetailsCard-title Card-title">
					<span>{this.props.selectedCompany.name}</span>
					
				</div>
				<div className="CompanyDetailsCard-content Card-content">
			
					<div className="left">
						{/*<CustomScroll heightRelativeToParent="calc(100% - 50px)">*/}
						<div className="block">
						<TextInputModel _key="name" placeholder={i18n.t("company_details_name_placeholder")} label={i18n.t("company_details_name_label")}   model={this.props.selectedCompany} editable={editable}></TextInputModel>
						<TextInputModel _key="department" placeholder={i18n.t("company_details_department_placeholder")} label={i18n.t("company_details_department_label")}  model={this.props.selectedCompany} editable={editable}></TextInputModel>
						</div>
						<div className="user block">
							<TextInputModel _key="name" placeholder={i18n.t("company_details_user_name_placeholder")} label={i18n.t("company_details_user_name_label")}  model={this.props.selectedCompany.contactDetails} editable={editable}></TextInputModel>
							<TextInputModel _key="email" placeholder={i18n.t("company_details_user_email_placeholder")} label={i18n.t("company_details_user_email_label")}  model={this.props.selectedCompany.contactDetails} editable={editable}></TextInputModel>
							<TextInputModel _key="mobile" placeholder={i18n.t("company_details_user_mobile_placeholder")} label={i18n.t("company_details_user_mobile_label")}  model={this.props.selectedCompany.contactDetails} editable={editable}></TextInputModel>
							<TextInputModel _key="phone" placeholder={i18n.t("company_details_user_phone_placeholder")} label={i18n.t("company_details_user_phone_label")}  model={this.props.selectedCompany.contactDetails} editable={editable}></TextInputModel>
							<TextInputModel _key="fax" placeholder={i18n.t("company_details_user_fax_placeholder")} label={i18n.t("company_details_user_fax_label")}  model={this.props.selectedCompany.contactDetails} editable={editable}></TextInputModel>
						</div> 
						<div className="block">
						<TextInputModel _key="street" placeholder={i18n.t("company_details_street_placeholder")} label={i18n.t("company_details_street_label")}  model={this.props.selectedCompany} editable={this.props.selectedCompany}></TextInputModel>
						<TextInputModel _key="city" placeholder={i18n.t("company_details_city_placeholder")} label={i18n.t("company_details_city_label")}  model={this.props.selectedCompany} editable={editable}></TextInputModel>
						<TextInputModel _key="country" placeholder={i18n.t("company_details_country_placeholder")} label={i18n.t("company_details_country_label")}  model={this.props.selectedCompany} editable={editable}></TextInputModel>
						<TextInputModel _key="email" placeholder={i18n.t("company_details_email_placeholder")} label={i18n.t("company_details_email_label")}  model={this.props.selectedCompany} editable={editable}></TextInputModel>
						<TextInputModel _key="vat_nr" placeholder={i18n.t("company_details_vatnr_placeholder")} label={i18n.t("company_details_vatnr_label")}  model={this.props.selectedCompany} editable={editable}></TextInputModel>
						</div>
						<div className="bank block">
						<TextInputModel _key="bank" placeholder={i18n.t("company_details_bank_placeholder")} label={i18n.t("company_details_bank_label")}  model={this.props.selectedCompany} editable={editable}></TextInputModel>
						<TextInputModel _key="iban" placeholder={i18n.t("company_details_iban_placeholder")} label={i18n.t("company_details_iban_label")}  model={this.props.selectedCompany} editable={editable}></TextInputModel>
						<TextInputModel _key="bic" placeholder={i18n.t("company_details_bic_placeholder")} label={i18n.t("company_details_bic_label")}  model={this.props.selectedCompany} editable={editable}></TextInputModel>
						</div>
						<div className="block">
						<TextInputModel _key="id" placeholder={i18n.t("company_details_adam_id_placeholder")} label={i18n.t("company_details_adam_id_label")}   model={this.props.selectedCompany} editable={false}></TextInputModel>
						<TextInputModel _key="system_id" placeholder={i18n.t("company_details_system_id_placeholder")} label={i18n.t("company_details_system_id_label")}  model={this.props.selectedCompany} editable={editable}></TextInputModel>
						</div>
						<div className="block">
						<FileBase64InputModel _key="logo" placeholder={i18n.t("company_details_logo_placeholder")} label={i18n.t("company_details_logo_label")}  model={this.props.selectedCompany} editable={editable} type="upload" className="logoUpload"></FileBase64InputModel>
						</div>
						{/*</CustomScroll>*/}
						<Button className={"green"+(!editable?" hidden":"")} onClick={this.saveClicked}>{i18n.t("company_details_save_btn")}</Button>				
					</div>
					<div className="right">
					<div className={"block levelup" + (!HasCompanyPermission(PermissionId._206)?" hidden":"")}>
					<LabeledButton label={i18n.t("company_details_levelup_label")} onClick={this.selectParentClicked} editable={editable}>{i18n.t("company_details_levelup_btn")}</LabeledButton>
					<TextInputModel _key="name" placeholder={i18n.t("company_details_levelUp_notset")} label="" model={parentCompanyModel} editable={false}></TextInputModel>
					</div>
					<div className={"block leveldown" + (!HasCompanyPermission(PermissionId._207)?" hidden":"")}>
						<LabeledButton className={level>=3?"hidden":""}label={i18n.t("company_details_leveldown_label")} left="plus" editable={editable}  onClick={this.props.newClicked} /*onClick={this.selectChildClicked}*/>{i18n.t("company_details_leveldown_btn")}</LabeledButton>
						<CompanyLevelDownContacts companies={this.props.children} editable={editable} onDelete={this.props.deleteClicked}></CompanyLevelDownContacts>
					</div>
					<CompanyMembersBlock companyId={this.props.companyid} clientsAvailable={this.areAvailable("clients")} agencyAvailable={this.areAvailable("agency")} salesAvailable={this.areAvailable("sales")} editable={editable} ></CompanyMembersBlock>
					<LabeledButton className={(!HasCompanyPermission(PermissionId._208)?" hidden":"")} label={i18n.t("company_details_right_config_label")} bgColor="gray" onClick={this.rightConfigButtonClicked}>{permissionName}</LabeledButton>
					</div>
				
				</div>
			</div>
		);
		}
	}
}

const mapStateToProps = state => ({
	selectedCompany:getSelectedCompany(state),
	selectedCompanyId:getSelectedCompanyId(state),
	result: getCompany(state),	
	pending: getCompanyPending(state),
	error: getCompanyError(state),
	pickerState: getPicker(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({get:get, update:update, addAppMessage: addAppMessage, showPicker, hidePicker}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CompanyDetailsCard);
