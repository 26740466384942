let comments_map = {};
let comments = [];



export function addComment(comment)
{
	comments_map[comment.id] = comment;
	comments.push(comment);
	return comments;
}


export function parseComments(rawCommentsList)
{	
	comments = [];
	comments_map = {};
	/* eslint-disable */
	for(let rawP in rawCommentsList)
	{
		 let p = parseComment(rawCommentsList[rawP]);
		 if(p!=null)
		 {
			 comments_map[p.id] = p;
			 comments.push(comments_map[p.id]);
		 }
	}
	/* eslint-enable */
	return comments;
}

export function findCommentById(id)
{
	if(comments_map[id]!=null)
		return comments_map[id];
	/* eslint-disable */
	for(let com in comments)
	{
		if(comments_map[com.id]==null)
			comments_map[com.id] = com;
		
	}
	/* eslint-enable */
	return comments_map[id];
}

export function parseComment(rawComment)
{
	if(rawComment== null)
		return null;
		
	let comment = {
	"id": "",
/*	"parent_comment_id": "",*/
	"version_id": "",
	"user_id": "",
	"msg": "",
	"editable":false,
/*	"marker_name": "",
	"marker_position": null,
	"marker_rotation": null,*/
	"username":"unknown",
	"updated_at": 0
	};

	comment.id  = rawComment.id;
	comment.version_id  = rawComment.version_id;
	comment.user_id  = rawComment.user_id;
	comment.editable  = rawComment.editable;
	comment.msg  = rawComment.msg;
	comment.username  = rawComment.username;
	comment.updated_at  = rawComment.updated_at;
	
	return comment;
}

export function getComments()
{
	return comments;
}

export function deleteComment(rawComment)
{
	if(comments_map[rawComment.id]!=null)
	{
		let foundIndex = -1;
		for(let i=0;i<comments.length;i++)
		{
			if(comments[i].id === rawComment.id)
				foundIndex = i;
		}
		if(foundIndex>=0)
			comments.splice(foundIndex, 1);
		delete comments_map[rawComment.id];
	}

	return comments;
}