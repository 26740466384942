import React, {Component} from 'react';
import i18n from "../i18n";
import LoginView from "./LoginView"
import "./ImprintView.css";
import CustomScroll from 'react-custom-scroll';
import {IsMobile} from 'Utils';

class ImprintView extends Component
{
	render()
	{
		let mobile = IsMobile();
		let leftClassC = "_c1-3";
		if(mobile)
			leftClassC = "_c1-4";
		return(
			<>
			<div className={"ImprintView Card round tile _r3-4 "+leftClassC}>
				<div className="ImprintView-title Card-title">
					<span>{i18n.t("imprint_title")}</span>				
				</div>
				<div className="ImprintView-content Card-content display-linebreak">
					<CustomScroll heightRelativeToParent="100%">
						<div className="txt_col_2" dangerouslySetInnerHTML={{__html:i18n.t("imprint_body")}}></div>					
					</CustomScroll>
				</div>
			</div>
			<LoginView className={mobile?"hidden":""} row="_r3-4" column="_c4"></LoginView>
			</>
		);
	}
}
export default ImprintView;