import React, { Component } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {addAppMessage} from './api/appMessage';

import './App.css';
import './fonts/fonts.css';
import MainNavigation from './ui/MainNavigation/MainNavigation';
import FootNavigation from './ui/FootNavigation';
import ContactView from './views/ContactView';
import PrivacyView from './views/PrivacyView';
import ImprintView from './views/ImprintView';
import CompanyView from './views/CompanyView';

import AgencyView from './views/AgencyView';
import SalesView from './views/SalesView';
import ReportView from './views/ReportView';

import StartView from './views/StartView';
import ConfigView from './views/ConfigView';
import HeaderSearch from './ui/HeaderSearch';
import LoadingSpinner from './ui/LoadingSpinner';
import MessageAlert from './ui/MessageAlert';
import DeleteAlert from './ui/DeleteAlert';


import {IsNavigationVisibleInRoute, IsProjectViewRoute, GetProjectViewHash} from './Utils';
import {_getToken} from './api/_api';

import {auth} from './api/login';
import {getAuth} from './reducers/login';
import {getSelectedCompanyId} from './reducers/company';

import CompanyPicker from './ui/Company/CompanyPicker';
import ProjectView from './views/ProjectView';
import Picker from './ui/Company/Picker';


class App extends Component {
	

	constructor(props) {
		super(props);
	
		//window.
		document.addEventListener('contextmenu', function(event) {
			event.preventDefault();
	 }, true); 
	}


	updateDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });    
  }

	componentDidMount()
	{
		const {auth} = this.props;
		
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions.bind(this));
		
		
		if(_getToken() != null && _getToken().length>10)
		{			
			auth({pendingLocation:this.props.location.pathname});			
		}

	}

	
	componentWillUnmount() {
		
    window.removeEventListener("resize", this.updateDimensions.bind(this));
	}
	
  render() {
		let searchvisible = IsNavigationVisibleInRoute(this.props.location.pathname);
		let device = "desktop";
		if(this.state)
		{
			if(this.state.width<this.state.height && this.state.width/this.state.height<(.75))
				device = "mobile";
		}
		if(IsProjectViewRoute(this.props.location.pathname))
		{
			if(GetProjectViewHash(this.props.location.pathname)!="")
			{
				localStorage.setItem("lastProjectViewHash",GetProjectViewHash(this.props.location.pathname));				
			}
			else
			{
				let hash = localStorage.getItem("lastProjectViewHash");
				if(hash.length>0)
					return(<Redirect to={"/projectview/"+hash}></Redirect>)
			}
		}
		
    return (
			
			<>
      <div className={"App"+(IsProjectViewRoute(this.props.location.pathname)?" projectview":"")+" "+device}>
			 <div className={"App-body"+(!searchvisible?" no_nav":"")}>
				 <Route path="/" component={HeaderSearch}  />
				 <Route path="/" render={(props) => <MainNavigation visible={searchvisible} selectedCompanyId={this.props.selectedCompanyId} />} />
				 
					<Switch>
						<Route exact path='/projectview/:projectid' component={ProjectView}></Route>
						<Route exact path='/' component={StartView}></Route>
						<Route path='/login' component={StartView}></Route>
						
						<Route path='/board' component={StartView}></Route>
						
						<Route exact path='/clients' component={CompanyView} ></Route>
						<Route exact path="/clients/add" component={CompanyView}></Route>
						<Route exact path="/clients/search/:search" component={CompanyView}></Route>
						<Route exact path="/clients/:companyid" component={CompanyView}></Route>
						
						<Route exact path='/agency' component={AgencyView}></Route>
						<Route exact path="/agency/search/:search" component={AgencyView}></Route>
						<Route exact path="/agency/:companyid" component={AgencyView}></Route>
						<Route exact path="/agency/:companyid/:projectid/:versionid" component={AgencyView}></Route>
						<Route exact path="/agency/:companyid/:projectid" component={AgencyView}></Route>
						
						<Route exact path='/sales' component={SalesView}></Route>
						<Route exact path="/sales/search/:search" component={SalesView}></Route>
						<Route exact path="/sales/:companyid" component={SalesView}></Route>
						<Route exact path="/sales/:companyid/:projectid" component={SalesView}></Route>
						<Route exact path="/sales/:companyid/:projectid/:versionid" component={SalesView}></Route>

						<Route exact path='/report' component={ReportView}></Route>
						<Route exact path="/report/search/:search" component={ReportView}></Route>
						<Route exact path='/report/:companyid' component={ReportView}></Route>
						
						<Route path='/config' component={ConfigView}></Route>

						<Route path='/contact' component={ContactView}></Route>
						<Route path='/privacy' component={PrivacyView}></Route>
						<Route path='/imprint' component={ImprintView}></Route>
					</Switch>
					<FootNavigation></FootNavigation>
				</div>
      </div>
		<CompanyPicker></CompanyPicker>
		<Picker></Picker>
		<MessageAlert></MessageAlert>
		<DeleteAlert></DeleteAlert>
		<LoadingSpinner></LoadingSpinner>
		</>
    );
  }
}


const mapStateToProps = state => ({
	result: getAuth(state),
	selectedCompanyId:getSelectedCompanyId(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({auth: auth, addAppMessage: addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(App);