export const initialState = {

	apiReducer:	{
		api_jobs:0
	},
	login:{
		pendingLocation:null,
		pending: false,
		token: "",
		error: null,
		companies:[]
	},
	company:
	{
		pending: false,
		error: null,
		companies:[],
		createdCompany:null
	},
	companyMembers:
	{
		members:[],
		memberSelected:null
	},
	project:
	{
		pending: false,
		error: null,
		projects:[],
		createdProjectId:"0",
		salesMembers:
		{
			error: null,
			pending: false,
			list:[]
		}
	},
	reports:
	{
		pending: false,
		error: null,
		list:[]
	},
	arproject:
	{
		pending: false,
		error: null,
		project:null
	},
	version:
	{
		pending: false,
		error: null,
		updatedVersionId:-1,
		list:[]
	},
	comment:
	{
		pending: false,
		error: null,
		list:[]
	},
	
	config:
	{

	},
	app:
	{
		messages:[]
	}
}