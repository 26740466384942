import React, {Component} from 'react';

import Icon from "ui/Icon";
import "./ActionBar.css";

class ActionBar extends Component
{
	render()
	{
		if(this.props.editClicked==null && this.props.deleteClicked==null && this.props.newClicked==null)
			return("");
		return(
			<div className={this.props.className +" ActionBar"}>
				<Icon type="edit" className={(this.props.editClicked!=null?"":"hidden")} onClick={this.props.editClicked}></Icon>
				<Icon type="delete" className={(this.props.deleteClicked!=null?"":"hidden")} onClick={this.props.deleteClicked} ></Icon>
				<Icon type="new" className={(this.props.newClicked!=null?"":"hidden")} onClick={this.props.newClicked} ></Icon>
			</div>
		);
	}
}
export default ActionBar;