import React, {Component} from 'react';
import CustomScroll from 'react-custom-scroll';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {addAppMessage} from './../api/appMessage';
import {APP_MESSAGE_TYPE_TODO} from './../actions/app';
import {gotConfig} from './../reducers/config';
import {getConfig, setConfig} from './../api/config';

import "./ConfigCard.css";
import Button from './Button.js';
import i18n from "../i18n";
import TextInput from './TextInput';
import RightsMatrix from './RightsMatrix';

class ConfigCard extends Component
{
	constructor(props) {
		super(props);
		
	
		this.saveClicked = this.saveClicked.bind(this);
	}

	componentDidMount()
	{
		if(this.loaded<1)
		{			
			this.props.getConfig({});
		}
		this.loaded++;
	}

	saveClicked(e)
	{
		this.props.addAppMessage("0", "api not connected yet", APP_MESSAGE_TYPE_TODO);
		console.log(this.props.config.config.rolePermissions);		
		this.props.setConfig({"rolePermissions":this.props.config.config.rolePermissions});	
	}
	
	render()
	{
		
		if(	this.loaded==null)
			this.loaded = 0;

		let editable = true;
		var settings = {};
		settings.mail = {};
		settings.mail.server =  {val:"smtp.mailserver.com"}; 
		settings.mail.user = {val:"mailuser"}; 
		settings.mail.password = {val:"mailpass"}; 
		settings.mail.port = {val:"587"}; 

		settings.token_lifetime =  {"val":60};

		if(this.props.config.pending)
		{
			return(
				<div className={"ConfigCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<div className="ConfigCard-title Card-title">
					<span>{i18n.t("config_card_title")}</span>					
				</div>
				<div className="ConfigCard-content Card-content">
					</div>
				</div>
			);
		}
	//	console.log(this.props.config.config);
		return(
			<div className={"ConfigCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<div className="ConfigCard-title Card-title">
					<span>{i18n.t("config_card_title")}</span>					
				</div>
				<div className="ConfigCard-content Card-content">
				<CustomScroll heightRelativeToParent="100%">
				<div className="left">
					<div className="block">
						<TextInput _key="server" placeholder={i18n.t("config_mail_server_placeholder")} label={i18n.t("config_mail_server_label")}  model={settings.mail.server} editable={editable}></TextInput>
						<TextInput _key="user" placeholder={i18n.t("config_mail_user_placeholder")} label={i18n.t("config_mail_user_label")}  model={settings.mail.user} editable={editable}></TextInput>
						<TextInput _key="pass" type="password" placeholder={i18n.t("config_mail_pass_placeholder")} label={i18n.t("config_mail_pass_label")}  model={settings.mail.password} editable={editable}></TextInput>
						<TextInput _key="port" placeholder={i18n.t("config_mail_port_placeholder")} label={i18n.t("config_mail_port_label")}  model={settings.mail.port} editable={editable}></TextInput>
					</div> 
					<div className="block">
						<TextInput _key="token" placeholder={i18n.t("config_token_lifetime_placeholder")} label={i18n.t("config_token_lifetime_label")}  model={settings.token_lifetime} editable={editable}></TextInput>
					</div> 
					<Button className="save_btn" onClick={this.saveClicked}>{i18n.t("config_save_btn")}</Button>
				</div>
				<div className="right hidden">
					<div className="block">
						<RightsMatrix data={this.props.config.config}></RightsMatrix>
					</div>
				
				
					</div>
					
					</CustomScroll>
				</div>
			</div>
		);
	}
}


const mapStateToProps = state => ({
	config: gotConfig(state)
})

//const mapDispatchToProps = dispatch => bindActionCreators({passResetRequest: passResetRequest, addAppMessage: addAppMessage}, dispatch);
const mapDispatchToProps = dispatch => bindActionCreators({addAppMessage: addAppMessage, setConfig:setConfig, getConfig:getConfig, }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(ConfigCard);
