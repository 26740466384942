import React, {Component} from 'react';
import {NavLink, Link} from 'react-router-dom';
import CustomScroll from 'react-custom-scroll';
import i18n from "i18n";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import {findById, getCompaniesGroupedByDepth, GetCompaniesByScope} from './../../_models/CompanyModel';
import {GetPermissionClassName as Permission, HasPermission, HasCompanyPermission, Ids as PermissionId} from './../../_models/Permissions'; 
import Icon from './../Icon.js';
import "./CompanyCard.css";
import Button from './../Button.js';
import {setSelectedCompanyId} from './../../api/company';
import {getLastUpdate} from './../../reducers/company';

class CompanyCard extends Component
{
	constructor(props) {
		super(props);

		this.state = {
      pos: {x:0,y:0},
      dragging: false,
      rel: null
		};
		this.handleLevelClicked = this.handleLevelClicked.bind(this);
		this.thumb_onMouseDown = this.thumb_onMouseDown.bind(this);
		this.thumb_onMouseUp = this.thumb_onMouseUp.bind(this);
		this.thumb_onMouseMove = this.thumb_onMouseMove.bind(this);	
		this.handleBCLeftAction = this.handleBCLeftAction.bind(this);	
		this.handleBCRightAction = this.handleBCRightAction.bind(this);	
	}

	componentDidMount()
	{
		this.mounted = true;
		let el = document.getElementsByClassName("scrollthumb")[0];
		if(el == null)
			return;
		let rect = el.getBoundingClientRect();
		let parent_rect = el.parentElement.getBoundingClientRect();
    var pos = rect;
    this.setState({
			...this.state, 
			dragging: false,
      rel: {
        x: 0- pos.left,
				y: 0 - pos.top,
				w: parent_rect.width,
				max_x: parent_rect.width-pos.width
			}
		});
		this.recalculateBreadCrumb();
	}

	componentWillUnmount()
	{
		this.mounted = false;
		document.removeEventListener('mousemove', this.thumb_onMouseMove);
		document.removeEventListener('mouseup', this.thumb_onMouseUp);
	}
	componentDidUpdate()
	{
		this.recalculateBreadCrumb();
		this.showlevel(this.lastLevel);
	}

	//#region Breadcrumb
	recalculateBreadCrumb()
	{		
		let bc = document.getElementsByClassName("bread_crumb")[0];
		let bc_rect = bc.getBoundingClientRect();
		let left = document.getElementsByClassName("gradient left")[0];
		let right = document.getElementsByClassName("gradient right")[0];

		let container = document.getElementsByClassName("bread_crumb_container")[0];
		let container_rect = container.getBoundingClientRect();

	
		if(container_rect.width>bc_rect.width)
		{
			left.style.display = 'block';
			right.style.display = 'block';
			container.style.setProperty('left', (bc_rect.width-container_rect.width)+"px");
			right.style.display = 'none';
		}
		else
		{
			container.style.setProperty('left', "0px");
			left.style.display = 'none';
			right.style.display = 'none';
		}
	}

	handleBCLeftAction()
	{
		this.moveBC(300);		
	}
	handleBCRightAction()
	{
		this.moveBC(-300);
		
	}

	moveBC(step)
	{
		let bc = document.getElementsByClassName("bread_crumb")[0];
		let bc_rect = bc.getBoundingClientRect();
		let left = document.getElementsByClassName("gradient left")[0];
		let right = document.getElementsByClassName("gradient right")[0];

		let container = document.getElementsByClassName("bread_crumb_container")[0];
		let container_rect = container.getBoundingClientRect();

		
		if(container_rect.width>bc_rect.width)
		{
			let _left = parseFloat(window.getComputedStyle(container, null).getPropertyValue("left").split("px").join(""));
			if(step>0)
			{
				if(_left+step>0)
					left.style.display = 'none';
				container.style.setProperty('left', Math.min(0,_left+step)+"px");
				right.style.display = 'block';
			}
			else 	if(step<0)
			{		
				if(_left+step<=bc_rect.width-container_rect.width)
					right.style.display = 'none';
				container.style.setProperty('left', Math.max(bc_rect.width-container_rect.width,_left+step)+"px");
				left.style.display = 'block';
			}
		}
	}
	//#endregion Breadcrumb

	handleLevelClicked(event)
	{
		event.preventDefault();
		this.showlevel(parseInt(event.target.id.split("level_")[1]));

	}

	//#region thumb drag
	thumb_onMouseDown(e) {
		this.dragged=true;
		if (e.button !== 0) 
		return;
		let rect = e.target.getBoundingClientRect();
		let parent_rect = e.target.parentElement.getBoundingClientRect();
		var pos = rect;
		if(this.mounted)
    this.setState( {
      dragging: true,
      rel: {
        x: e.pageX - pos.left,
				y: e.pageY - pos.top,
				w: parent_rect.width,
				max_x: parent_rect.width-pos.width
      }
    });
    e.stopPropagation();
		e.preventDefault();
		
		document.removeEventListener('mousemove', this.thumb_onMouseMove);
		document.removeEventListener('mouseup', this.thumb_onMouseUp);

    document.addEventListener('mousemove', this.thumb_onMouseMove);
    document.addEventListener('mouseup', this.thumb_onMouseUp);
  }
  thumb_onMouseUp(e) {
		this.dragged=true;
		this.setState({dragging: false});
		
    e.stopPropagation();
    e.preventDefault();
  }
  thumb_onMouseMove(e) {
		if (!this.state.dragging) return;
		this.dragged=true;
    this.setState({
			dragging:true,
      pos: {
        x: Math.min(this.state.rel.max_x, Math.max(0,e.pageX - this.state.rel.x)),
				y: e.pageY - this.state.rel.y
      }
    })
    e.stopPropagation();
    e.preventDefault();
	}
	//#endregion thumb drag
		
	showlevel(level)
	{
		//console.log(level);
		this.lastLevel = level;
		
		this.el = document.getElementsByClassName("CompanyCard")[0];
		if(this.el == null)
			this.el = document.getElementsByClassName("CompanyCard")[0];
		if(this.el != null)
		{
			this.el.style.setProperty('--level', level);
		
			let ls = this.el.getElementsByClassName("l");
			for(let i=0; i<ls.length;i++)
			{
				ls[i].classList.remove("active");
				if(i == level)
					ls[i].classList.add("active");
			}
		}	

		
		if(	!this.state.dragging && document.getElementsByClassName("scrollthumb").length>0 && document.getElementsByClassName("scrollthumb")[0].style!=null)
		{
			document.getElementsByClassName("scrollthumb")[0].style.setProperty("--left", (level/this.res.groups.length));
		}

	}
	
	render()
	{	
	
		let company = GetCompaniesByScope(this.props.scope);
		this.company = company;
		
		if(company.locked)
			return(
				<div className={"CompanyCard Card round tile" + (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<div className="CompanyCard-title Card-title">
					<span>{i18n.t("not_allowed")}</span>				
				</div>
				</div>
			);
		this.readonly = this.props.readonly===true;
			
		let actualCompany=null;
		var level = 0;
		if(this.props.companyid != null && this.company.FindById != null)
		{
			var s = this.company.FindById(this.props.companyid);
			if(s!=null)
			{				
				actualCompany = s.value;
				level = s.depth;
			}
		}
		
		let actualId = actualCompany!=null?actualCompany.id:"";
		let companyChanged = 	this.actualId !== actualId;
	
		if(this.actualId == actualId && level!=this.lastLevel && !this.dragged)
			companyChanged = true;
		this.dragged=false;
		this.actualId = actualId;
		
		//console.log(this.props.getLastUpdate, this.company.entries);

		this.props.setSelectedCompanyId(this.actualId)
		this.res = getCompaniesGroupedByDepth(this.actualId, 	this.company.entries);
		//console.log("updated")
		let res = this.res;
		this.availabelGroups = res.groups.length;
		
		//#region level percent
		let percent = 0;
		
		if(this.state.dragging)
		{		
			if(this.state.rel.max_x!==0)
				percent = this.state.pos.x / this.state.rel.max_x;
			this.showlevel(Math.min(res.groups.length-1, parseInt(percent*res.groups.length)));
		}
		else
		{
			
			if(companyChanged || this.lastLevel == null)
				this.showlevel(level);
			this.showlevel(this.lastLevel);
			percent = this.lastLevel/(res.groups.length-1);
		}
		//#endregion level percent

		//#region levels
		let l=[];
		for(var i=0;i<res.groups.length;i++)
			l.push(<span key={i} className={"l" +((level === i)?" active":"")}><a href="#c" onClick={this.handleLevelClicked} id={"level_"+i}>{i18n.t("roman_"+(i+1))}</a></span>);		
		//#endregion levels

		//#region create BreadCrumb
		let bc=[];
		for(let i=res.stack.length-1;i>=0;i--)
		{
			bc.push(
			<span key={i} className={"bc noselect" +((level === res.stack[i].depth)?" active":"")}>
				<Link to={"/"+this.props.scope+"/"+res.stack[i].value.id} >
					{res.stack[i].value.name}
					<span className={"limiter" + ((level === res.stack[i].depth)?" hidden":"")}> > </span>
				</Link>
			</span>);		
		}

	
		//#endregion create BreadCrumb 

		return(
			<div className={"CompanyCard Card round tile" + (this.props.gridpositions?" "+this.props.gridpositions:"")+(this.company.entries.length===0?" empty":"")}>
				<div className="CompanyCard-title Card-title">
					<span>user</span>
					{!this.readonly && HasPermission(202) && this.lastLevel<3 && (company.HasRootAccess||this.lastLevel>0)?<Button left="plus" color="black"  onClick={()=>this.props.newInLevelClicked(this.lastLevel)} className="right">{i18n.t("add_company")}</Button>:""}
				</div>
				<div className="CompanyCard-content">
					<div className="bread_crumb">
						
						<div className="bread_crumb_container">
						{bc}
						</div>
						<div className="gradient left" onClick={this.handleBCLeftAction}>
							<Icon type="left"></Icon>
						</div>
						<div className="gradient right" onClick={this.handleBCRightAction}>
						<Icon type="right"></Icon>
						</div>
					</div>
					
					<div className="level">
						<div className="scroller">
							<div className="line"></div>
							<div 
								className={"scrollthumb thumb noselect _"+res.groups.length + (!this.state.dragging?" notclicked":"")} 
								draggable="true" 
								onMouseDown={this.thumb_onMouseDown}
								style={{'--left':this.state.dragging?this.state.pos.x / this.state.rel.w:(this.lastLevel/res.groups.length), '--w':(this.state.rel!=null?this.state.rel.max_x:0)+"px"}}
							><Icon type="thumb"></Icon><Icon type="thumb"></Icon></div>
						</div>
						<span className="title">{i18n.t("company_level_title")}</span>
						<div className="level_row">
							{l}
						</div>
						<div className="line"></div>
					</div>
					
					<div className={"actionBar" + (this.readonly?" hidden":"") } >
						{/*<Icon type="edit"></Icon>*/}
						<Icon type="delete" className={(this.company.entries.length===0 || this.actualId==null || this.actualId.length<5?"hidden":"")+Permission(204)} onClick={this.props.deleteClicked}></Icon> 
						<Icon type="new" className={Permission(202)+(this.lastLevel>=3 || (!company.HasRootAccess&&this.lastLevel===0)?" hidden":"")} onClick={()=>this.props.newInLevelClicked(this.lastLevel)} ></Icon> 						
					</div>
					<div className="entries">
					<div className="container">
					{
						res.groups.map((group, index)=>(
							<div className="list_container" key={index}>
							<CustomScroll heightRelativeToParent="100%">
							<div className="list">
							{group.sort(function(a,b){return a.name.localeCompare(b.name)})?"":""}
							{group.map((company, index1) =>(								
									<div className={(this.actualId === company.id?"active":"")+" "+company.id+" noselect"} key={index1}><NavLink to={"/"+this.props.scope+"/"+company.id}>{company.name + (company.children.length===0?"":"("+company.children.length+")")}</NavLink></div>
							))}
							</div>
							</CustomScroll>
							</div>
						))
					}
			</div>
				</div>
			
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	getLastUpdate:getLastUpdate(state)
	
})

const mapDispatchToProps = dispatch => bindActionCreators({setSelectedCompanyId}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CompanyCard);
