import {
	COMPANYMEMBER_GET_SUCCESS, 
	COMPANYMEMBER_UPDATE_SUCCESS,
	 COMPANYMEMBER_UPDATE_ERROR,
	COMPANYMEMBER_DELETE_SUCCESS,
	COMPANYMEMBER_SELECTED
} from './../actions/companyMembers';
import {initialState}  from './../initialState.js';

export function companyMembersReducer(state = initialState, action) {

	switch(action.type) {			
	case COMPANYMEMBER_GET_SUCCESS:	
		return {
		...state,
		pending: false,
		info: action.info,
		members: action.payload!=null?action.payload:[],
		memberSelected:null
	}
	case COMPANYMEMBER_SELECTED:	
		return {
		...state,
		pending: false,
		info: action.info,
		memberSelected:action.payload
	}
	case COMPANYMEMBER_DELETE_SUCCESS:	
		console.log(action.payload);
		return {
		...state,
		pending: false,
		info: action.info,
		members: action.payload!=null?action.payload:[],
		memberSelected:null
	}
	case COMPANYMEMBER_UPDATE_SUCCESS:		
		return {
		...state,
		pending: false,
		info: action.info,
		/*companies: action.payload,*/
	}
	case COMPANYMEMBER_UPDATE_ERROR:
		return {
		...state,
		pending: false,
		error: action.info
	}
	
  default: 
    return state;
  }
}
//TODO
export const getMembers = state => state.companyMembers;
export const getCompanyMembers = state => state.companyMembers.members;
export const getCompanySelectedMember = state => state.companyMembers.memberSelected;

