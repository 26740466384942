
import {RESPONSE_TYPE} from './../api/_api.js';

export const CONFIG_GET_PENDING = 'CONFIG_GET_PENDING';
export const CONFIG_GET_SUCCESS = 'CONFIG_GET_SUCCESS';
export const CONFIG_GET_ERROR = 'CONFIG_GET_ERROR';

export const CONFIG_SET_PENDING = 'CONFIG_SET_PENDING';
export const CONFIG_SET_SUCCESS = 'CONFIG_SET_SUCCESS';
export const CONFIG_SET_ERROR = 'CONFIG_SET_ERROR';

export function fetchConfig_get(type, info, payload) {	
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: CONFIG_GET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: CONFIG_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: CONFIG_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}


export function fetchConfig_set(type, info, payload) {
//	console.log("set", info);	
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: CONFIG_SET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: CONFIG_SET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: CONFIG_SET_ERROR,
			info: info,
			payload: payload
		};
	}
}
