import React, {Component} from 'react';
import CustomScroll from 'react-custom-scroll';

import i18n from "i18n";

import {GetPermissionClassName as Permission, HasPermission} from '_models/Permissions'; 

import {ConvertMySqlDateForDisplay, HashStringToColor} from 'Utils';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {findCommentById, getComments} from '_models/CommentsModel'

import {getSelectedVersionId} from 'reducers/versions';
import {getSelectedCommentId, getCommentsReducer} from 'reducers/comments';

import {showAlertMessage} from 'api/appMessage';
import {getAllByVersionId, setSelectedCommentId, createComment,deleteComment,updateComment} from 'api/comments';

import "./CommentCard.css";
import Button from 'ui/Button';
import ActionBar from 'ui/ActionBar';
import { CONST } from '../../../Constants';
import {versionStatsUpdated} from 'api/projects';

class CommentCard extends Component
{
	constructor(props) {
		super(props);
		this.comment_map={};
		this.state = {
			actualId:0,
		}
		this.commentSelected = this.commentSelected.bind(this);
		this.newClicked = this.newClicked.bind(this);
		this.editClicked = this.editClicked.bind(this);
		this.deleteClicked = this.deleteClicked.bind(this);
		this.textChanged = this.textChanged.bind(this);
		this.saveClicked = this.saveClicked.bind(this);
		this.commentDoubleClicked = this.commentDoubleClicked.bind(this);

	}


	componentDidMount()
	{
		if(this.reloadTimer)
			clearInterval(this.reloadTimer);
		this.reloadTimer = setInterval(this.checkNewComments.bind(this), 10000);
		
	}

	componentWillUnmount()
	{
		if(this.reloadTimer)
			clearInterval(this.reloadTimer);
	}

	checkNewComments()
	{
	
		if(this.props.selectedVersionId!=null && (this.state.edit_id === -1 || this.state.edit_id == null))
			this.props.getComments({version_id:this.props.selectedVersionId});
	}

	newClicked(e)
	{	
		this.props.createComment({msg:"new", version_id:this.actualVersionid, parent_comment_id:0});
		
	}

	editClicked(e)
	{
		this.setState({edit_id:this.props.selectedCommentId})
	}

	saveClicked(e)
	{
		let comment = findCommentById(this.props.selectedCommentId);
		if(comment==null)
			return;
		this.setState({edit_id:-1});
	
		this.props.updateComment(comment);
		

	}
	deleteClicked(e)
	{
		this.props.deleteComment({id:this.props.selectedCommentId, version_id:this.actualVersionid});
	}

	textChanged(e)
	{
		let field = e.target;
		field.style.height = 'inherit';
		let height = field.scrollHeight - 5;
		field.style.height = height + 'px';
		
		let comment= findCommentById(this.props.selectedCommentId);

		comment.msg = e.target.value;
		this.setState({edit_id:this.props.selectedCommentId});
	}
	
	commentDoubleClicked(e)
	{
		if(e.currentTarget.dataset.id!==this.state.edit_id)
		this.setState({edit_id:-1});
		this.props.setSelectedCommentId(e.currentTarget.dataset.id);				
	}
	commentSelected(e)
	{
		if(e.currentTarget.dataset.id!==this.state.edit_id)
			this.setState({edit_id:-1});
		this.props.setSelectedCommentId(e.currentTarget.dataset.id);				
		this.setState({edit_id:this.props.selectedCommentId})
	}


	render()
	{
		
		let actualId = this.props.selectedCommentId;
		this.activeComment = null;
		if(actualId!=null && actualId!==-1 && actualId.length>5)
			this.activeComment = findCommentById(actualId);	
		
		if(this.actualVersionid !== this.props.selectedVersionId)
		{			
			this.actualVersionid = this.props.selectedVersionId;
			this.props.getComments({version_id:this.props.selectedVersionId});
		}
		if(actualId == null || this.props.comments.updated )
			actualId=-1;
		if(this.props.comments.updated)
		{
			actualId=-1;
			this.state.edit_id = -1;
			this.props.comments.updated = false;
		}
		if(this.props.comments.created!=null)
		{
			actualId=this.props.comments.created;
			this.state.edit_id = actualId;
			this.props.comments.created = null;
		}
		this.props.versionStatsUpdated(this.props.comments);
		
		this.props.comments.list.sort((a,b) => (a.updated_at > b.updated_at) ? -1 : ((b.updated_at > a.updated_at) ? 1 : 0)); 

	
		return(
			<div className={"CommentCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
				<div className="title">
					<span>{i18n.t("comments_title")}</span>
					<div className="buttons right">
						<Button className={Permission(292)} left="plus" color="black" onClick={this.newClicked}>{i18n.t("comments_add_btn")}</Button>			
						<Button color="black" onClick={this.props.showVisualizationCallback}>{i18n.t("visualization_visualization_btn")}</Button>			
					</div>					
				</div>
				<div className="content">
					<ActionBar newClicked={HasPermission(291)?this.newClicked:null} deleteClicked={(this.activeComment!=null && this.activeComment.editable) && actualId.length>5 && HasPermission(294)?this.deleteClicked:null} editClicked={(this.activeComment!=null && this.activeComment.editable) && (actualId.length>5 && HasPermission(293))?this.editClicked:null}></ActionBar>
					<CustomScroll heightRelativeToParent="calc(100% - 35px)">
				
					{getComments().map((comment, index) =>(
						<div key={index} className={(actualId === comment.id?"active":"")+(this.state.edit_id===comment.id?" editable":"")+" noselect row"} onClick={this.commentSelected} onDoubleClick={this.commentDoubleClicked} data-id={comment.id}>
							<div>
								<span className="marker_name">{index+1}</span>
								<span className="msg">
									<span className="txt">{comment.msg}</span>
															<textarea 
									type={this.props.type!=null?this.props.type:"text"} 
									
									placeholder={this.props.placeholder!=null?this.props.placeholder:""} 
									onFocus={comment.msg===CONST.new?this.onFocus:null}
									onChange={this.textChanged}
									value={comment.msg} 
									>
								</textarea>
								<Button className="save" onClick={this.saveClicked}>{i18n.t("comments_save_btn")}</Button>
								</span>
							</div>
							<div className="footer" style={{"backgroundColor":HashStringToColor(comment.username)}}><span className="time">{ConvertMySqlDateForDisplay(comment.updated_at)}</span><span className="author">{comment.username}</span></div>
						</div>
					))}
				
				</CustomScroll>

				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({	
	selectedVersionId:getSelectedVersionId(state),
	selectedCommentId:getSelectedCommentId(state),
	comments:getCommentsReducer(state)
})

const mapDispatchToProps = dispatch => bindActionCreators(
	{
		showAlertMessage,
		getComments:getAllByVersionId, 
		setSelectedCommentId, 
		updateComment,
		createComment,
		deleteComment,
		versionStatsUpdated
	}, 
	dispatch
);

export default connect(mapStateToProps,mapDispatchToProps)(CommentCard);

