var UserSettings;
export function getVersionExtendedActivated()
{
	return loadSetting("version_extended_activated");
}

export function setVersionExtendedActivated(value)
{
	return saveSetting("version_extended_activated", value);
}

function saveSetting(key, value)
{
	if(getVersionExtendedActivated == null)
		loadSetting(key);		
	UserSettings[key] = value;
	localStorage.setItem("UserSettings_"+key, value);
}

function loadSetting(key)
{
	if(UserSettings == null)
	{
		UserSettings = getIntialSettings();
		UserSettings["version_extended_activated"] = localStorage.getItem("UserSettings_version_extended_activated")==="true";
	}

	return UserSettings[key];
}

function getIntialSettings()
{
	return {
		version_extended_activated:false
	};
}

