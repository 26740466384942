import React, {Component} from 'react';
import i18n from "../i18n";
import CustomScroll from 'react-custom-scroll';
import {IsMobile} from 'Utils';
import LoginView from "./LoginView"

import "./ContactView.css";

class ContactView extends Component
{
	render()
	{
		let mobile = IsMobile();
		let leftClassC = "_c1-3";
		if(mobile)
			leftClassC = "_c1-4";

		return(
			<>
			<div className={"ContactView Card round tile _r3-4 "+leftClassC}>
				<div className="ContactView-title Card-title">
					<span>{i18n.t("contact_title")}</span>				
				</div>
				<div className="Card-content display-linebreak">
					<CustomScroll heightRelativeToParent="100%">
						<div className="txt_col_2" dangerouslySetInnerHTML={{__html:i18n.t("contact_body")}}></div>
					</CustomScroll>
				</div>
			</div>
			<LoginView className={mobile?"hidden":""} row="_r3-4" column="_c4"></LoginView>
			</>
		);
	}
}
export default ContactView;