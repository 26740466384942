import {orderReceivedCompaines, Reset as resetCompanies} from './../_models/CompanyModel';
import {CreateSearchIndex, ResetSearchIndex} from './../_models/SearchModel';
import {SetPermissions} from './../_models/Permissions';
import {CONST} from './../Constants';

import {
	AUTH_PENDING, AUTH_ERROR, AUTH_SUCCESS, 
	LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_ERROR, 
	LOGOUT_PENDING, LOGOUT_SUCCESS, LOGOUT_ERROR, 
	PASS_RESET_REQUEST_SUCCESS, PASS_RESET_REQUEST_ERROR, PASS_RESET_REQUEST_PENDING, 
	PASS_RESET_SUCCESS, PASS_RESET_ERROR, PASS_RESET_PENDING,
	SEARCH_PENDING, SEARCH_SUCCESS, SEARCH_ERROR
} from './../actions/login';

import {initialState}  from './../initialState.js';

export function loginReducer(state = initialState, action) {
		
	  switch(action.type) {
			
			case LOGOUT_PENDING: 
			  	return {
					...state,
					error: null,
					pending: true
        	}
				case LOGOUT_SUCCESS:		
					resetCompanies();	
					ResetSearchIndex();				
          return {
						...initialState,
            pending: false,
						token: "",
						loggedOut: true,
						user: {},
						
        	}
				case LOGOUT_ERROR:	
				
          return {
            ...state,
            pending: false,
            error: action.info
					}
			case AUTH_PENDING: 
			  	return {
					...state,
					error: null,
					pending: true,
					pendingLocation:action.payload.pendingLocation
        	}
				case AUTH_SUCCESS:							
					resetCompanies();
					
          return {
						...state,
            pending: false,
            token: action.payload.token,
						user: action.payload.user,
						companies: orderReceivedCompaines(action.payload.companies),
						project_companies: orderReceivedCompaines(action.payload.companies_projects, CONST.project),
						report_companies: orderReceivedCompaines(action.payload.companies_reports, CONST.report),
						sales_companies: orderReceivedCompaines(action.payload.companies_sales, CONST.sales),
						settings_companies: orderReceivedCompaines(action.payload.companies_settings, CONST.settings),
						permissions:SetPermissions(action.payload.permissions),
						searchIndex:CreateSearchIndex(action.payload.searchindex),
        	}
        case AUTH_ERROR:					
          return {
            ...state,
            pending: false,
            error: action.info
					}
				case LOGIN_PENDING: 
			  	return {
					...state,
					error: null,
					pending: true
        	}
				case LOGIN_SUCCESS:		
			
					resetCompanies();
					
          return {
						...state,
            pending: false,
						token: action.payload.token,
						user: action.payload.user,
						companies: orderReceivedCompaines(action.payload.companies),
						project_companies: orderReceivedCompaines(action.payload.companies_projects, CONST.project),
						report_companies: orderReceivedCompaines(action.payload.companies_reports, CONST.report),
						sales_companies: orderReceivedCompaines(action.payload.companies_sales, CONST.sales),
						settings_companies: orderReceivedCompaines(action.payload.companies_settings, CONST.settings),
						permissions:SetPermissions(action.payload.permissions),
						searchIndex:CreateSearchIndex(action.payload.searchindex)
        	}
        case LOGIN_ERROR:					
          return {
            ...state,
            pending: false,
            error: action.info
					}
				case PASS_RESET_REQUEST_SUCCESS:
					return{
						...state,
						passresetrequestpending:false,
						passresetrequestinfo: action.info
					}
				case PASS_RESET_REQUEST_ERROR:
					return{
						...state,
						passresetrequestpending:false,
						passresetrequesterror: action.info
					}
				case PASS_RESET_REQUEST_PENDING:
					return{
						...state,
						passresetrequesterror: null,
						passresetrequestpending: true
					}
				case PASS_RESET_SUCCESS:
					return{
						...state,
						passresetpending:false,
						passresetinfo: action.info
				}
				case PASS_RESET_ERROR:
				return{
					...state,
					passresetpending:false,
					passreseterror: action.info
				}
				case PASS_RESET_PENDING:
				return{
					...state,
					passreseterror: null,
					passresetpending: true
				}
				case SEARCH_PENDING: 
			  	return {
					...state,
					error: null,
					pending: true
        	}
				case SEARCH_SUCCESS:				
					return {
						...state,
            pending: false,            
						searchIndex:CreateSearchIndex(action.payload),
        	}
        case SEARCH_ERROR:					
          return {
            ...state,
            pending: false,
            error: action.info
					}
        default: 
           return state;
    }
}

export const getPendingLocation = state => state.login.pendingLocation;
export const getLogin = state => state.login.token;
export const getLogout = state => state.login.loggedOut;

export const getUser = state => state.login.user;
export const getCompanies = state => state.login.companies;
export const getAuth = state => state.login.token;
export const getLoginPending = state => state.login.pending;
export const getLoginError = state => state.login.error;
export const getPassResetRequestDone = state => state.login.passresetrequestinfo;
export const getPassResetRequestError = state => state.login.passresetrequesterror;
export const getPassResetRequestPending = state => state.login.passresetrequestpending;

export const getPassResetDone = state => state.login.passresetinfo;
export const getPassResetError = state => state.login.passreseterror;
export const getPassResetPending = state => state.login.passresetpending;

export const getSearchIndex = state => state.login.searchIndex;


