import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n.use(LanguageDetector).init({
  resources: { 
    en: {
      translations: {
        board: "board"        
      }
    },
  
    de: {
      translations: {
				roman_1: "I",
				roman_2: "II",
				roman_3: "III",
				roman_4: "IV",
				roman_5: "V",
				roman_6: "VI",
				roman_7: "VII",
				roman_8: "VIII",
				roman_9: "IX",
				roman_10: "X",
				roman_11: "XI",
				roman_12: "XII",
				roman_13: "XIII",
				roman_14: "XIV",
				roman_15: "XV",
				board_title: "board",
				clients_title: "clients",
				agency_title: "agency",
				
				sales_title: "sales",
				report_title: "report",
				config_title: "config",
				logout: "Abmelden",
				add_company: "add client",

				project_search_category: "project",
				clients_search_category: "client",

				article_short:"Art.",

				company_level_title:"Supply chain partner",
				company_details_name_label: "company",
				company_details_name_placeholder: "company name",
				company_details_department_label:"department",
				company_details_department_placeholder:"department",
				company_details_street_label:"street",
				company_details_street_placeholder:"street",
				company_details_city_label:"zip / place",
				company_details_city_placeholder:"zip / place",
				company_details_country_label:"country",
				company_details_country_placeholder:"country",
				company_details_email_label:"email",
				company_details_email_placeholder:"email",
				company_details_vatnr_label:"VAT-Nr.",
				company_details_vatnr_placeholder:"VAT-Nr.",
				company_details_user_name_label:"contact person",
				company_details_user_name_placeholder:"contact person",
				company_details_user_email_label:"email (personal)",
				company_details_user_email_placeholder:"email (personal)",
				company_details_user_mobile_label:"mobile (personal)",
				company_details_user_mobile_placeholder:"mobile (personal)",
				company_details_user_phone_label:"telefon",
				company_details_user_phone_placeholder:"telefon",
				company_details_user_fax_label:"Fax",
				company_details_user_fax_placeholder:"Fax",
				company_details_bank_label:"bank",
				company_details_bank_placeholder:"bank",				
				company_details_iban_label:"iban",
				company_details_iban_placeholder:"iban",
				company_details_bic_label:"bic",
				company_details_bic_placeholder:"bic",

				company_details_adam_id_label:"adam id",
				company_details_adam_id_placeholder:"adam id",
				company_details_system_id_label:"system id",
				company_details_system_id_placeholder:"system id",

				company_details_logo_placeholder:"Upload Logo",
				company_details_logo_label:"logo",
				
				company_details_save_btn:"save file",


				company_details_levelup_label:"levelUp",
				company_details_levelup_btn:"set as topLevel",
				company_details_levelUp_notset: "no set",

				company_details_leveldown_label:"levelDown",
				company_details_leveldown_btn:"add levelDown client",
				company_details_leveldown_delete_btn:"delete client",
				company_details_leveldown_activate_btn:"activate client",
				company_details_leveldown_deactivate_btn:"deactivate client",

				company_details_members_label:"member",
				company_details_members_input_placeholder:"...enter an email adress",

				company_details_members_msg_saved_title:"Saved",
				company_details_members_msg_saved_text:"member([#email]) settings have been saved!",
				

				company_details_right_config_label:"right config",
				
				company_picker_parent_title : "Select the parent",
				company_picker_child_title : "Select a child",

				company_new_name : "New company",

				permission_picker_role_title: "Select a permission role",

				projects_tile:"projects",
				projects_new_project_title:"new project",
				add_project: "add project",
				project_details_title:"Project details",
				projects_details_name_label:"name",
				projects_details_name_placeholder:"name",
				projects_details_articlenr_label:"article-nr.",
				projects_details_articlenr_placeholder:"article-nr.",
				projects_details_jobnr_label:"job-nr.",
				projects_details_jobnr_placeholder:"job-nr.",
				projects_details_material_label:"material",
				projects_details_material_placeholder:"material",
				projects_details_height_label:"height",
				projects_details_height_placeholder:"height",
				projects_details_width_label:"width",
				projects_details_width_placeholder:"width",
				projects_details_depth_label:"depth",
				projects_details_depth_placeholder:"depth",
				projects_details_save_btn:"save file",
				projects_details_delete_btn:"delete file",
				projects_details_uploadtext_btn:"add your 3D object here <h2>Drag + Drop</h2> .obj Data",
				projects_details_upload_fileselected_text_btn:"add your 3D object here <h2>[#name]</h2> [#size]",
				kB:"kB",
				projects_details_publish_btn:"publish",
				projects_details_unpublish_btn:"unpublish",
				project_edit_disabled_because_version_published:"There is at least one version published. Unpublish all versions before changing the 3D object.",
				versions_tile:"version",
				versions_add_btn:"add version",

				version_edit_disabled_because_published: "Active version ist published. Unpublish the version before changing images.",
				version_map_color_label:"Color (cm.png)",
				version_map_metallic_label:"Metallic (mm.png)",
				version_map_roughness_label:"Roughness (rm.png)",
				version_map_normal_label:"Normal (nm.png)",
				version_map_emmisive_label:"Emmisive (em.png)",
				version_map_ambient_occ_label:"Ambient Occlusion (ao.png)",
				version_map_opacity_label:"Opacity (om.png)",
				
				version_map_upload_btn:"Upload Map",
				version_map_download_btn:"Download Map",

				version_details_title:"Version details",

				visualization_title:"visualization process",
				visualization_comment_btn:"comment view",
				visualization_visualization_btn:"visualization view",
				visualization_project_btn:"project view",
				visualization_uploadtext_btn:"add your Layouts here <h2>Drag + Drop</h2> .png Data",
				visualization_extended_mode:"extended mode",

				visualization_color_label:"Color",
				visualization_metallic_label:"Metallic",
				visualization_roughness_label:"Roughness",
				visualization_normal_label:"Normal",
				visualization_emmisive_label:"Emmisive",
				visualization_ambient_occulusion_label:"Ambient Occlusion",
				
				comments_title:"comments",
				comments_add_btn:"add comment",
				comments_save_btn:"save comment",

				visualization_upload_btn:"Upload Map",
				visualization_download_btn:"Download Map",

				company_details__placeholder:"",



				sales_project_btn:"project view",

				sales_direct_link_to_ar_label: "Direct link to AR project:",
				sales_direct_link_to_ar_copy_btn: "copy",
				sales_email_placeholder: "...enter an email adress",

				report_card_title: "Reports for:",
				report_row_view: "AR project views:",
				report_row_contacts: "AR involved contacts",



				config_card_title: "Config",
				config_mail_server_label:"SMPT Server",
				config_mail_server_placeholder:"SMPT Server",

				config_mail_user_placeholder:"User",
				config_mail_user_label:"User",

				config_mail_pass_placeholder:"Password",
				config_mail_pass_label:"Password",

				config_mail_port_placeholder:"Port",
				config_mail_port_label:"Port",

				config_token_lifetime_label: "Token life time",
				config_token_lifetime_placeholder: "seconds",

				config_mail__placeholder:"",
				config_mail__label:"",
				
				config_save_btn:"save file",
				config_permiossions_label:"Permissions",
				not_allowed:"Not allowed",
				
				search_title: "Results for:",
				search_scope: "category",
				search_client: "searched entry",
				search_result: "result",

				deletealert_title:"Delete. adam.",
				deletealert_cancel_btn:"cancel",
				deletealert_delete_btn:"delete",
				deletealert_hint:"This action cannot be undone.",
				deletealert_message:"Are you sure to delete [#1]?",
				
				tooltip_new:"new",
				tooltip_edit:"edit",
				tooltip_delete:"delete",


				footer_copyright: "&copy; adam.2020",
				footer_contact_title: "contact",
				footer_privacy_title: "privacy",
				footer_imprint_title: "imprint",
				navigation_title: "Navigation",
				login_title: "Login",	
				login_card_title: "Login. adam.",
				login_name_label: "user (email)",	
				login_name_placeholder: "your e-mail address",				
				login_pass_placeholder: "password",	
				login_btn_label: "Login",				
				login_pass_forgot_link: "Forgot password?",				
				login_pass_forgot_title: "Forgot Password. adam.",
								
				login_pass_forgot_btn_label: "Reset",	
				login_pass_forgot_info: "Enter the e-mail address for your adam account and we will help you to set a new password",			
				login_pass_forgot_info_done: "We have sent you an email with a link to reset the password.",			
				login_pass_label:"Password",
				
				login_pass_reset_title: "New Password. adam.",
				login_pass_reset_emailid_label: "reset code",						
				login_pass_reset_emailid_placeholder: "enter received reset code",	
				login_pass_reset_newpass1_label: "new password",						
				login_pass_reset_newpass2_label: "new password",						
				login_pass_reset_setpass_btn_label: "Set new password",			
				


				fileuploader_errorInselectedFile_title: "Error in selected file.",
				fileuploader_versionDetailsFilenameUnknown_title: "Selected file name is unknown.",
				fileuploader_versionDetailsFilenameUnknown_text: "Following file names are alowed: cm.png, mm.png, rm.png, nm.png, em.png, ao.png. Your file is [#filename]",
				fileuploader_versionFilenameUnknown_title: "Selected file name is unknown. Only *.obj files are allowed.",

				fileuploader_projectDetailsFilenameUnknown_title:"Selected file extension is unknown.",
				fileuploader_projectDetailsFilenameUnknown_text:"Following file extensions are allowed: *.obj. Your file is [#filename]",


				fileuploader_versionDetailsFileToBig_title:"",
				fileuploader_versionDetailsFileToBig_text:"Following file [#filename] is too big. Max size is 10MB.",

				fileuploader_version_maps_uploaded:"Map image(s) were uploaded",

				fileuploader_obj_uploaded:"Obj file was uploaded",


				versionMapImage_donwloadUrl_error_title:"Image dowload error",
				versionMapImage_donwloadUrl_error_text:"Image not found",

				copied_toclipboard_title: "Copied to clipboard",
				copied_toclipboard_text: "Added to clipboard {{msg}}",

				loading: "loading...",
				quicksearch_placeholder: "Quicksearch...",
				/*#region Errors*/
				error_000103_user_pass_not_valid: "User not found or pass is invalid.",
				error_app001_input_empty_user:"Enter a valid email address.",
				error_app002_input_differ_pass:"Entered passwords differ.",
				error_app003_input_short_pass:"Entered password is to short. We need at least 6 chars.",
				error_app004_logo_file_size_to_big:"Selected file is to big. Max size is: [#1]",
				error_app005_logo_file_type_not_png:"Selected file isn't a png image. Logo must be a valid PNG image.",
				error_app006_logo_file_error:"Selected file error",
				/*error_app007_email_already_in_members:"Entered emailaddress is already in the members list",*/
				error_app007_email_already_in_watchers:"Entered emailaddress is already in the members list",
				/*#endregion Errors */


				/*#region Messages*/
				msg_app_901_link_copied_to_clipboard:"Link was copied to clipboard.",
				/*#endregion Messages*/

				/*#region long Texts contact, imprint, privacy */
				contact_title:"Contact",
				contact_body: `Like what you saw here? We’re glad to hear it! We are just a click away if you have any questions, or want us to inspire you with our ideas.

				Wishes come true!
				
				IDL Worldwide (EU)
				Kirsten Lingner
				Bid Manager/Office Manager
				
				Fon: +49 (0) 5321 3715 632
				<a href="mailto:hello@idlww.eu">hello@idlww.eu</a>
				
				Magdeburger Kamp 3
				D-38644 Goslar
				`,
				imprint_title:"Imprint",
				imprint_body: `
				<h2>Information in accordance with section 5 TMG:</h2>
IDL Crack Europe GmbH
Magdeburger Kamp 3
38644 Goslar

Represented by:
Lars Mentgen

<h2>Contact:</h2>
Telefon: +49 5321 6866-0
Telefax: +49 5321 6866-312
E-Mail: info@idlww.de

Register entry: Eintragung im Handelsregister.
Register Court: Braunschweig
Register Number: HRB 203 263

<h2>VAT indentification number in accorance with
section 27 a of the German VAT act:</h2>
DE278372028

<h2>Responsibly for the contents after §55 paragraph 2 RStV:</h2>
Lars Mentgen
<div>

<h2>Accountability for content</h2>
The contents of our pages have been created with the utmost care. However, we cannot guarantee the contents' accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible for our own content on these web pages. In this context, please note that we are accordingly not obliged to monitor merely the transmitted or saved information of third parties, or investigate circumstances pointing to illegal activity. Our obligations to remove or block the use of information under generally applicable laws remain unaffected by this as per §§ 8 to 10 of the Telemedia Act (TMG).
</div>

<h2>Accountability for links</h2>
Responsibility for the content of external links (to web pages of third parties) lies solely with the operators of the linked pages. No violations were evident to us at the time of linking. Should any legal infringement become known to us, we will remove the respective link immediately.

<h2>Copyright</h2>
Our web pages and their contents are subject to German copyright law. Unless expressly permitted by law (§ 44a et seq. of the copyright law), every form of utilizing, reproducing or processing works subject to copyright protection on our web pages requires the prior consent of the respective owner of the rights. Individual reproductions of a work are allowed only for private use, so must not serve either directly or indirectly for earnings. Unauthorized utilization of copyrighted works is punishable (§ 106 of the copyright law).

				`
				,
				privacy_title:"Privacy",
				privacy_body: `<h2>Generel</h2>
				Your personal data (e.g. title, name, house address, e-mail address, phone number, bank details, credit card number) are processed by us only in accordance with the provisions of German data privacy laws. The following provisions describe the type, scope and purpose of collecting, processing and utilizing personal data. This data privacy policy applies only to our web pages. If links on our pages route you to other pages, please inquire there about how your data are handled in such cases.
				
				<h2>Inventory data</h2>
				(1) Your personal data, insofar as these are necessary for this contractual relationship (inventory data) in terms of its establishment, organization of content and modifications, are used exclusively for fulfilling the contract. For goods to be delivered, for instance, your name and address must be relayed to the supplier of the goods.
				(2) Without your explicit consent or a legal basis, your personal data are not passed on to third parties outside the scope of fulfilling this contract. After completion of the contract, your data are blocked against further use. After expiry of deadlines as per tax-related and commercial regulations, these data are deleted unless you have expressly consented to their further use.
				
				<h2>Web analysis with Google Analytics</h2>
				This website uses Google Analytics, a web analysis service of Google Inc. (Google). Google Analytics uses cookies, i.e. text files stored on your computer to enable analysis of website usage by you. Information generated by the cookie about your use of this website is usually transmitted to a Google server in the United States and stored there. In case of activated IP anonymization on this website, however, your IP address is previously truncated by Google within member states of the European Union or in other states which are party to the agreement on the European Economic Area. Only in exceptional cases is a full IP address transmitted to a Google server in the United States and truncated there. On behalf this website's owner, Google will use this information to evaluate your use of the website, compile reports about website activities, and provide the website's operator with further services related to website and Internet usage. The IP address sent from your browser as part of Google Analytics is not merged with other data by Google. You can prevent storage of cookies by appropriately setting your browser software; in this case, however, please note that you might not be able to fully use all functions offered by this website. In addition, you can prevent data generated by the cookie and relating to your use of the website (including your IP address) from being collected and processed by Google, by downloading and installing a browser plug-in from the following link:http://tools.google.com/dlpage/gaoptout?hl=en
				
				<h2>Information about cookies</h2>
				(1) To optimize our web presence, we use cookies. These are small text files stored in your computer's main memory. These cookies are deleted after you close the browser. Other cookies remain on your computer (long-term cookies) and permit its recognition on your next visit. This allows us to improve your access to our site.
				(2) You can prevent storage of cookies by choosing a "disable cookies" option in your browser settings. But this can limit the functionality of our Internet offers as a result.
				
				<h2>Disclosure</h2>
				According to the Federal Data Protection Act, you have a right to free-of-charge information about your stored data, and possibly entitlement to correction, blocking or deletion of such data. Inquiries can be directed to the following e-mail addresses: info@idlww.eu
				
				`
				/*#endregion long Texts contact, imprint, privacy */
      }
    }
   
  },
  fallbackLng: "de",
  debug: process.env.NODE_ENV==="development",

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});
export default i18n;