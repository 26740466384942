import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {getCompanies} from './../reducers/login';
import {setSelectedProjectId} from './../api/projects';
import {setSelectedCompanyId} from './../api/company';

import {getSelectedProjectId} from './../reducers/projects';
import { getSelectedCompanyId } from '../reducers/company';

import CompanyCard from '../ui/Company/CompanyCard';
import ProjectsCard from '../ui/ProjectsCard';
import ProjectDetailCard from '../ui/_project/ProjectDetailCard';
import VersionsCard from '../ui/_project/_version/VersionsCard';
import VersionDetailCard from '../ui/_project/_version/VersionDetailCard';
import CommentCard from '../ui/_project/CommentCard';
import CommentPreviewCard from '../ui/_project/CommentPreviewCard';
import SearchDetails from '../ui/SearchDetails';
import { CONST } from '../Constants';


class AgencyView extends Component
{
	constructor(props) {
		super(props);
		this.state={view_type:"visualization"}
		this.commentBtnClicked = this.commentBtnClicked.bind(this);
		this.visualizationBtnClicked = this.visualizationBtnClicked.bind(this);
	}
	commentBtnClicked(e)
	{
		this.setState({view_type:"comment"});
	}
	visualizationBtnClicked(e)
	{
		this.setState({view_type:"visualization"});
	}

	render()
	{
		if(this.props.match.params.projectid == null || this.props.match.params.projectid==="new")
			this.state.view_type = "visualization";
			
		if(this.props.match.params.projectid!=null && this.props.selectedProjectId !==this.props.match.params.projectid )
		{
			this.props.setSelectedProjectId(this.props.match.params.projectid);
		}
		if(this.props.match.params.companyid!=null && this.props.selectedCompanyId !==this.props.match.params.companyid )
			this.props.setSelectedCompanyId(this.props.match.params.companyid);
		
		
		let view_type = this.state.view_type;
		
		let scope = "agency" ;

		let isComment = view_type==="comment";
		return(
			<>
			{
			this.props.match.params.search?
			<>
				<CompanyCard companyid={this.props.match.params.companyid} companies={this.props.companies} readonly={true} scope={scope} gridpositions="_r3-4 _c1"></CompanyCard>
				<SearchDetails search={this.props.match.params.search}  scope={CONST.project}></SearchDetails>
			</>
			:
			<>
				<CompanyCard companyid={this.props.match.params.companyid} companies={this.props.companies} readonly={true} scope={scope} gridpositions="_r3 _c1"></CompanyCard>
				<ProjectsCard gridpositions="_r3 _c2-3" scope={scope} ></ProjectsCard>
				{isComment?<CommentPreviewCard versionid={this.props.match.params.versionid} gridpositions="_r3 _c4"></CommentPreviewCard>:<ProjectDetailCard gridpositions="_r3 _c4"></ProjectDetailCard>}
				<VersionsCard gridpositions="_r4 _c1" companyid={this.props.match.params.companyid}  projectid={this.props.match.params.projectid}  versionid={this.props.match.params.versionid}></VersionsCard>
				{isComment?	<ProjectDetailCard gridpositions="_r4 _c2-3" view_type="short" scope={scope} versionid={this.props.match.params.versionid}></ProjectDetailCard>:<VersionDetailCard gridpositions="_r4 _c2-4" showCommentsCallback={this.commentBtnClicked}  versionid={this.props.match.params.versionid} ></VersionDetailCard>}
				{isComment?	<CommentCard gridpositions="_r4 _c4" showVisualizationCallback={this.visualizationBtnClicked}></CommentCard>:""}
			</>
			}
			</>
		)
		
	}
}

const mapStateToProps = state => ({
	companies: getCompanies(state),
	selectedCompanyId:getSelectedCompanyId(state),
	selectedProjectId:getSelectedProjectId(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({setSelectedProjectId, setSelectedCompanyId}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(AgencyView);

