import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18n from "i18n";

import {PICKER_MESSAGE_TYPE_PERMISSION_ROLE, PICKER_MESSAGE_TYPE_HIDE} from './../../actions/app';
import {getPicker} from './../../reducers/app';
import {showPicker, hidePicker, selectedPicker} from './../../api/appMessage';
import CustomScroll from 'react-custom-scroll';

import "./Picker.css";
import Icon from './../Icon';

class Picker extends Component
{
	constructor(props) {
		super(props);
		
		this.closeClicked = this.closeClicked.bind(this);
		this.selectClicked = this.selectClicked.bind(this);
		
	}
	
	selectClicked(e)
	{
		if(e.currentTarget.dataset.id == "")
			e.currentTarget.dataset.id = "0";
		//console.log(this.props.pickerState.list)
		this.props.selectedPicker(parseInt(e.currentTarget.dataset.id), this.props.pickerState.type, this.props.pickerState.list);
	}
	closeClicked(e) {
		this.props.hidePicker(PICKER_MESSAGE_TYPE_HIDE);
  }

	renderList(_list)
	{
		let list = [];
		for(let i=0; i<_list.length; i++)
		{
			var c = (
			<div className="c" key={i}>
				<div className={"row"+(i===1?" active":"")}>
					<span>{_list[i] !== Object(_list[i])?_list[i]:_list[i].name}</span>
					<Icon type="plus" onClick={this.selectClicked} id={_list[i] !== Object(_list[i] )?i:_list[i].id}></Icon>
				</div>
			</div>
			)
			list.push(c);
		}
		return list;
	}
	render()
	{
		
		if(
			this.props.pickerState == null || 
			this.props.pickerState.type === PICKER_MESSAGE_TYPE_HIDE || 
			this.props.pickerState.selectedPermission != null || //just selected||
			this.props.pickerState.type !== PICKER_MESSAGE_TYPE_PERMISSION_ROLE
		)
			return(<div className="Picker"><div className="Picker-content"></div></div>);
		else
		{
			
			let msg = this.props.pickerState;
			if(msg.list == null)
				msg.list = [];
			if(msg.selectedRoleId == null)
				msg.selectedRoleId = -1;
			
			if(msg.type === PICKER_MESSAGE_TYPE_PERMISSION_ROLE)
				msg.title = i18n.t("permission_picker_role_title");
			else
				msg.title = "unknown";
		
			msg.text = "";

			return(
			<div className={"Picker visible "+msg.type}>
				<div className="bg" onClick={this.closeClicked}></div>
				<div className="Picker-content">	
					<Icon type="close" className="black" onClick={this.closeClicked}></Icon>				
					<div className="content">
						<h2>{msg.title}</h2>
						<br></br>
						<div className="pickerlist">
							<CustomScroll heightRelativeToParent="100%">
								{this.renderList(msg.list)}
							</CustomScroll>
						</div>
					</div>					
				</div>
			</div>
			);
		}		
	}
}

const mapStateToProps = state => ({
	pickerState: getPicker(state),	
})
const mapDispatchToProps = dispatch => bindActionCreators({showPicker, hidePicker, selectedPicker}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Picker);