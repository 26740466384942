import {PROJECTS_GET_PENDING, PROJECTS_GET_SUCCESS, PROJECTS_GET_ERROR, PROJECT_SET_SELECTED_ID, PROJECT_SET_PENDING, PROJECT_SET_ERROR, PROJECT_SET_SUCCESS,
SHARING_GET_ERROR, SHARING_GET_SUCCESS, SHARING_GET_PENDING, SHARING_DEL_SUCCESS, SHARING_ADD_SUCCESS, PROJECTS_UDPATE_VERSION
} from './../actions/projects';
import {parseProject, parseProjects, deleteProject, addProject, getProjects, updateProject} from './../_models/ProjectsModel';
import {initialState}  from './../initialState.js';



export function updatePublishedVersion(state = initialState, version)
{
console.log(version, state)
}

export function projectReducer(state = initialState, action) {

	switch(action.type) {	
		//#region sharing
	
		case SHARING_GET_PENDING: 
	  	return {
			...state,
			salesMembers:
			{
				error: null,
				pending: true,
				list:state.salesMembers.list
			}
		}
		case SHARING_GET_SUCCESS: 
			return {
			...state,			
			salesMembers:
			{
				error: null,
				pending: false,
				list:action.payload!=null?action.payload:[]
			}
		}
		case SHARING_DEL_SUCCESS: 
			let oldlist1 = state.salesMembers.list;
			oldlist1.splice(oldlist1.indexOf(action.payload), 1);
			return {
			...state,			
			salesMembers:
			{
				error: null,
				pending: false,
				list:oldlist1
			}
		}
		case SHARING_ADD_SUCCESS: 
			let oldlist = [...state.salesMembers.list];
			if(action.payload)
				oldlist.push(action.payload);
		
			return {
			...state,			
			salesMembers:
			{
				error: null,
				pending: false,
				list:oldlist
				
			}
		}
		case SHARING_GET_ERROR: 		
			return {
			...state,
			salesMembers:
			{
				error: action.info,
				pending: false
			}
		}		
		//#endregion sharing
		case PROJECTS_GET_PENDING: 
	  	return {
			...state,
			error: null,
			pending: true
     	}
		case PROJECTS_GET_SUCCESS:	
			
		  return {
			...state,
			pending: false,
			info: action.info,
			projects: parseProjects(action.payload),
		
    }
    case PROJECT_SET_ERROR:			
		  return {
      ...state,
      pending: false,
      error: action.info
		}
		case PROJECT_SET_PENDING: 		
	  	return {
			...state,
			error: null,
			pending: true
     	}
		case PROJECT_SET_SUCCESS:
			
			if(action.info.id === "000252")
			{
				let newProject = parseProject(action.payload);
				addProject(newProject);
				
				console.log(newProject)
			
				return {
				...state,
				pending: false,
				info: action.info,	
				projects: getProjects(),
				createdProjectId: newProject.id,
				selectedProjectId: newProject.id	
				}
			}
			else if(action.info.id === "000253")
			{
				updateProject(action.payload);
				/*
				let oldlist = [...state.projects];
				console.log(action.payload);
				for(let i=0; i<oldlist.length;i++)
				{
					if(oldlist[i].id === action.payload.id)
					{
						oldlist[i] = parseProject(action.payload);
						console.log("found");
					}
				}
*/
				return {
				...state,
				pending: false,
				info: action.info,
				projects: getProjects(),
				selectedProjectId: action.payload.id,
				updatedProjectId:action.payload.id
				}
			}
			else if(action.info.id === "000254")
			{
				
				return {
				...state,
				pending: false,
				info: action.info,		
				projects: deleteProject(action.payload),
				selectedProjectId:"0",
				deletedId:action.payload.id

				}
			}
			break;
		case PROJECTS_UDPATE_VERSION:			
			return {
				...state,
				updatedVersionStats:action.payload
			}
			break;
    case PROJECTS_GET_ERROR:
		  return {
      ...state,
      pending: false,
      error: action.info
		}
		case PROJECT_SET_SELECTED_ID:	
				return {
				...state,
				selectedProjectId: action.payload
			}
		
    default: 
      return state;
  }
}

export const getProject = state => state.project;
export const getSalesMembers = state => state.project.salesMembers;
export const getSelectedProjectId = state => state.project.selectedProjectId;
export const getUpdatedProjectId = state => state.project.updatedProjectId;


