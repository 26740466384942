export function ValidateEmail(email)
{
	// eslint-disable-next-line
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function IsMobile()
{
	let c = document.getElementsByClassName("App");
	if(c.length>0 && c[0].classList!=null && c[0].classList.contains("mobile"))
		return true;
	return false;
}
export function IsNavigationVisibleInRoute(route)
{	
	if(route === "/imprint" || route === "/contact" || route === "/privacy" || route.includes("/login") || route.includes("/board") || route === "/" || route.includes("/projectview") )
		return false;
	return true;
}

export function IsProjectViewRoute(route)
{	
	if(route.includes("/projectview") )
		return true;
	return false;
}
export function GetProjectViewHash(route)
{	
	if(route.includes("/projectview") )
	{
	
		let parts = route.split("/projectview/");
		if(parts.length>1 && parts[1].length>2)
			return parts[1];
	}
	return "";
}




export function ConvertMySqlDateForDisplay(dateString)
{
	var dateTime = dateString.split(' ');
	var date = dateTime[0].split('-');
	var time = dateTime[1].split(':');
	
	var utc = Date.UTC(date[0], date[1]-1, date[2], time[0]-1, time[1], time[2]);
	let _utcdate = new Date(utc);   
	//console.log( _utcdate.getMonth()+1, pad(_utcdate.getMonth()+1));
	
	return _utcdate.getDate() 
    + '.' + pad(_utcdate.getMonth()+1)
    + '.' + pad(_utcdate.getFullYear())
    + ' - ' + pad(_utcdate.getHours())
    + ':' + pad(_utcdate.getMinutes())+"h";
}

export function pad(padString, length) {
	if(length==null)
		length=2;
	var str = padString+"";
	while (str.length < length)
			str = "0"+str;
	return str;
}

//#region hashstring
function djb2(str){
  var hash = 5381;
  for (var i = 0; i < str.length; i++) {
    hash = ((hash << 5) + hash) + str.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
}

 
export function HashStringToColor(str) {
	if(str==null)
		str="anonymous";
	//str = MakeRandString(10);
  var hash = djb2(str);
  var r = (hash & 0xFF0000) >> 16;
  var g = (hash & 0x00FF00) >> 8;
	var b = hash & 0x0000FF;
	
	//console.log(r,g,b)
	//console.log(r+g+b)
	let threshold = 300;
	if(r+g+b<threshold)
		return HashStringToColor(str+MakeRandString(1));
  return "#" + ("0" + r.toString(16)).substr(-2) + ("0" + g.toString(16)).substr(-2) + ("0" + b.toString(16)).substr(-2);
}
//#endregion hashstring



export function GetFileAsBase64(file)
{
	let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
		console.log(reader.result);
		return reader.result;
  };
  reader.onerror = function (error) {
		console.log('Error: ', error);
		return "0";
   };
}

export function MakeRandString(length) {
	var result           = '';
	var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for ( var i = 0; i < length; i++ ) {
		 result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function colorizeAdamText(text)
{
	if(!text.includes("adam."))
		return text;
	else
		return text.split("adam.").join("ada<span class='adam_color'>m</span>.");
}