import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {APP_MESSAGE_TYPE_CLEAR} from './../actions/app';
import {getMessages} from './../reducers/app';
import {removeAppMessage} from './../api/appMessage';
import "./MessageAlert.css";
import Icon from './Icon';

class MessageAlert extends Component
{
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick(e) {
		this.props.removeAppMessage(e.currentTarget.dataset.index);
  }

	render()
	{
		/* eslint-disable */
		this.props.messages.map(msg => {msg.key = msg.key == null?msg.key=new Date().getTime():msg.key});
		/* eslint-enable */
		var msg = null;
		if(this.timer!=null)
			clearTimeout(this.timer);

		if(this.props.messages.length>0)
		{
			msg = this.props.messages[this.props.messages.length-1];	
			this.timer = setTimeout(() => this.props.removeAppMessage(msg.key), 5000);
		}
		
		
		if(msg == null || msg.type === APP_MESSAGE_TYPE_CLEAR)
			return(<div className="MessageAlert"><div className="MessageAlert-content"></div></div>);
		else
			return(
			<div className={"MessageAlert visible "+msg.type}>
				<div className="MessageAlert-content">					
					<div className={"appmessage_row "+msg.type} key={msg.key}>
						<h2>{msg.title}</h2>
						<span className="appmessage_txt">{msg.text}</span>
						<div className="appmessage_close" onClick={this.handleClick} data-index={msg.key}>
							<Icon type="close"></Icon>
						</div>
					</div>					
				</div>
			</div>
		);		
	}
}

const mapStateToProps = state => ({
	messages: getMessages(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({removeAppMessage: removeAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(MessageAlert);