import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {getLogin, getUser, getLogout} from './../../reducers/login';
import {login, logout} from './../../api/login';

import "./MainNavigationUserItem.css";


class MainNavigationUserItem extends Component
{
	constructor(props) {
		super(props);		
		this.handleLogoutClick = this.handleLogoutClick.bind(this);				
	}

	handleLogoutClick(event) {
		event.preventDefault();
		//const {logout} = this.props;		
		this.props.logout();		
	}

	render()
	{
		
		const {result} = this.props;
		let loggedIn = result!=null && result.length>10;
		if(!loggedIn)
			return (<Redirect to='/login' />);
		
		let userName = "ANONYMOUS";
		if(this.props.user!=null)
			userName = this.props.user.name;
		if(userName.length<1)
			userName = this.props.user.email;

		return(
			<div className={"MainNavigationUserItem round noselect"+ (this.props.className!=null?" "+this.props.className:"")}>
				<div className="MainNavigationUserItem-content">
					<span className="name">{userName}</span>
					<span className={"MainNavigationUserItem-logout"}>
						<a href="#logout" onClick={this.handleLogoutClick}>logout</a>
					</span>
					
				</div>
			</div>
		);
	}
}


const mapStateToProps = state => ({
	logoutResult: getLogout(state),
	result: getLogin(state),
	user: getUser(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({getLogin: login, logout:logout}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(MainNavigationUserItem);

