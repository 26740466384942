
import {RESPONSE_TYPE} from './../api/_api.js';


export const PROJECTS_GET_PENDING = 'PROJECTS_GET_PENDING';
export const PROJECTS_GET_SUCCESS = 'PROJECTS_GET_SUCCESS';
export const PROJECTS_GET_ERROR = 'PROJECTS_GET_ERROR';

export const PROJECT_SET_PENDING = 'PROJECT_SET_PENDING';
export const PROJECT_SET_SUCCESS = 'PROJECT_SET_SUCCESS';
export const PROJECT_SET_ERROR = 'PROJECT_SET_ERROR';

export const PROJECT_SET_SELECTED_ID = 'PROJECT_SET_SELECTED_ID';


export const SHARING_GET_PENDING = 'SHARING_GET_PENDING';
export const SHARING_GET_SUCCESS = 'SHARING_GET_SUCCESS';
export const SHARING_DEL_SUCCESS = 'SHARING_DEL_SUCCESS';
export const SHARING_ADD_SUCCESS = 'SHARING_ADD_SUCCESS';
export const SHARING_GET_ERROR = 'SHARING_GET_ERROR';

export const PROJECTS_UDPATE_VERSION = 'PROJECTS_UDPATE_VERSION';



export function fetchSharingMember_del(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: SHARING_GET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type:  SHARING_DEL_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type:  SHARING_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}



export function versionStatsUpdated(data)
{
	return {
		type:  PROJECTS_UDPATE_VERSION,
		info: "",
		payload: data
	};

}

export function fetchSharingMember_add(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: SHARING_GET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type:  SHARING_ADD_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type:  SHARING_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function fetchSharingMembers_get(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: SHARING_GET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type:  SHARING_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type:  SHARING_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}


export function fetchProjects_get(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PROJECTS_GET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{		
		return {
			type:  PROJECTS_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type:  PROJECTS_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function fetchSelectedProjectId_set(payload) {
	return {	
		type:  PROJECT_SET_SELECTED_ID,
		payload: payload
	};
	
}

export function fetchProject_set(type, info, payload) {

	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PROJECT_SET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type:  PROJECT_SET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type:  PROJECT_SET_ERROR,
			info: info,
			payload: payload
		};
	}
}
