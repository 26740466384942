import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//import {SearchCompany} from './../_models/CompanyModel';
import {Search, ShouldRefreshIndex, GetVersion} from './../_models/SearchModel';
import {getSearchIndex as loadIndex} from './../api/login';
import {getSearchIndex} from './../reducers/login';

import "./QuickSearch.css";
import i18n from '../i18n';
import { CONST } from '../Constants';
import { Link, withRouter } from 'react-router-dom';

class QuickSearch extends Component
{
	constructor(props) {
		super(props);
		this.state = {search:""}
		this.onTextChange = this.onTextChange.bind(this);
		this.resultsRef = React.createRef();
		this.documentClicked  = this.documentClicked.bind(this);
	}

	onTextChange(event){	
		if(event.target.value.length===0)
		{
			this.setState({search:event.target.value, results:[]});
			return;
		}
		if(ShouldRefreshIndex())
		{
			this.props.loadIndex();
			//this.setState({search:event.target.value, results:[], pending:GetVersion()});
		}
		else
		{
			this.setState({search:event.target.value, results:Search(event.target.value)});
		}
	}

	renderResults(results)
	{
		let list = [];
	
		
		for(let i in results)
		{
			if(results[i].scope !== this.props.scope && results[i].scope!==CONST.project || results[i].scope===CONST.project && this.props.scope!==CONST.agency)
				continue;
			let amount = 0;
			amount+=results[i].results.length;
			if(amount==0)
				continue;
			else
				list.push(<div key={i}><Link to={"/"+(results[i].scope===CONST.project?CONST.agency:results[i].scope)+"/search/"+this.state.search}>{this.state.search}<span>{results[i].scope+" ("+amount+")"}</span></Link></div>);		
		}


		for(let i in results)
		{
			if(results[i].scope == CONST.settings || results[i].scope === this.props.scope || (this.props.scope===CONST.agency && results[i].scope===CONST.project))
				continue;
			let amount = 0;
			amount+=results[i].results.length;
			if(amount==0)
				continue;
			else
				list.push(<div key={i}><Link to={"/"+(results[i].scope===CONST.project?CONST.agency:results[i].scope)+"/search/"+this.state.search}>{this.state.search}<span>{results[i].scope+" ("+amount+")"}</span></Link></div>);		
		}
		if(list.length>0)
		{
			document.removeEventListener("mouseup",this.documentClicked, true);
			document.removeEventListener("mouseup",this.documentClicked, false);
			document.addEventListener("mouseup",this.documentClicked);
		}
		return list;
	}

	documentClicked(e)
	{
		document.removeEventListener("mouseup",this.documentClicked, true);
		document.removeEventListener("mouseup",this.documentClicked, false);
		this.state.search="";
		this.state.results = [];
		if(this.resultsRef.current!=null)
			this.resultsRef.current.classList.add("hidden");
	}
	
	componentDidUpdate()
	{
	//	this.lastPath = this.props.location.pathname;
	}
	render()
	{
		//this.lastPath = this.props.location.pathname;
	//	console.log(this.lastPath, this.props.location.pathname);
		if(this.state.pending && this.state.pending != GetVersion())
		{
			this.setState({search:this.state.search, results:Search(this.state.search), pending:null});
		}

		if(this.props.scope !== this.lastscope)
		{
			this.state.results = "";
			this.state.search = "";
			this.lastscope = this.props.scope;		
		}
		if(this.state.results!=null && this.state.results.length>0)
		{
			document.onclick = this.documentClicked;
			if(this.resultsRef!=null && this.resultsRef.current!=null)
				this.resultsRef.current.classList.remove("hidden");	
		}
		
		return(
			<div className="QuickSearch Card round">
				<div className="QuickSearch-content">
					<input type="text" 
						placeholder={i18n.t("quicksearch_placeholder")} 
						className="round"
						onChange={this.onTextChange} 
						value={this.state.search!=null?this.state.search:""}
					/>
				</div>
				<div className="results" ref={this.resultsRef}>
					{this.renderResults(this.state.results)}
				</div>
			</div>
			
		);
	}
}

const mapStateToProps = state => ({
	searchIndex:getSearchIndex(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({loadIndex}, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(QuickSearch);