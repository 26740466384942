//let projects_map = {};
let projects = [];

export function getProjects()
{
	return projects;
}
export function parseProjects(rawProjectsList)
{
	projects = [];
	
	//projects_map = {};
	/* eslint-disable */
	for(let rawP in rawProjectsList)
	{
		 let p = parseProject(rawProjectsList[rawP]);
		 if(p!=null)
		 {
			 //projects_map[p.id] = p;
			 projects.push(p);
		 }
	}
	/* eslint-enable */
	
	return projects;
}


export function updateProject(project)
{
	addProject(project);
}


export function addProject(project)
{
	let _project = findProjectById(project.id);
	
	if(!_project)
	{
		projects.push(project);
	}
	else
	{
		deleteProject(project);
		projects.push(project);
	}
	return project;
}

export function findProjectById(id)
{
	
	for(let i=0;i<projects.length;i++)
	{
		if(projects[i].id === id)
		{			
			return projects[i];
		}
	}	
	
}

export function parseProject(rawProject)
{
	if(rawProject== null)
		return null;
		
	let project = {
	"id": "",
	"company_id": "",
	"name": "",
	"article_nr": "",
	"job_nr": "",
	"material": "",
	"height": "",
	"width": "",
	"depth": "",
	"comments":0,
	"obj_file": null,
	"hash": "",
	"url":"",
	"published_versions":[]
	};

	project.id  = rawProject.id;
	project.company_id  = rawProject.company_id;
	project.name  = rawProject.name;
	project.article_nr  = rawProject.article_nr;
	project.job_nr  = rawProject.job_nr;
	project.material  = rawProject.material;
	project.height  = rawProject.height;
	project.width  = rawProject.width;
	project.depth  = rawProject.depth;
	project.obj_file  = rawProject.obj_file;
	project.hash  = rawProject.hash;
	project.url  = rawProject.url;
	project.comments = rawProject.comments;
	project.published_versions = rawProject.published_versions;
	if(project.published_versions==null)
		project.published_versions = [];
	return project;
}


export function deleteProject(rawProject)
{
	//if(projects_map[rawProject.id]!=null)
	//{
		let foundIndex = -1;
		for(let i=0;i<projects.length;i++)
		{
			if(projects[i].id === rawProject.id)
				foundIndex = i;
		}
		if(foundIndex>=0)
			projects.splice(foundIndex, 1);
	//	delete projects_map[rawProject.id];
	//}

	return projects;
}