import React, {Component} from 'react';

import "./Icon.css";
import i18n from '../i18n';
class Icon extends Component
{
	

	render()
	{
		let tooltip = "";
		if(this.props.type === "delete")
			tooltip = i18n.t("tooltip_delete");
		else if(this.props.type === "edit")
			tooltip = i18n.t("tooltip_edit");
		else if(this.props.type === "duplicate")
			tooltip = i18n.t("tooltip_new");
		if(this.props.title!=null)
			tooltip = this.props.title;
		return(
			<div className={"Icon "+this.props.type+" "+(this.props.status!=null?this.props.status:"")+(this.props.className!=null?" "+this.props.className:"")} onClick={this.props.onClick} data-id={this.props.id?this.props.id:""} title={tooltip}></div>
		);
	}
}
export default Icon;