import {fetchArProject_get} from './../actions/ar';
import {_doRequest} from './_api.js';
import {_doAppRequest} from './_app.js';

export function getARProjectByHash(data) {	
	return _doRequest(
		"arproject_get", 
		fetchArProject_get, 
		"000501",
		data
	);
}