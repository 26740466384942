import {
	fetchCompanyMembers_get, 
	fetchCompanyMember_update,
	fetchCompanyMember_delete,	
	companyMemberSelect
	
}from './../actions/companyMembers';


import {_doRequest} from './_api.js';
import {_doAppRequest} from './_app.js';

export function getMembers( data) {	
		return _doRequest(
		"company_members_get", 
		fetchCompanyMembers_get, 
		"000221",
		data
	);
}

export function setMember(data) {	

	return _doRequest(
		"company_member_set", 
		fetchCompanyMember_update, 
		"000223",
		data
	);
}


export function deleteMember(data) {	

return _doRequest(
	"company_member_delete", 
	fetchCompanyMember_delete, 
	"000224",
	data
);
}


export function selectMember(member) {	
	return dispatch => {
		dispatch(companyMemberSelect(member));					
	};
}