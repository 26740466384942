export const APP_MESSAGE_TYPE_INFO = 'APP_MESSAGE_TYPE_INFO';
export const APP_MESSAGE_TYPE_WARNING = 'APP_MESSAGE_TYPE_WARNING';
export const APP_MESSAGE_TYPE_ERROR = 'APP_MESSAGE_TYPE_ERROR';
export const APP_MESSAGE_TYPE_CLEAR = 'APP_MESSAGE_TYPE_CLEAR';
export const APP_MESSAGE_TYPE_TODO = 'APP_MESSAGE_TYPE_TODO';



export const PICKER_MESSAGE_TYPE_PARENT = 'PICKER_MESSAGE_TYPE_PARENT';
export const PICKER_MESSAGE_TYPE_PERMISSION_ROLE = 'PICKER_MESSAGE_TYPE_PERMISSION_ROLE';
export const PICKER_MESSAGE_TYPE_CHILD = 'PICKER_MESSAGE_TYPE_CHILD';
export const PICKER_MESSAGE_TYPE_HIDE = 'PICKER_MESSAGE_TYPE_HIDE';

export const APP_MESSAGE_ADD = 'APP_MESSAGE_ADD';
export const APP_MESSAGE_REMOVE = 'APP_MESSAGE_REMOVE';

export const ALERT_MESSAGE_TYPE_SHOW = 'ALERT_MESSAGE_TYPE_SHOW';
export const ALERT_MESSAGE_TYPE_HIDE = 'ALERT_MESSAGE_TYPE_HIDE';


export function appMessageAdd(title, text, type) {	
	return {
		 type: APP_MESSAGE_ADD,
		 
		 message:{title:title, text:text, type:type}
	}
}
export function appMessageRemove(key) {	
	return {
		 type: APP_MESSAGE_REMOVE,
		 key:key
	}
}

export function appPickerShow(type, selectedId, list) {	

	return {
		 type: type,	
		 selectedId: selectedId,
		 list:list	 
	}
}

export function appPickerSelected(id, type, list) {	
	return {
		 company_id: id,
		 selectedId: id,
		 list: list,
		 type: type
	}
}
export function appPickerHide(type) {	

	return {
		 type: PICKER_MESSAGE_TYPE_HIDE,
		 company_id: null
	}
}


export function alertMessageShow(msg) {	
	return {
		 type: ALERT_MESSAGE_TYPE_SHOW,	
		 message: msg 
	}
}
export function alertMessageHide(type) {	

	return {
		 type: ALERT_MESSAGE_TYPE_HIDE,
		 company_id: null
	}
}


