import {COMPANY_GET_PENDING, COMPANY_GET_SUCCESS, COMPANY_GET_ERROR, COMPANY_SET_SELECTED_ID,
	COMPANYMEMBER_GET_SUCCESS, COMPANYMEMBER_UPDATE_SUCCESS, COMPANYMEMBER_UPDATE_ERROR,
	COMPANYMEMBER_DELETE_SUCCESS,
	COMPANY_CREATE_SUCCESS,
	COMPANY_UPDATE_SUCCESS,
	COMPANY_DELETE_SUCCESS,
	COMPANYMEMBER_SELECTED
} from './../actions/company';

import {addCreatedCompanyAndReturnAll, removeDeletedCompanyAndReturnAll, updateCompanyAndReturnAll} from './../_models/CompanyModel';
import {SetCompanyPermissions} from './../_models/Permissions';
import {initialState}  from './../initialState.js';

export function companyReducer(state = initialState, action) {	
	switch(action.type) {			
	case COMPANY_GET_PENDING: 
	 	return {
		...state,
		error: null,
		pending: true
   	}
	case COMPANY_GET_SUCCESS:	
		
		SetCompanyPermissions(action.payload[0].userPermissions);
	  return {
			...state,
			pending: false,
			info: action.info,
			companies: action.payload,
			selectedCompany:action.payload[0],
			selectedCompanyId:action.payload[0].id,
			updatedCompanyTime:new Date()
		}
	case COMPANY_UPDATE_SUCCESS:	
		
		//SetCompanyPermissions(action.payload[0].userPermissions);
	  return {
			...state,
			pending: false,
			info: action.info,
			companies: updateCompanyAndReturnAll(action.payload),
			/*selectedCompany:action.payload[0],*/
			updatedCompany:action.payload[0],
			updatedCompanyTime:new Date()
	}
		
  case COMPANY_GET_ERROR:
		if( action.info.id === "000201")
			return {
				...state,
				pending: false,
				error: action.info,
				selectedCompanyId:null,
				selectedCompany:null
			}
			
		return {
			...state,
			pending: false,
			error: action.info
			
		}
	case COMPANY_SET_SELECTED_ID:
			return {
			...state,
			pending:false,
			error:null,
			selectedCompanyId: action.payload
		}
	case COMPANY_CREATE_SUCCESS:		
		return {
			...state,
			pending: false,
			info: action.info,
			companies: addCreatedCompanyAndReturnAll(action.payload),
			deletedCompany:null,
			updatedCompany:null,
			createdCompany:action.payload,
			selectedCompanyId: action.payload.id
		}
	
	case COMPANY_DELETE_SUCCESS:		
		return {
			...state,
			pending: false,
			info: action.info,
			companies: removeDeletedCompanyAndReturnAll(action.payload),
			updatedCompanyTime:new Date(),
			deletedCompany:action.payload
		}
  default: 
    return state;
  }
}
//TODO
export const getCompany = state => state.company;
export const getSelectedCompany = state => state.company.selectedCompany;
export const getSelectedCompanyId = state => state.company.selectedCompanyId;
export const getUpdatedCompany = state => state.company.updatedCompany;
export const getLastUpdate = state => state.company.updatedCompanyTime;
export const getCreatedCompany = state => state.company.createdCompany;


export const getCompanyPending = state => state.company.pending;
export const getCompanyError = state => state.company.error;
export const getCompanyMembers = state => state.company.members;
export const getCompanySelectedMember = state => state.company.memberSelected;

