import React, {Component} from 'react';
import i18n from "../i18n";
import { connect } from 'react-redux';

import {isPending} from './../reducers/api';

import "./LoadingSpinner.css";

class LoadingSpinner extends Component
{
	constructor(props)
	{
		super(props);
//		this.ref = React.createRef();
	}
	render()
	{

		return(
			<div className={"LoadingSpinner "+(this.props.pending===true?"visible":"") }>
				<div className="LoadingSpinner-content">
					<div className="ani"></div>
					<span>{i18n.t("loading")}</span>
				</div>
			</div>
		);		
	}
}

const mapStateToProps = state => ({
	pending: isPending(state)
})

export default connect(mapStateToProps,null)(LoadingSpinner);