import {VERSIONS_GET_PENDING, VERSIONS_GET_SUCCESS, VERSIONS_GET_ERROR, VERSION_SET_SELECTED_ID, VERSION_SET_PENDING, VERSION_SET_ERROR, VERSION_SET_SUCCESS} from './../actions/versions';
import {parseVersion, parseVersions, deleteVersion, udpateVersion, addVersion, getVersions} from './../_models/VersionsModel';
import {initialState}  from './../initialState';

/*import { version } from 'react';*/


export function versionReducer(state = initialState, action) {

	switch(action.type) {			
		case VERSIONS_GET_PENDING: 
	  	return {
			...state,
			error: null,
			pending: true
     	}
		case VERSIONS_GET_SUCCESS:	

		  return {
			...state,
			pending: false,
			info: action.info,
			list: parseVersions(action.payload)/*.sort((a,b) => (a.version > b.version) ? 1 : ((b.version > a.version) ? -1 : 0)),*/
		
    }
		case VERSION_SET_ERROR:		
			console.log("VERSION_SET_ERROR", action)	
		  return {
      ...state,
      pending: false,
      error: action.info
		}
		case VERSION_SET_PENDING: 		
	  	return {
			...state,
			error: null,
			pending: true
     	}
		case VERSION_SET_SUCCESS:
			//console.log(action)
			if(action.info.id === "000272")
			{
				let newVersion = parseVersion(action.payload);
				addVersion(newVersion);
				return {
				...state,
				pending: false,
				info: action.info,	
				list: getVersions(),
				
				createdVersion: newVersion,
				createdVersionId: newVersion.id
				}
			}
			else if(action.info.id === "000273")
			{
				udpateVersion(action.payload);
				
				return {
				...state,
				pending: false,
				info: action.info,
				updatedVersionId: action.payload.id,
				updatedVersion:action.payload
				}

			
			}
			else if(action.info.id === "000274")
			{
				deleteVersion(action.payload);

				return {
				...state,
				pending: false,
				info: action.info,		
				list: getVersions(),
				selectedVersionId: -1
				}
			}
			break;
    case VERSIONS_GET_ERROR:
		  return {
      ...state,
      pending: false,
      error: action.info
		}
		case VERSION_SET_SELECTED_ID:	
				return {
				...state,
				selectedVersionId: action.payload				
			}
    default: 
      return state;
  }
}

export const getVersionsReducer = state => state.version;
export const getUpdatedVersionId = state => state.version.updatedVersionId;
export const getCreatedVersionId = state => state.version.createdVersionId;

export const getSelectedVersionId = state => state.version.selectedVersionId;

