import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18n from "i18n";


import {getARProjectByHash} from './../api/ar';
import {getArProject} from './../reducers/arproject';

import CustomScroll from 'react-custom-scroll';
import TextInputModel from 'ui/TextInputModel';
import "./ProjectView.css";
import Button from '../ui/Button';
import QrCode from 'qrcode.react';
class ProjectView extends Component
{
	componentDidMount()
	{
		if(this.props.match.params.projectid!=null && this.props.match.params.projectid.length>5)
		{
			this.props.getARProjectByHash({hash:this.props.match.params.projectid});
			this.lasthash = this.props.match.params.projectid;
		}		
	}

	shouldComponentUpdate()
	{
		if(this.project != null)
			return false;
		return true;
	}

	render()
	{
		if(this.props.arProject.project==null)
			return(i18n.t("loading"));
	
		this.project = this.props.arProject.project;
		
		this.model = this.props.arProject.project;//{name:"Haribo Display System inStore", article_nr:"Art. 2661KP6"}
		this.versions = this.model.entries;
				
		
		let editable = false;

		let v=[];
		for(let i=0; i<this.versions.length;i++)
		{
			v.push(
				<a key={i} target="_blank" href={this.versions[i].usdz_url+"?"+new Date().getTime()} rel="ar" className="version">
 					<div style={{backgroundImage: "url("+this.versions[i].img+")"}}>{/*<QrCode value={this.versions[i].usdz_url} includeMargin={true} renderAs="svg" ></QrCode>*/}</div>
				</a>
			);
		}
		
		let qrlink = (window.location+"").replace("localhost","192.168.178.154");
		if(qrlink.indexOf("192.168.178.154")===-1)
			qrlink = qrlink.replace("#/","");
		
		return(
			<div className="_r2-5 _c1-4 Card ProjectView">
				<div className="Card-title">
					<span>{this.model.name}</span>
					
				</div>

				<div className="Card-content">
				<div className="head">
					<div className="left">
					<TextInputModel _key="name" placeholder={i18n.t("projects_details_name_placeholder")} label={i18n.t("projects_details_name_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="article_nr" placeholder={i18n.t("projects_details_articlenr_placeholder")} label={i18n.t("projects_details_articlenr_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="job_nr" placeholder={i18n.t("projects_details_jobnr_placeholder")} label={i18n.t("projects_details_jobnr_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="material" placeholder={i18n.t("projects_details_material_placeholder")} label={i18n.t("projects_details_material_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="height" placeholder={i18n.t("projects_details_height_placeholder")} label={i18n.t("projects_details_height_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="width" placeholder={i18n.t("projects_details_width_placeholder")} label={i18n.t("projects_details_width_label")}  model={this.model} editable={editable}></TextInputModel>
					<TextInputModel _key="depth" placeholder={i18n.t("projects_details_depth_placeholder")} label={i18n.t("projects_details_depth_label")}  model={this.model} editable={editable}></TextInputModel>				
					</div>
					<div className="right">
						<QrCode value={qrlink} includeMargin={true} renderAs="svg"></QrCode>
					</div>
				</div>
					<div className="versions">
						<CustomScroll heightRelativeToParent="calc(100% - 35px)">
						{v}
						</CustomScroll>
					</div>
				</div>
			</div>
		);
	}
}

//


const mapStateToProps = state => ({
	arProject:getArProject(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({getARProjectByHash}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(ProjectView);
