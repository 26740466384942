import React, {Component} from 'react';

import {Link} from 'react-router-dom';

import {IsMobile} from 'Utils';
import "./FootNavigation.css";
import i18n from '../i18n';
import {version as appVersion} from './../../package.json';
class FootNavigation extends Component
{
	render()
	{
	
		return(
			<>
			<div className="FootNavigation tile">
				<div className="FootNavigation-content">
					<span dangerouslySetInnerHTML={{ "__html": i18n.t("footer_copyright") }}></span>|
					{IsMobile()?"":<><Link to="/board">{i18n.t("board_title")}</Link>|</>}
					<Link to="/contact">{i18n.t("footer_contact_title")}</Link>| 
					<Link to="/privacy">{i18n.t("footer_privacy_title")}</Link>|
					<Link to="/imprint">{i18n.t("footer_imprint_title")}</Link>
					<span className="version">{"version: "+appVersion}</span>
				</div>
			</div>
			<div className="FootLogo tile"><img src="images/logo_sgk.png" alt="SGK" /></div>
			</>
		);
	}
}
export default FootNavigation;