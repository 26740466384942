import * as THREE from './../three.module.js';
import {OrbitControls} from './jsm/controls/OrbitControls.js';
import {OBJLoader} from './jsm/loaders/OBJLoader.js';

export default class adam3dviewer {
	constructor(container, model) {
		
		this.statsEnabled = false;
		this.container=null;
		this.stats=null;
		this.camera=null;
		this.scene=null;
		this.renderer=null;
		this.controls=null;
		this.strDownloadMime = "image/octet-stream"; // save
	
		if(container == null)
			return;
		this.render(container, model);
	}

	fitCameraToSelection(camera, controls, obj, fitOffset = 1.2) {
		const box = new THREE.Box3();
	
		box.expandByObject(obj);
		const size = box.getSize(new THREE.Vector3());
		const center = box.getCenter(new THREE.Vector3());
		const maxSize = Math.max(size.x, size.y, size.z);
		const fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * camera.fov / 360));
		const fitWidthDistance = fitHeightDistance / camera.aspect;
		const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);
		const direction = controls.target.clone()
			.sub(camera.position)
			.normalize()
			.multiplyScalar(distance);

		controls.maxDistance = distance * 10;
		controls.target.copy(center);

		camera.near = distance / 100;
		camera.far = distance * 100;
		camera.updateProjectionMatrix();

		camera.position.copy(controls.target).sub(direction);
		controls.update();
	}

	render(div, modeldata_json) {
		this.init(div, modeldata_json);
		this.animate();
	}

	init(div, model) {
	
		if(adam3dviewer.renderer!=null)
			adam3dviewer.renderer.dispose();
		this.container = div;
		this.container.innerHTML = "";
		//console.log(this.container)
		//INFO
		/*
		performance optmization
		1. antialising:false
		2. this.renderer.setPixelRatio (1);
		3. alpha:false???
		*/
		
		this.renderer = new THREE.WebGLRenderer({
			antialias: true,
			preserveDrawingBuffer: true,
			alpha:true
		});
		adam3dviewer.renderer = this.renderer;
		//this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setPixelRatio (1);
		 
	//	this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
		this.container.appendChild(this.renderer.domElement);

		this.renderer.gammaOutput = true;
		this.renderer.gammaInput = true;
		this.renderer.gammaFactor = 2.2;
		
		this.renderer.toneMapping = THREE.LinearToneMapping;//ACESFilmicToneMapping;//ReinhardToneMapping; //LinearToneMapping
		this.renderer.toneMappingExposure =	.7;

		THREE.Cache.enabled = false;
		
		this.scene = new THREE.Scene();
		
		/*this.camera = new THREE.PerspectiveCamera(50, this.container.offsetWidth / this.container.offsetHeight, 0.01, 1000);
		this.camera.position.z = 5;
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.enableKeys = false;
		*/
		//this.scene.add( new THREE.HemisphereLight( 0x333333, 0x000000, 70) );
		
		this.material = new THREE.MeshPhongMaterial();//MeshStandardMaterial();
	//	https://threejsfundamentals.org/threejs/lessons/threejs-materials.html
	//MeshLambertMaterial
		//MeshPhongMaterial
		let material = this.material;
		material.color.convertSRGBToLinear();

		this.SetModel(model);
		
		//cubemap-----------------------
		var path = './3d/examples/textures/cube/studio1/';
		var format = '.jpg';
		var urls = [
			path + 'px' + format, path + 'nx' + format,
			path + 'py' + format, path + 'ny' + format,
			path + 'pz' + format, path + 'nz' + format
		];

		var reflectionCube = new THREE.CubeTextureLoader().load(urls);
		reflectionCube.format = THREE.RGBFormat;
	
		//this.scene.background = new THREE.Color(0xffffff);
		//this.scene.background = reflectionCube;
		material.envMap = reflectionCube;
	
		window.addEventListener('resize', this.onWindowResize.bind(this), false);
	}

	SetModel(model)
	{
	//	console.log(model);
		if(model.obj==null)
		{
			return;
		}
		
		this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
	
		let material = this.material;
		let loader = new OBJLoader();
		
		//console.log(model.obj==this.lastModel.obj, model, this.lastModel);
		if(this.lastModel==null || model.obj!=this.lastModel.obj)
		{
			//console.log("dispose");
			//this.render.dispose();
			this.renderer.renderLists.dispose();
			
			this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);

		//	console.log(this.group, this.modelD );
			if(this.modelD!=null)
				this.modelD.dispose();
			while(this.scene.children.length > 0){ 
				//console.log(this.scene.children[0])
				//this.scene.children[0].dispose();
				this.scene.remove(this.scene.children[0]); 
			}
			//console.log(this.scene)
			
			if(this.material!=null)
				this.material.dispose();
		
			this.scene.dispose();

			this.scene.add( new THREE.HemisphereLight( 0x333333, 0x000000, 70) );

			this.camera = new THREE.PerspectiveCamera(50, this.container.offsetWidth / this.container.offsetHeight, 0.01, 1000);
			this.camera.position.z = 5;
			this.controls = new OrbitControls(this.camera, this.renderer.domElement);
			this.controls.enableKeys = false;
			this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
	
		//	if(this.group!=null)
		//		this.group.dispose();
		
			this.modelD = loader.load(model.obj+"", function (group) {
				
				material.roughness = 1; // attenuates roughnessMap
				material.metalness = 1; // attenuates metalnessMap
			
				this.loadImages(model, material);

				material.map.wrapS = THREE.RepeatWrapping;
				material.roughnessMap.wrapS = THREE.RepeatWrapping;
				material.metalnessMap.wrapS = THREE.RepeatWrapping;
				material.normalMap.wrapS = THREE.RepeatWrapping;
				this.group = group;
				group.traverse(function (child) {
					if (child instanceof THREE.Mesh) {
						child.material = material;
					}
				});
				group.position.x = -0.45;
				group.rotation.y = -Math.PI / 2;
			
				this.scene.add(group);
				this.fitCameraToSelection(this.camera, this.controls, group); //NEW
				this.camera.updateProjectionMatrix();	

				material.needsUpdate = true;
			}.bind(this),
			function ( xhr ) {
				;//console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
			}.bind(this),
			function ( error ) {
				console.log( 'An error happened while loading obj: '+error );
			});
		}	
		else
		{
			
			this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight-6);
			this.camera.aspect = this.container.offsetWidth / (this.container.offsetHeight-6);
		
			this.camera.updateProjectionMatrix();			
			this.fitCameraToSelection(this.camera, this.controls, this.group);
			this.loadImages(model, material);
		}	
		this.lastModel = model;
	}

	loadImages(model, material)
	{
		
		this.imgLoader = new THREE.TextureLoader();
		//console.log("Loadimages", material.map);
	
		if(material.map!=null && material.map.image!=null && material.map.image.src ===  model.color)
			;//console.log("cm,aploaded");
		else
		{
			if(material.map!=null)
				material.map.dispose();
			material.map = this.imgLoader.load(model.color, this.imgLoadDone, this.imgLoadProgress, this.imgLoadError);
		}

		if(material.metalnessMap!=null)
		{
			material.metalnessMap.dispose();
			material.roughnessMap.dispose();
			material.normalMap.dispose();
			material.aoMap.dispose();

			material.emissiveMap.dispose();

		}
		material.metalnessMap = this.imgLoader.load(model.metallic, this.imgLoadDone, this.imgLoadProgress, this.imgLoadError);
		material.roughnessMap = this.imgLoader.load(model.roughness, this.imgLoadDone, this.imgLoadProgress, this.imgLoadError);
		material.normalMap = this.imgLoader.load(model.normal+"?nocache="+(new Date().getTime()), this.imgLoadDone, this.imgLoadProgress, this.imgLoadError);
		material.aoMap = this.imgLoader.load(model.ao, this.imgLoadDone, this.imgLoadProgress, this.imgLoadError);
		material.emissiveMap = this.imgLoader.load(model.emissive, this.imgLoadDone, this.imgLoadProgress, this.imgLoadError);
		if(model.omActive)
		{
			material.transparent = true;
			//material.alphaTest = 0.5
			material.opacityMap = this.imgLoader.load(model.om, this.imgLoadDone, this.imgLoadProgress, this.imgLoadError);	
			console.log(model.om);
		}
		else
		{
			material.transparent = false;
		}
	}

	imgLoadDone(l)
	{
		//console.log("imgloaded",l.image.src);
	}
	imgLoadProgress(xhr)
	{
		console.log(xhr);
	}

	imgLoadError(error)
	{
		console.log(error);
	}

	CreatePreview(callback) {
		var imgData;
		setTimeout(function () {
			try {
				var strMime = "image/png";				
				imgData = adam3dviewer.renderer.domElement.toDataURL(strMime);	
				callback(imgData);			
			} catch (e) {
				console.log("not created", e);
				callback(null);
			}
		}, 100);
	}

	 onWindowResize() {
		this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight-6);
		//console.log(this.container, this.container.offsetWidth, this.container.offsetHeight)
		this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
		this.camera.updateProjectionMatrix();

	}

	//

	 animate() {	
		requestAnimationFrame(this.animate.bind(this) );	
		//if(this.controls == null)
		//	return;
		this.controls.update();
		this.renderer.render(this.scene, this.camera);
		if (this.statsEnabled) 
			this.stats.update();		
	}
};