import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {setSelectedProjectId} from './../api/projects';
import {setSelectedCompanyId} from './../api/company';

import {getSelectedProjectId} from './../reducers/projects';
import {getSelectedCompanyId } from '../reducers/company';
import {getVersionsReducer,getSelectedVersionId } from '../reducers/versions';
import {setSelectedVersionId, getAllByProjectId} from 'api/versions';

import {getCompanies} from './../reducers/login';
import CompanyCard from '../ui/Company/CompanyCard';
import ProjectsCard from '../ui/ProjectsCard';
import SalesVersionDetailsCard from '../ui/_sales/SalesVersionDetailsCard';
import PreviewCard from '../ui/_sales/PreviewCard';
import { findVersionById } from '../_models/VersionsModel';
import { findProjectById } from '../_models/ProjectsModel';
import { CONST } from '../Constants';
import SearchDetails from '../ui/SearchDetails';


class SalesView extends Component
{
	constructor(props) {
		super(props);
		this.state={view_type:"visualization"}
		this.commentBtnClicked = this.commentBtnClicked.bind(this);
		this.visualizationBtnClicked = this.visualizationBtnClicked.bind(this);
	}
	commentBtnClicked(e)
	{
		this.setState({view_type:"comment"});
	}
	visualizationBtnClicked(e)
	{
		this.setState({view_type:"visualization"});
	}

	render()
	{
		if(this.props.match.params.projectid!=null && this.props.selectedProjectId !==this.props.match.params.projectid)
		{
			this.props.setSelectedProjectId(this.props.match.params.projectid);
			this.props.getAllByProjectId({project_id:this.props.match.params.projectid});


		}
		if(this.props.match.params.companyid!=null && this.props.selectedCompanyId !==this.props.match.params.companyid)
		{
			this.props.setSelectedCompanyId(this.props.match.params.companyid);
			
		}
		if(this.props.match.params.versionid!=null && this.props.selectedVersionId !==this.props.match.params.versionid)
		{
			this.props.setSelectedVersionId(this.props.match.params.versionid);
			
		}

		let project = findProjectById(this.props.match.params.projectid);
		let version = findVersionById(this.props.match.params.versionid);
		
		let scope = CONST.sales;

		return(
			<>
			{
			this.props.match.params.search?
			<>
				<CompanyCard companyid={this.props.match.params.companyid} companies={this.props.companies} readonly={true} scope={scope} gridpositions="_r3-4 _c1"></CompanyCard>
				<SearchDetails search={this.props.match.params.search}  scope={CONST.sales}></SearchDetails>
			</>
			:
			<>
				<CompanyCard companyid={this.props.match.params.companyid} companies={this.props.companies} readonly={true} scope={scope} gridpositions="_r3 _c1"></CompanyCard>
				<ProjectsCard gridpositions="_r3 _c2-3" scope={scope}></ProjectsCard>
				<PreviewCard gridpositions="_r3-4 _c4" versionid={this.props.match.params.versionid} version={version} project={project}></PreviewCard>
				<SalesVersionDetailsCard gridpositions="_r4 _c1-3" project={project}></SalesVersionDetailsCard>
			</>
			}
			</>
		);	
	}
}

const mapStateToProps = state => ({
	companies: getCompanies(state),
	selectedCompanyId:getSelectedCompanyId(state),
	selectedProjectId:getSelectedProjectId(state),
	selectedVersionId:getSelectedVersionId(state),
	versionsReducer: getVersionsReducer(state)

})

const mapDispatchToProps = dispatch => bindActionCreators({setSelectedProjectId, setSelectedCompanyId, setSelectedVersionId,	getAllByProjectId}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(SalesView);