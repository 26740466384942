
import {RESPONSE_TYPE, _setToken} from './../api/_api.js';

export const AUTH_PENDING = 'AUTH_PENDING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';




export const SEARCH_PENDING = 'SEARCH_PENDING';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERROR = 'SEARCH_ERROR';


export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const PASS_RESET_REQUEST_SUCCESS = 'PASS_RESET_REQUEST_SUCCESS';
export const PASS_RESET_REQUEST_ERROR = 'PASS_RESET_REQUEST_ERROR';
export const PASS_RESET_REQUEST_PENDING = 'PASS_RESET_REQUEST_PENDING';

export const PASS_RESET_SUCCESS = 'PASS_RESET_SUCCESS';
export const PASS_RESET_ERROR = 'PASS_RESET_ERROR';
export const PASS_RESET_PENDING = 'PASS_RESET_PENDING';

export function fetchAuth(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: AUTH_PENDING, payload:info}; //login pending location
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		_setToken(payload.token);
		return {
			type: AUTH_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: AUTH_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function fetchLogin(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: LOGIN_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		_setToken(payload.token);
		return {
			type: LOGIN_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: LOGIN_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function fetchSearchIndex(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: SEARCH_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: SEARCH_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: SEARCH_ERROR,
			info: info,
			payload: payload
		};
	}
}




export function fetchLogout(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: LOGOUT_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		_setToken(payload.token);
		return {
			type: LOGOUT_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: LOGOUT_ERROR,
			info: info,
			payload: payload
		};
	}
}


export function fetchPassResetRequest(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PASS_RESET_REQUEST_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: PASS_RESET_REQUEST_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: PASS_RESET_REQUEST_ERROR,
			info: info,
			payload: payload
		};
	}
}


export function fetchPassReset(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PASS_RESET_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: PASS_RESET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: PASS_RESET_ERROR,
			info: info,
			payload: payload
		};
	}
}