import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18n from 'i18n';

import {HasCompanyPermission, GetPermissionClassName as Permission  } from '../../../_models/Permissions';
import CustomScroll from 'react-custom-scroll';

import {addAppMessage} from './../../../api/appMessage';
import {getSalesMembers, deleteSalesMember, addSalesMember} from './../../../api/projects';
import {getSalesMembers as gotSalesMembers} from './../../../reducers/projects';
import {APP_MESSAGE_TYPE_CLEAR, APP_MESSAGE_TYPE_ERROR, APP_MESSAGE_TYPE_TODO, APP_MESSAGE_TYPE_INFO} from './../../../actions/app';
import {GetError} from './../../../Errors';
import {ValidateEmail} from './../../../Utils';


import Icon from './../../Icon';

import "./ContactsList.css";

class ContactsList extends Component
{
	constructor(props)
	{
		super(props);
		
		this.state = {selected:null};
		this.onAddClick = this.onAddClick.bind(this);
		this.rowClicked = this.rowClicked.bind(this);
	}

	
	rowClicked(e)
	{
		//console.log(e, e.currentTarget.dataset.email);
		if(e.target.className.includes("minus"))
		{	
		//	this.props.addAppMessage("-1", "//TODO add delete confirm", APP_MESSAGE_TYPE_TODO);	
		
			this.props.deleteSalesMember({project_id:this.projectid, email:e.currentTarget.dataset.email});
		}
		else
		{
			let mail = e.currentTarget.dataset.email;
			navigator.clipboard.writeText(e.currentTarget.dataset.email).then(function(e) {				
				this.props.addAppMessage(i18n.t("copied_toclipboard_title"), i18n.t("copied_toclipboard_text",{msg:mail}), APP_MESSAGE_TYPE_INFO)
			}.bind(this), function(err) {
				
			});
		}
	}

	onAddClick(e)
	{		
		if(!HasCompanyPermission(302))
			return;
		let email = this.refs.member_email_input.value;
		if(!ValidateEmail(email))
		{			
			let err = GetError({id:"app001", "msg":"invalid email"});
			this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);	
			return;
		}
		this.props.addAppMessage("", "", APP_MESSAGE_TYPE_CLEAR);	
		
		for(let i=0; i<this.props.gotSalesMembers.list.length;i++)
		{
			if(this.props.gotSalesMembers.list[i] === email)
			{
				let err = GetError({id:"app007", "msg":"member alrady exists"});
				this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);
				this.setState({selected:email});	
				return;
			}
		}		
		this.props.addSalesMember({project_id:this.projectid, email:email})
		this.refs.member_email_input.value = "";
	}
	render()
	{
		if(this.projectid != this.props.projectid)
		{
			this.projectid = this.props.projectid;
			this.props.getSalesMembers({project_id:this.props.projectid});
			return("");
		}
		
		if(this.props.gotSalesMembers==null || this.props.gotSalesMembers.list==null)
			return("");
		
		if(this.props.gotSalesMembers.selected!=null && this.state.selected!=this.props.gotSalesMembers.selected)
		{
		
			this.setState({selected:this.props.gotSalesMembers.selected});	

		}
		
		let list=[];
		for(var i=0;i<this.props.gotSalesMembers.list.length;i++)
			list.push(
			<div key={i} data-email={this.props.gotSalesMembers.list[i].email} onClick={this.rowClicked} className={"row"+(this.state.selected===this.props.gotSalesMembers.list[i]?" selected":"")}>
				<span>{this.props.gotSalesMembers.list[i].email}</span>
				<Icon type="activity" status={this.props.gotSalesMembers.list[i].active?"ok":"error"} onClick={this.activateClicked}></Icon>
				<Icon className={Permission(304)} type="minus" status="error" onClick={this.deleteClicked} ref="members_list_row_delete"></Icon>
			</div>
			);		

		return(
			<div className="block members noselect">				
				<div className="ContactsList-content ">
					<div className="mailInput">
						<form onSubmit={this.onAddClick} className={Permission(302)} >
						<input type="text" id="memberEmail" placeholder={i18n.t("company_details_members_input_placeholder")} ref="member_email_input" />
						<Icon type="plus" status="green" onClick={this.onAddClick}></Icon>
						</form>
					</div>
					<div className="CompanyMembersList">
					<CustomScroll heightRelativeToParent="100%">
				
						{list}
						</CustomScroll>
					</div>

					
				</div>
			</div>
		);
	}
}


const mapStateToProps = state => ({gotSalesMembers:gotSalesMembers(state)})

const mapDispatchToProps = dispatch => bindActionCreators({addAppMessage, getSalesMembers, deleteSalesMember, addSalesMember}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(ContactsList);
