import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';

import BoardUi from "./../ui/BoardUi";
import CardLogin from "./../ui/_login/CardLogin";
import CardLoginPassForgot from "./../ui/_login/CardLoginPassForgot";
import CardLoginPassReset from "./../ui/_login/CardLoginPassReset";

import "./StartView.css";
class StartView extends Component
{
	render()
	{
		return(
			<div className="_r0 _c0">
			<Switch>
				<Route exact path='/' component={BoardUi}></Route>
				<Route exact path='/board' component={BoardUi}></Route>
				<Route exact path='/login' component={CardLogin}></Route>
				<Route exact path='/login/pass_forgot' component={CardLoginPassForgot}></Route>
				
				<Route path='/login/pass_reset/:email/:emailid' component={CardLoginPassReset}></Route>
				<Route path='/login/pass_reset/:email' component={CardLoginPassReset}></Route>
				<Route path='/login/pass_reset' component={CardLoginPassReset}></Route>
				<Route path='/login/:email/:hash' component={CardLogin}></Route>
			</Switch>
			
			</div>	
		);
	}
}
export default StartView;