import React, {Component} from 'react';

import "./VersionMapImageRow.css";
import Button from 'ui/Button.js';
import Icon from 'ui/Icon.js';
import {CONST} from 'Constants';
//import { basename } from 'path';
import i18n from "i18n";
import Label from 'ui/Label';

class VersionMapImageRow extends Component
{
	constructor(props)
	{
		super(props);
	}

	chkbChanged(e)
	{
		this.props.checked(this.props.type, e.target.checked)
	}
	render()
	{
	
		return(
			<div className={"VersionMapImageRow"+(this.props.disabled?" disabled":"")+(this.props.isCheckBox?((this.props.isChecked?" chkb active":" chkb unactive")):"")}>
				{
				this.props.isCheckBox?
					<div className="chkbl">
						<input id={"check_"+this.props.type} type="checkbox" onChange={this.chkbChanged.bind(this)} checked={this.props.isChecked}></input>
						<label htmlFor={"check_"+this.props.type}>{this.props.name}</label>
					</div>
				:
					<Label className="black" value={this.props.name}></Label>
				}
				
				<Icon status={this.props.status?CONST.ok:CONST.error} type="activity"></Icon>
				<Button color="gray" onClick={()=>{this.props.selected(this.props.type, CONST.upload)}}>{i18n.t("version_map_upload_btn")}</Button>
				<Button onClick={()=>{this.props.selected(this.props.type, CONST.download)}}>{i18n.t("version_map_download_btn")}</Button>
			</div>
		);
	}
}
export default VersionMapImageRow;