import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18n from "i18n";

import {PICKER_MESSAGE_TYPE_PARENT, PICKER_MESSAGE_TYPE_CHILD, PICKER_MESSAGE_TYPE_HIDE, PICKER_MESSAGE_TYPE_PERMISSION_ROLE} from './../../actions/app';
import {getPicker} from './../../reducers/app';
import {getCompanies} from './../../reducers/login';
import {showPicker, hidePicker, selectedPicker} from './../../api/appMessage';
import CustomScroll from 'react-custom-scroll';

import "./CompanyPicker.css";
import Icon from './../Icon';

class CompanyPicker extends Component
{
	constructor(props) {
		super(props);
		
		this.closeClicked = this.closeClicked.bind(this);
		this.selectClicked = this.selectClicked.bind(this);
		
	}
	
	selectClicked(e)
	{
		this.props.selectedPicker(e.currentTarget.dataset.id, this.props.pickerState.type);
	}
	closeClicked(e) {
		this.props.hidePicker(PICKER_MESSAGE_TYPE_HIDE);
  }

	renderCompanies(companies)
	{
		var list = [];
		if(companies== null || companies.length===0)
			return list;
		
		for(let i=0; i<companies.length; i++)
		{
			var c = (
			<div className={"c"+(i===companies.length-1?" last":"")} key={i}>
				<div className="line"></div>
				<div className={"row"+(i===1?" active":"")}>
					<span>{companies[i].name}</span>
					<Icon type="plus" onClick={this.selectClicked} id={companies[i].id}></Icon>
				</div>
				{this.renderCompanies(companies[i].children)}
			</div>
			)
			list.push(c);
		}

		return list;
	}
	render()
	{
		if(
			this.props.pickerState == null || 
			this.props.pickerState.type === PICKER_MESSAGE_TYPE_HIDE || 
			this.props.pickerState.selectedCompany != null ||//just selected
			this.props.pickerState.type === PICKER_MESSAGE_TYPE_PERMISSION_ROLE
		)
			return(<div className="CompanyPicker"><div className="CompanyPicker-content"></div></div>);
		else
		{
		
			let msg = this.props.pickerState;
			if(msg.type === PICKER_MESSAGE_TYPE_PARENT)
				msg.title = i18n.t("company_picker_parent_title");
			else if(msg.type === PICKER_MESSAGE_TYPE_CHILD)
				msg.title = i18n.t("company_picker_child_title");
			else
				msg.title = "unknown";
		
			msg.text = "";

			return(
			<div className={"CompanyPicker visible "+msg.type}>
				<div className="bg" onClick={this.closeClicked}></div>
				<div className="CompanyPicker-content">	
					<Icon type="close" className="black" onClick={this.closeClicked}></Icon>				
					<div className="content">
						<h2>{msg.title}</h2>
						<br></br>
						<div className="pickerlist">
							<CustomScroll heightRelativeToParent="100%">
								{this.props.companies.sort(function(a,b){return a.name.localeCompare(b.name)})?"":""}
								{this.renderCompanies([{id:"0",name:"root",children:this.props.companies}])}
							</CustomScroll>
						</div>
					</div>					
				</div>
			</div>
			);
		}		
	}
}

const mapStateToProps = state => ({
	pickerState: getPicker(state),
	companies: getCompanies(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({showPicker, hidePicker, selectedPicker}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CompanyPicker);