import {API_REQUEST_ADD, API_REQUEST_DONE} from './../actions/api';
import {initialState}  from './../initialState.js';

export function apiReducer(state = initialState, action) {
	
  switch(action.type) {
    case API_REQUEST_ADD: 
    	return {
				...state,
				api_jobs: state.api_jobs+1
      	}
    case API_REQUEST_DONE:
    	return {
				...state,
				api_jobs: state.api_jobs-1,            
    	}       
    default: 
			return state;
	}
}

export const isPending = state => state.apiReducer.api_jobs>0;
