import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CustomScroll from 'react-custom-scroll';
import {Link, Redirect } from 'react-router-dom';
import i18n from "i18n";

import {GetPermissionClassName as Permission, HasPermission, HasOnOfPermissions} from './../../_models/Permissions'; 

import ActionBar from 'ui/ActionBar';

import {getSelectedCompanyId, getLastUpdate} from 'reducers/company';
import {getProject, getSelectedProjectId} from 'reducers/projects';
import {getSelectedVersionId} from 'reducers/versions';
import {getAllByCompanyID, setSelectedProjectId} from 'api/projects';


import { findProjectById } from '_models/ProjectsModel';
import {showAlertMessage} from 'api/appMessage';

import "./ProjectsCard.css";
import { CONST } from '../../Constants';
import Button from '../Button';

class ProjectsCard extends Component
{
	constructor(props) {
		super(props);

		this.newClicked = this.newClicked.bind(this);
		this.deleteClicked = this.deleteClicked.bind(this);
	}

	componentDidMount()
	{
		this.lastCompanyId = this.props.selectedCompanyId;
		this.props.getProjects({company_id:this.props.selectedCompanyId});
	}

	newClicked(e)
	{
		e.preventDefault();
		window.location.hash = "#/"+this.props.scope+"/"+this.props.selectedCompanyId+"/new";
	}

	deleteClicked(e)
	{
		let project = findProjectById(this.props.selectedProjectId);
		if(project!=null)
			this.props.showAlertMessage({text:i18n.t("deletealert_message").split("[#1]").join(project.name), data:project, scope:CONST.project});
	}

	render()
	{
		
		let actualId = this.props.selectedProjectId;

		//console.log(actualId, this.props.selectedProjectId, this.props.projectsReducer.createdProjectId)

		if(actualId===CONST.new && this.props.projectsReducer.createdProjectId!=="0")
		{
			let tmp = this.props.projectsReducer.createdProjectId;
			this.props.projectsReducer.createdProjectId = "0";
			return(<Redirect to={"/"+this.props.scope+"/"+this.lastCompanyId+"/"+tmp}></Redirect>)
		}
		
		if(this.lastCompanyId !== this.props.selectedCompanyId)
		{			
			this.lastCompanyId = this.props.selectedCompanyId;
			this.props.setSelectedProjectId(0);			
			this.props.getProjects({company_id:this.props.selectedCompanyId});
		}
		if(this.props.projectsReducer.updatedVersionStats != null)
		{
			this.props.projectsReducer.updatedVersionStats = null;
			
			this.props.getProjects({company_id:this.props.selectedCompanyId});
		}
	
		
		
		if(this.props.pending || !HasPermission(301) && this.props.scope === CONST.sales)
		{
			return(
				<div className={"ProjectsCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")}>
					<div className="ProjectsCard-title Card-title">
						<span>{i18n.t("projects_tile")}</span>					
					</div>
					<div className="ProjectsCard-content Card-content">

					</div>
				</div>
			);
		}
		else
		{
			let isSales = this.props.scope === CONST.sales;
		
			return(
				<div className={"ProjectsCard Card round tile tile"+ (this.props.gridpositions?" "+this.props.gridpositions:"")+(isSales?" sales":"")}>
					<div className="ProjectsCard-title Card-title">
						<span>{i18n.t("projects_tile")}</span>			
						{!isSales && HasPermission(252)?<Button left="plus" color="black" to={"/"+this.props.scope+"/"+this.props.selectedCompanyId+"/new"} className="right">{i18n.t("add_project")}</Button>:""}		
					</div>
					<div className="ProjectsCard-content Card-content">
					<ActionBar className={isSales || !HasOnOfPermissions(252,253,254)?"hidden":""} editClicked_depr={HasPermission(253) && actualId!==0?this.editClicked:null} deleteClicked={HasPermission(254) && actualId!==0?this.deleteClicked:null} newClicked={HasPermission(252)?this.newClicked:null}></ActionBar>
					<CustomScroll heightRelativeToParent="calc(100% - 35px)">
					<div className="list">
						{actualId==="new"?(<div className="active noselect"><Link to={"/"+this.props.scope+"/"+this.props.selectedCompanyId+"/new"}>{i18n.t("projects_new_project_title")}</Link></div>):""}
						{this.props.projectsReducer.projects.map((project, index) =>(		
							
							<div key={project.id} >				
							<div key={project.id} className={(actualId === project.id?"active":"")+" noselect"}>
								<Link to={"/"+this.props.scope+"/"+project.company_id+"/"+project.id+
								(this.props.scope === CONST.sales && project.published_versions.length>0?"/"+project.published_versions[0].id:"")
								}>
									{project.name}
									<span className={(project.published_versions.length>0?"":"no")+" published"} title={project.published_versions.length}></span>
									<span className={(this.props.scope === CONST.agency && project.comments>0?"":"hidden")+" comments"}>{project.comments}</span>
								</Link>
							</div>
							<div className={"versions"+(actualId === project.id && project.published_versions.length>0 && isSales?"":" hidden")}>
							{project.published_versions.map((version, vindex) =>(								
								<div key={index+"_"+vindex} className={(this.props.selectedVersionId === version.id?"active":"")+" noselect"}>
									<Link to={"/"+this.props.scope+"/"+project.company_id+"/"+project.id+"/"+version.id}>{version.name}</Link>										
								</div>
							))}
							</div>
							</div>
						))}
					</div>
					</CustomScroll>
					</div>
				</div>
			);
		}
		
	}
}

const mapStateToProps = state => ({
	selectedCompanyId: getSelectedCompanyId(state),
	projectsReducer: getProject(state),
	selectedProjectId:getSelectedProjectId(state),
	selectedVersionId:getSelectedVersionId(state),
	lastCompanyModelUpdateTime:getLastUpdate(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({getProjects:getAllByCompanyID, setSelectedProjectId, showAlertMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(ProjectsCard);
