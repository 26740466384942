import React, {Component} from 'react';

import "./Card.css";
import "./BoardUi.css";
import MainNavigation from './MainNavigation/MainNavigation';
import QuickSearch from './QuickSearch';
class BoardUi extends Component
{
	render()
	{
		return(
			<div className="BoardUi">
				<QuickSearch></QuickSearch>
				<MainNavigation visible={true} isboard={true}></MainNavigation>
			</div>
		);
	}	
}

export default BoardUi;

