
import {RESPONSE_TYPE} from './../api/_api.js';

export const COMPANYMEMBER_GET_PENDING = 'COMPANYMEMBER_GET_PENDING';
export const COMPANYMEMBER_GET_SUCCESS = 'COMPANYMEMBER_GET_SUCCESS';
export const COMPANYMEMBER_GET_ERROR = 'COMPANYMEMBER_GET_ERROR';

export const COMPANYMEMBER_UPDATE_SUCCESS = 'COMPANYMEMBER_UPDATE_SUCCESS';
export const COMPANYMEMBER_UPDATE_ERROR = 'COMPANYMEMBER_UPDATE_ERROR';

export const COMPANYMEMBER_DELETE_SUCCESS = 'COMPANYMEMBER_DELETE_SUCCESS';
export const COMPANYMEMBER_SELECTED = 'COMPANYMEMBER_SELECTED';

/*#region fetch CompanyMember*/

export function fetchCompanyMember_update(type, info, payload) {
	
	if(type === RESPONSE_TYPE.PENDING){ return {type: COMPANYMEMBER_GET_PENDING}; }
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: COMPANYMEMBER_UPDATE_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR){ return {type: COMPANYMEMBER_GET_ERROR, info: info, payload: payload};}
}

export function companyMemberSelect(member, type) {	
	return {
		type: COMPANYMEMBER_SELECTED,
		info: {},
		payload: member
	};
}

export function fetchCompanyMember_delete(type, info, payload) {
	
	if(type === RESPONSE_TYPE.PENDING){ return {type: COMPANYMEMBER_GET_PENDING}; }
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: COMPANYMEMBER_DELETE_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR){ return {type: COMPANYMEMBER_GET_ERROR, info: info, payload: payload};}
}

export function fetchCompanyMembers_get(type, info, payload) {
	//console.log(type, info, payload);
	if(type === RESPONSE_TYPE.PENDING){return {type: COMPANYMEMBER_GET_PENDING};}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: COMPANYMEMBER_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR){ return {type: COMPANYMEMBER_UPDATE_ERROR,info: info,payload: payload};}
}
/*#endregion fetch CompanyMember*/