import {
	fetchCompany_get, 
	fetchCompany_update, 
	fetchSelectedCompanyId_set, 
	fetchCompany_create,
	fetchCompany_delete
}from './../actions/company';


import {_doRequest} from './_api.js';
import {_doAppRequest} from './_app.js';

export function get( data) {	
	//dispatch(companyMemberSelect(member));	
//	console.log(data);
	return _doRequest(
		"company_get", 
		fetchCompany_get, 
		"000201",
		data
	);
}
export function create( data) {	

	return _doRequest(
		"company_create", 
		fetchCompany_create, 
		"000202",
		data
	);
}
export function del( data) {	
	
	console.log(data)
	return _doRequest(
		"company_delete", 
		fetchCompany_delete, 
		"000204",
		data
	);
}


export function update( data) {	
	
	return _doRequest(
		"company_udpate", 
		fetchCompany_update, 
		"000203",
		data
	);
}

export function setSelectedCompanyId( data) {	
	
	return _doAppRequest(
		fetchSelectedCompanyId_set, 		
		data
	);
}
