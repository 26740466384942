import fetch from './_api_fetchTimeout';
import {apiRequestStart, apiRequestDone, API_REQUEST_ADD} from './../actions/api';

const _API_URL_DEV = 'https://adamapi.aktuelleprojekte.de/';
const _API_URL = 'https://api.adam-ar.de/';
//const _API_URL_DEV = 'https://api.adam-ar.de/';
const _API_KEY = 'f8!!e&mebMUqsc!u';
const _API_VERSION = '';
let _API_TOKEN = '';

export const RESPONSE_TYPE={SUCCES : 'RESPONSE_TYPE_SUCCES',ERROR:'RESPONSE_TYPE_ERROR',PENDING:'RESPONSE_TYPE_PENDING'};

export function GetApiURL()
{
	if((document.location+"").indexOf("adam-ar.de")!==-1)
		return _API_URL;
	
	
	return _API_URL_DEV;

}



export function _doRequest(endpoint, actionFunc, id, data)
{
	let debug = false;

	var ob = _createApiCallObject(endpoint, data);

	return dispatch => {
		
		if(debug)console.log(endpoint, data, ob);
		dispatch(apiRequestStart());
		dispatch(actionFunc(RESPONSE_TYPE.PENDING, data));
		let timeout = 5000;
		if(ob.url.indexOf("/version/update")!==-1
			|| ob.url.indexOf("/project/update")!==-1
			|| ob.url.indexOf("/project/create")!==-1)	
				timeout = 260000;
		fetch(ob.url, ob.data, timeout)
    .then(res => {	
				if(debug)console.log(res);
				return res.json();			
			})
    .then(res => {		
			if(debug)console.log(res);
			if(res.success===false)
        throw(res);			
			if(res.success===true)
			{
			//	console.log( res.info.id, id);
				if(res.info!=null && res.info.id === id)
				{
					dispatch(actionFunc(RESPONSE_TYPE.SUCCES, res.info, res.data));					
				}
				else if(res.info!=null && res.info.id === "000102")
				{
					if(id !== "000100")
						document.location.reload(); 
				}
			}
			else
				dispatch(actionFunc(RESPONSE_TYPE.ERROR, res.info, res.data));				
			return true;
    })
    .catch((error) => {		
			if(debug)console.log(error);
		  dispatch(actionFunc(RESPONSE_TYPE.ERROR, error));
		})
		.finally(()=>	dispatch(apiRequestDone()));
		}
		
  
}


export function _createApiCallObject(actionName, bodydata) {
	var o = {};
	o.url = GetApiURL()+_API_VERSION;

	o.headers = {"x-api-key": _API_KEY, 'Content-Type': 'application/json',  "Authorization": "Bearer "+_getToken()};
	o.method = "POST";
	o.body = {};

	__setAction(actionName, o, bodydata);

	o.data = {'headers':o.headers}; 
	if(Object.keys(o.body).length)
		o.data.body = JSON.stringify(o.body);
	o.data.method = o.method;
	if(o.body.get!=null)
	{
		o.body = JSON.stringify(o.body);
		o.async = true;
  	o.crossDomain = true;
		o.contentType = false;
		o.processData = false;
		//o.data = o.body;
		o.data = {'headers':o.headers};
		o.data.method = o.method;
		o.redirect='follow';
	
		//o.body = null;
	//	o.mimeType = "multipart/form-data";
	}

	if(actionName === "version_update")
	{
		//console.log(o);
	}
	return o;    
}


function __setAction(actionName, o, bodydata)
{
	
	if(actionName === "/")
	{
		o.url += "";
		o.method = "GET";	
	}
	
	else if(actionName === "searchindex")
	{
		o.url += "searchindex";
		o.method = "POST";
	}	
	else if(actionName === "login")
	{
		o.url += "login";
		o.method = "POST";
	}	
	else if(actionName === "logout")
	{
		o.url += "logout";
		o.method = "GET";		
	}	
	else if(actionName === "auth")
	{
		o.url += "auth";
		o.method = "POST";
	}	
	else if(actionName === "pass_reset_request")
	{
		o.url += "user/pass_reset_request";
		o.method = "POST";
	}	
	else if(actionName === "pass_reset")
	{
		o.url += "user/pass_reset";
		o.method = "POST";		
	}	
	else if(actionName === "company_get")
	{
		o.url += "company";
		o.method = "POST";		
	}	
	else if(actionName === "company_create")
	{
		o.url += "/company/create";
		o.method = "POST";	
	}	
	else if(actionName === "company_udpate")
	{
		o.url += "company/update";
		o.method = "POST";		
	}	
	else if(actionName === "company_delete")
	{
		o.url += "/company/delete";
		o.method = "POST";		
	}	
	else if(actionName === "company_members_get")
	{
		o.url += "company_member";
		o.method = "POST";		
	}	
	else if(actionName === "company_member_set")
	{
		o.url += "company_member/set";
		o.method = "POST";		
	}	
	else if(actionName === "company_member_delete")
	{
		o.url += "company_member/delete";
		o.method = "POST";		
	}	
	else if(actionName === "config_get")
	{
		o.url += "permissions";
		o.method = "POST";		
	}	
	else if(actionName === "config_set")
	{
		o.url += "permissions/update";
		o.method = "POST";		
	}	
	else if(actionName === "project_get")
	{
		o.url += "project";
		o.method = "POST";		
	}	
	else if(actionName === "project_update")
	{
		o.url += "project/update";
		o.method = "POST";		
		var formData = new FormData();
	
		for(let s in bodydata)// eslint-disable-line
			formData.append(s, bodydata[s]);// eslint-disable-line
	
	}
	else if(actionName === "project_create")
	{
		o.url += "project/create";
		o.method = "POST";		
	}	
	else if(actionName === "project_delete")
	{
		o.url += "project/delete";
		o.method = "POST";		
	}		
	else if(actionName === "version_get")
	{
		o.url += "version";
		o.method = "POST";		
	}	
	else if(actionName === "version_update")
	{
		o.url += "version/update";
		o.method = "POST";		
	}
	else if(actionName === "version_create")
	{
		o.url += "version/create";
		o.method = "POST";		
	}	
	else if(actionName === "version_delete")
	{
		o.url += "version/delete";
		o.method = "POST";		
	}	
	else if(actionName === "comment_get")
	{
		o.url += "comment";
		o.method = "POST";		
	}	
	else if(actionName === "comment_update")
	{
		o.url += "comment/update";
		o.method = "POST";		
	}
	else if(actionName === "comment_create")
	{
		o.url += "comment/create";
		o.method = "POST";		
	}	
	else if(actionName === "comment_delete")
	{
		o.url += "comment/delete";
		o.method = "POST";		
	}	
	else if(actionName === "arproject_get")
	{
		o.url += "ar/getproject";
		o.method = "POST";		
	}	
	else if(actionName === "sharing_getMembers")
	{
		o.url += "share/getMembers";
		o.method = "POST";		
	}	
	else if(actionName === "sharing_addMember")
	{
		o.url += "share/addMember";
		o.method = "POST";		
	}	
	else if(actionName === "sharing_delMember")
	{
		o.url += "share/deleteMember";
		o.method = "POST";		
	}	else if(actionName === "reports_get")
	{
		o.url += "reports";
		o.method = "POST";		
	}	
	
	//o.url += "?nocache="+(new Date().getTime() / 1000);
	
	if(_API_TOKEN!= null && _API_TOKEN.length>4)
		o.url += "?nocache="+_API_TOKEN.slice(-3);
	else 
		o.url += "?nocache="+(new Date().getTime() / 1000);
	
//	console.log(o.url);
	o.body = bodydata;
	if(o.body == null)
		o.body = {};
}



export function _getToken()
{
	if(_API_TOKEN==null || _API_TOKEN.length === 0)
		_API_TOKEN = localStorage.getItem("token");	
	return _API_TOKEN;
}


export function _setToken(token)
{
	_API_TOKEN = token;
	localStorage.setItem("token", _API_TOKEN);	
}

