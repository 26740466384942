import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from 'i18n';

import {login, logout} from 'api/login';
import {addAppMessage} from 'api/appMessage';
import {getLogin, getLoginError, getLoginPending, getPendingLocation} from 'reducers/login';
import {GetError} from 'Errors';
import {ValidateEmail, colorizeAdamText} from 'Utils';


import {Link, Redirect} from 'react-router-dom';
import TextInput from './../TextInput';
import Button from './../Button';

import {APP_MESSAGE_TYPE_ERROR} from 'actions/app';

import "./../Card.css";
import "./CardLogin.css";

class CardLogin extends Component
{
	constructor(props) {
		super(props);
		if(this.state == null)
		{
			this.state = {user:{val:""}, pass:{val:""}, user_err:false, pass_err:false};
		}
		this.handleClick = this.handleClick.bind(this);
		this.handleLogoutClick = this.handleLogoutClick.bind(this);
		
		this.onSubmit = this.onSubmit.bind(this);
	}

	handleLogoutClick(event) {
		event.preventDefault();
		const {logout} = this.props;		
		logout();		
	}
	
	handleClick() {
		const {getLogin} = this.props;
		var errors = false;
		if(!ValidateEmail(this.state.user.val))
		{
			errors = true;
			this.setState({user_err:true, error:true});
		}
		else
			this.setState({user_err:false});

		if(this.state.pass.val.length<4)
		{
			errors = true;
			this.setState({pass_err:true, error:true});
		}
		else
			this.setState({pass_err:false});

		if(!errors)		
		{
			getLogin({"user":this.state.user.val, "pass":this.state.pass.val});
		}
  }

	onSubmit(event){
		event.preventDefault();
		this.handleClick();
	}

	render()
	{
		const {result, error, pending} = this.props;
		if(result==null && error == null && pending == null)
		{
		
		}	
		else if(!pending)
		{
			if(error != null)
			{
				var err = GetError(error.info);
				this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);
			}
			if(result)
			{
				if(this.props.pendingLocation === "/login" || this.props.pendingLocation==null)
					return (<Redirect to={"/"}></Redirect>)
				else
					return (<Redirect to={this.props.pendingLocation}></Redirect>)
			}
		}
		
		
		if(result)
		{
			return(
				<div className="Login Card round">
					<div className="Card-title">
						<span>Login. adam.</span>
					</div>
					<div className="Card-content">
						<a href="#/logout" onClick={this.handleLogoutClick}>logout</a>
					</div>
				</div>
			);
		}
		else
		{

		return(
			<div className="Login Card round">
				<div className="Card-title Login-title">
					<span dangerouslySetInnerHTML={{__html:colorizeAdamText(i18n.t("login_card_title"))}}></span>
				</div>
				<div className="Card-content Login-content">
					<form onSubmit={this.onSubmit}>
					<TextInput label={i18n.t("login_name_label")} placeholder={i18n.t("login_name_placeholder")} type="text" model={this.state.user} editable={true} error={this.state.user_err}></TextInput>
					<TextInput label={i18n.t("login_pass_label")} placeholder={i18n.t("login_pass_placeholder")} type="password" model={this.state.pass} editable={true}  error={this.state.pass_err}></TextInput>
					<Button onClick={this.handleClick} color="slate-green" className="login_btn">{i18n.t("login_btn_label")}</Button>
					<div className="login_bottom">
						<div className="login_icon">
							<div className={"login_status " + ((error!=null || this.state.error)?"error":"")}></div>
						</div>
						<span className="login_right">					
							<Link className="link" to="login/pass_forgot">{i18n.t("login_pass_forgot_link")}</Link>
						</span>
					</div>
					<input type="submit" className="hidden"></input>
					</form>
				</div>
			</div>
		);
		}
	}
}

const mapStateToProps = state => ({
	pendingLocation: getPendingLocation(state),
	result: getLogin(state),
	error: getLoginError(state),
	pending: getLoginPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({getLogin: login, logout:logout,addAppMessage: addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CardLogin);

