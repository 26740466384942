import {fetchComments_get, fetchComment_set, fetchSelectedCommentId_set} from './../actions/comments';
import {_doRequest} from './_api.js';

export function getAllByVersionId( data) {	
	return _doRequest(
		"comment_get", 
		fetchComments_get, 
		"000291",
		data
	);
}

export function createComment( data) {	
	return _doRequest(
		"comment_create", 
		fetchComment_set, 
		"000292",
		data
	);
}

export function updateComment( data) {	
	return _doRequest(
		"comment_update", 
		fetchComment_set, 
		"000293",
		data
	);
}

export function deleteComment( data) {	
	return _doRequest(
		"comment_delete", 
		fetchComment_set, 
		"000294",
		data
	);
}

export function setSelectedCommentId(data) {	
	return dispatch => {
		dispatch(fetchSelectedCommentId_set(data));					
	};
}

