import React, {Component} from 'react';
import Label from './Label';

import "./TextInput.css";
import Button from './Button';

class LabeledButton extends Component
{

	constructor(props)
	{
		super(props);
		this.state = {value:""}
		this.onTextChange = this.onTextChange.bind(this);
	}

	onTextChange(event){
		this.props.model[this.props._key]=event.target.value;
		this.setState({value:event.target.value})		
	};

	render()
	{
	
		return(
			<div className={"TextInput"+ (this.props.className!=null?" "+this.props.className:"") + (this.props.editable?" editable":"")+ (this.props.error?" error":"")}> 
				<Label value={this.props.label}></Label>
				<Button color={this.props.color} className={this.props.bgColor!=null?this.props.bgColor:"black" + (this.props.editable===false?" hidden":"")} left={this.props.left} onClick={this.props.onClick}>{this.props.children}</Button>
			</div>
		);
	}
}
export default LabeledButton;