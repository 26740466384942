import {appMessageAdd, appMessageRemove, appPickerSelected, appPickerShow, appPickerHide, alertMessageHide, alertMessageShow} from './../actions/app';

export function addAppMessage(title, text, type) {
 return dispatch => {	 	
		dispatch(appMessageAdd(title, text, type));
	};
}

export function removeAppMessage(key) {	
	return dispatch => {	 	
		dispatch(appMessageRemove(key));
	 };
 }

 export function showPicker(type, selectedId, list) {	
	return dispatch => {	 	
	
		dispatch(appPickerShow(type, selectedId, list));
	 };
 }
 export function hidePicker() {	
	return dispatch => {	 	
		dispatch(appPickerHide());
	};
 }
 export function selectedPicker(id, type, list) {	
	return dispatch => {	 	
		dispatch(appPickerSelected(id, type, list));
	 };
 }

 export function hideAlertMessage() {	
	return dispatch => {	 	
		dispatch(alertMessageHide());
	 };
 }
 export function showAlertMessage(msg) {	
	return dispatch => {	 	
		dispatch(alertMessageShow(msg));
	 };
 }

 


 